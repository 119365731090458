import { useFormContext } from 'react-hook-form'
import { Stack, Alert, Typography } from '@mui/material'
import { TextInput } from '@productwindtom/ui-base'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { useEffect } from 'react'

export const PaymentBillingContactInput = ({ name }: { name: 'paymentBillingContact' }) => {
  const {
    formState: { errors },
    trigger
  } = useFormContext<ProposalCreateForm>()

  useEffect(() => {
    trigger(name)
  }, [])

  const error = errors[name]?.message

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
        <TextInput
          name={`${name}.email`}
          fullWidth
          placeholder={'Enter billing email'}
          onBlur={() => trigger(name)}
          error={!!error}
          disabled
        />
        <TextInput
          name={`${name}.name`}
          fullWidth
          placeholder={'Enter contact name'}
          onBlur={() => trigger(name)}
          error={!!error}
          disabled
        />
      </Stack>

      {!!error && (
        <Alert severity={'error'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}
