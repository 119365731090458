import {
  GraphQLTypes,
  InputType,
  InvoiceStatus,
  PauseReason,
  Selector
} from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'

const proposalSelector = Selector('Proposal')({
  invoiceDueDate: true,
  invoiceStatus: true,
  launchDate: true
})
type Proposal = InputType<GraphQLTypes['Proposal'], typeof proposalSelector>

export enum CampaignStatus {
  DELAYED = 'Delayed',
  PRE_LAUNCH = 'Pre-Launch',
  ACTIVE = 'Active',
  UPCOMING = 'Upcoming',
  FINISHED = 'Ended',
  PAUSED = 'Paused'
}

export const getCampaignStatus = (
  startDate: string,
  preLaunchDate?: string,
  endDate?: string,
  hasPurchase?: boolean,
  pauseReason?: PauseReason,
  proposal?: Proposal
): CampaignStatus => {
  const now = DateTime.now()
  const start = DateTime.fromISO(startDate)
  const preLaunch = preLaunchDate ? DateTime.fromISO(preLaunchDate) : undefined
  const end = endDate ? DateTime.fromISO(endDate) : undefined

  if (end && now > end) {
    return CampaignStatus.FINISHED
  }

  if (!hasPurchase && proposal) {
    if (
      proposal.invoiceStatus === InvoiceStatus.NOT_PAID &&
      DateTime.fromISO(startDate).diff(DateTime.fromISO(proposal.launchDate)).days > 1
    ) {
      return CampaignStatus.DELAYED
    }
  }

  // For now, we only consider the out-of-stock
  if (pauseReason === PauseReason.OUT_OF_STOCK) {
    return CampaignStatus.PAUSED
  }

  if (hasPurchase && now > start) {
    return CampaignStatus.ACTIVE
  }

  return CampaignStatus.UPCOMING
}
