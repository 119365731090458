export const BASE_ROUTES = {
  HOME: '/',
  COMPANY_BASE: '/company',
  COMPANY: '/company/:companyId',
  TEAM_BASE: '/team',
  ADMIN_BASE: '/admin',
  BRANDS: '/brands',
  BRAND: '/brands/:brandId',
  AGENCY: '/agency/:agencyId'
}

const generateRoute = (base: string, path: string) => [base, path].join('/')

export const ROUTES = {
  ...BASE_ROUTES,

  LOGIN: '/login',
  SIGN_UP: '/signup',
  SIGN_UP_VIA_INVITATION: '/signup/invitation/:id',
  VERIFY: '/account/verify',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_SET_PASSWORD: '/forgot-password/set-password/:code',
  JOIN: '/join/:email/:tempPassword',

  BRANDS_CREATE: generateRoute(BASE_ROUTES.BRANDS, 'create'),
  BRAND_PRODUCTS: generateRoute(BASE_ROUTES.BRAND, 'products'),
  BRAND_CONTENT: generateRoute(BASE_ROUTES.BRAND, 'content'),
  BRAND_CREATE_PROPOSAL: generateRoute(BASE_ROUTES.BRAND, 'proposals/create'),
  BRAND_CREATE_PROPOSAL_EDIT: generateRoute(BASE_ROUTES.BRAND, 'proposals/:id/edit'),
  BRAND_CREATE_PROPOSAL_FROM_RECOMMENDATION: generateRoute(
    BASE_ROUTES.BRAND,
    'proposals/recommendation/:productId/:type'
  ),
  BRAND_PROFILE: generateRoute(BASE_ROUTES.BRAND, 'profile'),
  BRAND_SUBSCRIPTION: generateRoute(BASE_ROUTES.BRAND, 'subscription'),
  BRAND_SUBSCRIPTION_TRIAL_SUCCESS: generateRoute(BASE_ROUTES.BRAND, 'subscription/trial-success'),
  CAMPAIGN: generateRoute(BASE_ROUTES.BRAND, 'campaigns/:campaignId'),
  BRAND_PROPOSAL: generateRoute(BASE_ROUTES.BRAND, 'proposals/:id'),

  COMPANIES: '/companies',
  COMPANY_TEAM: generateRoute(BASE_ROUTES.COMPANY, 'team'),

  ADMIN_PROPOSALS: generateRoute(BASE_ROUTES.ADMIN_BASE, 'proposals'),
  ADMIN_RECOMMENDATIONS: generateRoute(BASE_ROUTES.ADMIN_BASE, 'recommendations'),
  ADMIN_USERS: generateRoute(BASE_ROUTES.ADMIN_BASE, 'users'),
  ADMIN_SUBSCRIPTIONS: generateRoute(BASE_ROUTES.ADMIN_BASE, 'subscriptions'),

  PRODUCTS: '/products/:brandId',

  AGENCY_TEAM: 'team',
  AGENCY_COMPANY: 'company/:companyId',
  AGENCY_PROFILE: generateRoute(BASE_ROUTES.AGENCY, 'profile')
}

export const ROUTE_NAMES_BRAND = {
  PROPOSALS: 'proposals',
  PRODUCTS: 'products',
  RECOMMENDATIONS: 'recommendations',
  CAMPAIGNS: 'campaigns'
}

export const ROUTE_NAMES_PRODUCT = {
  VIEW: 'view',
  ALERTS: 'alerts'
}

export const ROUTE_NAMES_CAMPAIGN = {
  OVERVIEW: 'overview',
  ECOMMERCE: 'ecommerce',
  CONTENT: 'content',
  MY_ACTIONS: 'my-actions'
}

export const ROUTE_NAMES_CAMPAIGN_ECOMMERCE = {
  TRAFFIC: 'traffic',
  SEO: 'seo',
  SEO_V2: 'seo-v2',
  SPONSORED_RANK: 'sponsored-rank',
  REVIEWS: 'reviews',
  SALES: 'sales',
  FEEDBACK: 'feedback',
  BENCHMARK: 'benchmark',
  PERFORMANCE: 'performance'
}

export const ROUTE_NAMES_CAMPAIGN_MY_ACTIONS = {
  APPROVE_CREATORS: 'approve-creators',
  APPROVE_CONTENT: 'approve-content',
  LINK_SELLER: 'link-seller',
  LINK_VENDOR: 'link-vendor'
}

export const ROUTE_NAMES_CAMPAIGN_OVERVIEW = {
  SUMMARY: 'summary',
  PROPOSAL: 'proposal'
}

export const ROUTE_NAMES_PROPOSAL = {
  PRODUCT_CREATORS: 'product-creators',
  TIMELINE: 'timeline',
  REVIEW: 'review',
  WIN: 'win'
}
