import { AMAZON_ASIN_FULL_URL_REGEX, AMAZON_ASIN_REGEX, STORE_DETAILS } from '@momentum/utils/storeUtils'
import { Add, Cancel } from '@mui/icons-material'
import { Button, Link, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

export const BenchmarkProductsInput = ({
  name,
  addText,
  placeholder,
  max,
  storeName,
  disabled
}: {
  name: string
  addText: string
  placeholder?: string
  max?: number
  storeName?: Store,
  disabled?: boolean
}) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const { control, getValues } = useFormContext()
  const { append, fields, remove } = useFieldArray({ name, control })

  const { valid, errorMessage } = validateInput(searchInput, storeName)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const modifiedSearchInput = searchInput.trim()
      const vals = getValues(name)
      if (!valid || (vals || []).find((v: string) => v.trim() === modifiedSearchInput)) return
      handleAdd()
    }
  }

  const handleAdd = () => {
    let productUrl = searchInput.trim()
    if (storeName?.includes('amazon') && productUrl.match(AMAZON_ASIN_REGEX)) {
      productUrl = `https://${STORE_DETAILS[storeName].link}/dp/${productUrl}`
    }
    append(productUrl)
    setSearchInput('')
  }

  return (
    <Stack spacing={2}>
      {!!fields.length && (
        <Stack spacing={2}>
          {fields.map((v, index) => (
            <Stack key={v.id} direction={'row'}>
              <Link variant={'label3'} noWrap href={getValues(`${name}[${index}]`)} target='_blank'>
                {getValues(`${name}[${index}]`)}
              </Link>
              {!disabled && <Cancel
                sx={{ cursor: 'pointer', marginLeft: 'auto !important' }}
                onClick={() => {
                  remove(index)
                }}
                fontSize={'mSmall'}
                color={'action'}
              />}
            </Stack>
          ))}
        </Stack>
      )}

      {(!max || fields.length < max) && !disabled && (
        <TextField
          variant={'outlined'}
          value={searchInput}
          onKeyDown={handleKeyDown}
          onChange={e => setSearchInput(e.target.value)}
          disabled={fields.length >= 5}
          placeholder={placeholder}
          error={!valid && !!errorMessage}
          helperText={errorMessage}
        />
      )}
      {!disabled && <Box>
        <Button
          startIcon={<Add />}
          variant={'text'}
          onClick={handleAdd}
          disabled={!valid || (!!max && fields.length >= max)}
        >
          {addText}
        </Button>
      </Box>}
    </Stack>
  )
}

const validateInput = (
  input?: string,
  storeName?: Store
): {
  valid: boolean
  errorMessage?: string
} => {
  const trimmedInput = input?.trim()
  if (!trimmedInput) {
    return {
      valid: false
    }
  }
  if (!storeName?.includes('amazon') && !trimmedInput.match(urlPattern)) {
    return {
      valid: false,
      errorMessage: 'Invalid URL'
    }
  }
  if (
    storeName?.includes('amazon') &&
    !trimmedInput.match(AMAZON_ASIN_FULL_URL_REGEX) &&
    !trimmedInput.match(AMAZON_ASIN_REGEX)
  ) {
    return {
      valid: false,
      errorMessage: 'Invalid Amazon Product URL or ASIN'
    }
  }
  return {
    valid: true
  }
}
