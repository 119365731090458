import { mApi } from '@momentum/api'
import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

const brandSelector = Selector('Brand')({
  id: true,
  name: true,
  companyId: true,
  brandApis: {
    enabled: true,
    api: true,
    apiVersion: true,
    isIntegrated: true
  },
  trialSubscriptionStartedAt: true,
  subscriptionStartsAt: true,
  subscriptionEndsAt: true,
  amazonBrandStoreUrl: true,
  isBrandStoreScrapeEnabled: true,
  walmartScrapeUrl: true,
  isWalmartScrapeEnabled: true,
  subscriptionCredits: {
    action: true,
    numCredits: true
  }
})

export type Brand = InputType<GraphQLTypes['Brand'], typeof brandSelector>

export const getBrandWithSubscriptions = async (nextToken?: string): Promise<Brand[]> => {
  const { listBrandsByActiveBySubscriptionEndsAt } = await mApi('query')({
    listBrandsByActiveBySubscriptionEndsAt: [
      { active: 'active', nextToken },
      { items: brandSelector, nextToken: true }
    ]
  })

  if (listBrandsByActiveBySubscriptionEndsAt.nextToken) {
    return [
      ...listBrandsByActiveBySubscriptionEndsAt.items,
      ...(await getBrandWithSubscriptions(listBrandsByActiveBySubscriptionEndsAt.nextToken))
    ]
  }
  return listBrandsByActiveBySubscriptionEndsAt.items
}
