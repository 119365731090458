import { drawerWidth } from '@momentum/components/nav-drawer'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Paper,
  Stack,
  Toolbar,
  Typography
} from '@mui/material'
import { Auth } from 'aws-amplify'
import React from 'react'
import LogoWithTextIcon from '@momentum/components/icons/logo-with-text'
import { WarningAmber } from '@mui/icons-material'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import VideoOrImage from '@momentum/components/video-or-image'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'

const WARNING_DAYS = 30

export const AppBar = () => {
  const { isAdmin, isAdminView, setIsAdminView, agency, agencyId } = useUserSessionContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { endsAt, getSubscriptionStatus } = useSubscriptionContext()
  const { isActive } = getSubscriptionStatus()

  const handleMenuClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    Auth.signOut()

    handleMenuClose()
  }

  const handleSetAdminView = () => {
    setIsAdminView(!isAdminView)

    handleMenuClose()
  }

  const expiryDays = endsAt && Math.ceil(endsAt.diffNow('days').days)

  return (
    <React.Fragment>
      <MuiAppBar sx={{ background: 'white', left: drawerWidth, minHeight: '56px', width: 'auto' }} elevation={1}>
        <Toolbar>
          <Container disableGutters sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <LogoWithTextIcon sx={{ height: '32px', width: '174px' }} />
            </Stack>
            <Stack ml={'auto'} direction={'row'} spacing={4} alignItems={'center'}>
              {agencyId && agency?.logo && (
                <Paper
                  elevation={0}
                  sx={{
                    p: 0.5,
                    boxSizing: 'border-box',
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <VideoOrImage src={getCdnImageUrl(agency.logo)} bgcolor="white" />
                </Paper>
              )}
              <Button
                onClick={handleMenuClick}
                variant="contained"
                disableElevation
                size="large"
                endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                style={{
                  height: '24px',
                  borderRadius: '132px',
                  padding: '4px 8px ',
                  backgroundColor: '#EBEDF6',
                  color: '#002880',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  letterSpacing: '0.28px'
                }}
              >
                {isAdminView ? 'Admin view' : isAdmin ? 'Client view' : 'Account'}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                style={{
                  marginTop: 4
                }}
              >
                {isAdmin && [
                  <MenuItem key={'switch-menu-item'} onClick={handleSetAdminView}>
                    {isAdminView ? 'Switch to client view' : 'Switch to admin view'}
                  </MenuItem>,
                  <Divider key={'switch-menu-item-divider'} />
                ]}
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
              </Menu>
            </Stack>
          </Container>
        </Toolbar>
      </MuiAppBar>
      {isActive && expiryDays && expiryDays > 0 && expiryDays <= WARNING_DAYS && (
        <SubscriptionExpiryBar daysExpiring={expiryDays} />
      )}
    </React.Fragment>
  )
}

const SubscriptionExpiryBar = ({ daysExpiring }: { daysExpiring: number }) => {
  return (
    <Stack
      position={'sticky'}
      width={'100%'}
      top={64}
      left={0}
      zIndex={3}
      direction={'row'}
      sx={{ bgcolor: theme => theme.palette.warning.lighter }}
      alignItems={'center'}
      justifyContent={'center'}
      py={1}
      spacing={1}
    >
      <WarningAmber color={'warning'} />
      <Typography variant={'label2'} color={'black'}>
        Your Momentum subscription and access expires in {daysExpiring} days! Contact sales@productwind.com
      </Typography>
    </Stack>
  )
}
