import { PRODUCT_COLUMN_DEFINITIONS } from './constants'
import { GridRenderCellParams, useGridApiRef, GridRowId } from '@mui/x-data-grid-premium'
import { IconButton, Stack } from '@mui/material'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { DataGrid } from '@momentum/components/table'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useState, useMemo } from 'react'
import { keyBy, uniq, map, uniqBy } from 'lodash'
import { notEmpty, capitalizeFirstLetter } from '@productwindtom/shared-node'
import { DateTime } from 'luxon'
import { ProductsActions } from '@momentum/routes/products/ProductsActions'

export const ViewAllProducts = () => {
  const { products, brand, setIsAddProductActiveOrCallback } = useBrandContext()
  const [search, setSearch] = useState('')

  const apiRef = useGridApiRef()

  const productsById = useMemo(() => keyBy(products, p => p.id), [products])

  const productRows = useMemo(
    () =>
      (products || []).map(product => {
        const { listingFulfillmentType } = product

        const children = (products || []).filter(p => p.parentSkuId === product.id)

        const merged = uniq(children.map(c => c.ratingSummary?.numRatings)).length === 1 || children.length === 0

        return {
          ...(product?.ratingSummary || {}),
          ...product,
          isParent: !!children.length,
          variation: children.length ? 'Parent' : '',
          numRatings: merged ? product.ratingSummary?.numRatings?.toLocaleString() : 'Not merged',
          rating: merged ? product.ratingSummary?.rating : 'Not merged',
          path: uniq([product.parentSkuId, product.id].filter(notEmpty)),
          availabilityDate:
            product.availabilityDate && DateTime.fromISO(product.availabilityDate).toLocaleString(DateTime.DATE_SHORT),
          store: product.store,
          category: product?.categories?.[1],
          listingFulfillmentType: listingFulfillmentType && capitalizeFirstLetter(listingFulfillmentType.toLowerCase()),
          actions: {
            productId: product.id,
            brandId: brand.id
          }
        }
      }),
    [products, brand, productsById]
  )

  const filteredProductRows = useMemo(() => {
    const filteredRows = productRows.filter(
      p =>
        !search ||
        p.name.toLowerCase().includes(search) ||
        (search.length > 1 &&
          (p.skuId?.toLowerCase()?.startsWith(search) ||
            productsById?.[p.parentSkuId || '']?.skuId?.toLowerCase().startsWith(search)))
    )

    const parentSkuIds = uniq(map(filteredRows, p => p.parentSkuId))
    const parentSkus = productRows.filter(p => parentSkuIds.includes(p.id))

    return uniqBy([...filteredRows, ...parentSkus], r => r.id)
  }, [productRows, search])

  const handleAddProduct = () => {
    setIsAddProductActiveOrCallback({
      callback: async () => true
    })
  }

  const handleExpandAll = (isExpanded: boolean) => {
    apiRef.current.getAllRowIds().forEach(id => expandRow(id, isExpanded))
  }

  const expandRow = (id: GridRowId, isExpanded: boolean) => {
    const rowNode = apiRef.current.getRowNode(id)
    if (rowNode?.type === 'group') {
      apiRef.current.setRowChildrenExpansion(id, isExpanded)
    }
  }

  return (
    <Stack>
      <ProductsActions onSearch={setSearch} onAddProduct={handleAddProduct} onExpandAll={handleExpandAll} />
      <DataGrid
        apiRef={apiRef}
        loading={!products}
        autoHeight
        rows={filteredProductRows}
        columns={PRODUCT_COLUMN_DEFINITIONS.map(d => ({ ...d, sortable: d.sortable || false }))}
        disableColumnMenu={true}
        disableColumnReorder={true}
        pagination
        treeData
        getTreeDataPath={row => row.path}
        groupingColDef={{
          headerName: '',
          width: 50,
          renderCell: ({ id, field, rowNode }: GridRenderCellParams) => {
            if (rowNode.type !== 'group') {
              return null
            }

            return (
              <IconButton
                onClick={event => {
                  expandRow(id, !rowNode.childrenExpanded)
                  apiRef.current.setCellFocus(id, field)
                  event.stopPropagation()
                }}
                size="small"
                color="primary"
              >
                {rowNode.childrenExpanded ? <RemoveCircleRoundedIcon /> : <AddCircleRoundedIcon />}
              </IconButton>
            )
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </Stack>
  )
}
