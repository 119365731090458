import { PaymentTermsType } from '@productwindtom/shared-momentum-zeus-types'

/**
 * Get payment terms for a company based on the type of net terms.
 *
 * @param paymentTermsType
 * @param paymentTermsNetDays
 * @param productCostPaymentTermsType
 * @param productCostPaymentTermsNetDays
 */
export const getPaymentTerms = ({
  paymentTermsType,
  paymentTermsNetDays,
  productCostPaymentTermsType,
  productCostPaymentTermsNetDays
}: {
  paymentTermsType: PaymentTermsType
  paymentTermsNetDays?: number
  productCostPaymentTermsType?: PaymentTermsType
  productCostPaymentTermsNetDays?: number
}) => {
  if (
    paymentTermsType === PaymentTermsType.DEFAULT_7_DAYS &&
    (!productCostPaymentTermsType || productCostPaymentTermsType === PaymentTermsType.DEFAULT_7_DAYS)
  ) {
    return 'If Client chooses to purchase Credits and activate creators for additional cost in the Momentum Platform, payment must be received in full before Credits are issued or creators are activated.'
  }

  if (paymentTermsType === PaymentTermsType.NET_CUSTOM && !productCostPaymentTermsType) {
    return (
      'If Client chooses to purchase Credits and activate creators for additional cost in the Momentum Platform, ' +
      `payment must be received net ${paymentTermsNetDays} of the start date for the applicable Campaign (as defined in the Terms and Conditions applicable to the purchase and use of Credits and activation of creators).`
    )
  }

  if (
    paymentTermsType === PaymentTermsType.NET_CUSTOM &&
    productCostPaymentTermsType === PaymentTermsType.DEFAULT_7_DAYS
  ) {
    return (
      'If Client chooses to purchase Credits and activate creators for additional cost in the Momentum Platform, ' +
      `payment must be received net ${paymentTermsNetDays} of the start date for the applicable Campaign (as defined in the Terms and Conditions applicable to the purchase and use of Credits and activation of creators). Notwithstanding the foregoing, payment of all product costs (payment provided to creators to purchase the product) must be received in full before creators are activated.`
    )
  }

  if (paymentTermsType === PaymentTermsType.NET_CUSTOM && productCostPaymentTermsType === PaymentTermsType.NET_CUSTOM) {
    return (
      'If Client chooses to purchase Credits and activate creators for additional cost in the Momentum Platform, ' +
      `payment must be received net ${paymentTermsNetDays} of the start date for the applicable Campaign (as defined in the Terms and Conditions applicable to the purchase and use of Credits and activation of creators). ` +
      `Notwithstanding the foregoing, payment of all product costs (payment provided to creators to purchase the product) must be received net ${productCostPaymentTermsNetDays} of the start date for the applicable Campaign.`
    )
  }

  if (
    paymentTermsType === PaymentTermsType.DEFAULT_7_DAYS &&
    productCostPaymentTermsType === PaymentTermsType.NET_CUSTOM
  ) {
    return (
      'If Client chooses to purchase Credits and activate creators for additional cost in the Momentum Platform, ' +
      'payment must be received in full before the start date for the applicable Campaign (as defined in the Terms and Conditions applicable to the purchase and use of Credits and activation of creators). ' +
      `Notwithstanding the foregoing, payment of all product costs (payment provided to creators to purchase the product) must be received net ${productCostPaymentTermsNetDays} of the start date for the applicable Campaign.`
    )
  }

  throw new Error('Invalid payment terms')
}
