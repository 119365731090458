import { typography } from '@momentum/styles'
import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  label1: typography.label1,
  label2: typography.label2,
  body1: typography.body1,
  caption1: typography.caption1,
  caption2: typography.caption2,
  h4: typography.h4,
  spacing: {
    marginBottom: '24px'
  },
  row: {
    flexDirection: 'row'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  columns: {
    flexDirection: 'column'
  },
  header: {
    alignItems: 'center',
    padding: '10px 40px',
    backgroundColor: '#324AA8',
    color: 'white'
  },
  body: {
    padding: '32px 40px',
    position: 'relative'
  },
  roundBorder: {
    borderRadius: '4px',
    border: '1px solid #EBEDF6',
    backgroundColor: 'white'
  },
  timelineStepCircle: {
    borderRadius: '50%',
    backgroundColor: '#324AA8',
    color: 'white',
    fontSize: '12px',
    width: '24px',
    height: '24px',
    paddingTop: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    margin: '8px auto 12px'
  },
  timelineLine: {
    height: '1px',
    width: '50%',
    backgroundColor: '#324AA8',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-1px)'
  }
})
