/* eslint-disable */

export const AllTypesProps: Record<string, any> = {
  Mutation: {
    adjustCampaignStartDate: {
      input: 'AdjustCampaignStartDateInput'
    },
    adjustProductRecommendationVisibility: {
      input: 'AdjustProductRecommendationVisibilityInput'
    },
    adjustSubscriptionCredits: {
      input: 'AdjustCreditInput'
    },
    approveContent: {
      input: 'ApproveContentInput'
    },
    createAgency: {
      input: 'ModelInputCreateAgency'
    },
    createBrand: {
      input: 'CreateBrandInput'
    },
    createCampaign: {},
    createCampaignBenchmark: {
      input: 'ModelInputCreateCampaignBenchmark'
    },
    createCampaignPerformance: {
      input: 'ModelInputCreateCampaignPerformance'
    },
    createCampaignPerformanceProduct: {
      input: 'ModelInputCreateCampaignPerformanceProduct'
    },
    createCompany: {
      input: 'ModelInputCreateCompany'
    },
    createManualProduct: {
      input: 'ProductInput'
    },
    createNotification: {
      input: 'ModelInputCreateNotification'
    },
    createPWProductSeoSummary: {
      input: 'ModelInputCreatePWProductSeoSummary'
    },
    createPricing: {
      input: 'ModelInputCreatePricing'
    },
    createProductFromUrl: {
      input: 'CreateProductFromUrlInput'
    },
    createProposal: {
      input: 'ModelInputCreateProposal'
    },
    createProposalSlide: {
      input: 'CreateProposalSlideInput'
    },
    createScrapeRequestJob: {
      input: 'ModelInputCreateScrapeRequestJob'
    },
    createSupportTicket: {},
    deactivateUser: {},
    deleteCampaignPerformance: {
      input: 'ModelInputDeleteCampaignPerformance'
    },
    deleteCampaignPerformanceProduct: {
      input: 'ModelInputDeleteCampaignPerformanceProduct'
    },
    deletePricing: {
      input: 'ModelInputDeletePricing'
    },
    deleteProposal: {
      input: 'ModelInputDeleteProposal'
    },
    deleteUserInvitation: {
      input: 'ModelInputDeleteUserInvitation'
    },
    endCampaign: {},
    generateFeedbackReport: {},
    inviteUserToMomentum: {
      input: 'UserInvitationInput'
    },
    pingActivity: {},
    purchaseSubscription: {
      input: 'PurchaseSubscriptionInput'
    },
    purchaseSubscriptionCredits: {
      input: 'PurchaseCreditsInput'
    },
    reactivateUser: {},
    refreshRecommendations: {
      stores: 'Store'
    },
    requestContentEdits: {
      input: 'RequestContentEditsInput'
    },
    requestUploadFileLink: {
      additionalParameters: 'AWSJSON',
      uploadRequestType: 'UploadRequestType'
    },
    resetPassword: {},
    sendProposalInvoice: {},
    signupUser: {
      attributes: 'AWSJSON'
    },
    submitCampaignCreatorApproval: {
      input: 'SubmitCampaignCreatorApprovalInput'
    },
    toggleContentVisibility: {
      input: 'ToggleContentVisibilityInput'
    },
    updateAgency: {
      input: 'ModelInputUpdateAgency'
    },
    updateBrand: {
      input: 'UpdateBrandInput'
    },
    updateCampaignBenchmark: {
      input: 'ModelInputUpdateCampaignBenchmark'
    },
    updateCampaignPerformance: {
      input: 'ModelInputUpdateCampaignPerformance'
    },
    updateCampaignPerformanceProduct: {
      input: 'ModelInputUpdateCampaignPerformanceProduct'
    },
    updateCampaignProduct: {},
    updateCompany: {
      input: 'ModelInputUpdateCompany'
    },
    updateManualProduct: {
      input: 'UpdateManualProductInput'
    },
    updateNotification: {
      input: 'ModelInputUpdateNotification'
    },
    updatePWProductSeoSummary: {
      input: 'ModelInputUpdatePWProductSeoSummary'
    },
    updatePricing: {
      input: 'ModelInputUpdatePricing'
    },
    updateProductKeywordSummary: {
      input: 'ModelInputUpdateProductKeywordSummary'
    },
    updateProductSeoSummary: {
      input: 'ModelInputUpdateProductSeoSummary'
    },
    updateProposal: {
      input: 'ModelInputUpdateProposal'
    },
    updateScrapeRequestJob: {
      input: 'ModelInputUpdateScrapeRequestJob'
    },
    updateUser: {
      input: 'ModelInputUpdateUser'
    },
    updateUserAgency: {
      input: 'ModelInputUpdateUserAgency'
    },
    updateUserCampaignSocialContent: {
      input: 'ModelInputUpdateUserCampaignSocialContent'
    },
    updateUserInvitation: {
      input: 'ModelInputUpdateUserInvitation'
    },
    uploadCreatorContent: {
      input: 'UploadCreatorContentInput'
    },
    uploadProductSeoData: {
      input: 'UploadProductSeoDataInput'
    }
  },
  Query: {
    companiesByAgency: {
      sortDirection: 'ModelSortDirection'
    },
    generateCompanyRollupReport: {},
    getAgency: {},
    getAmazonAuthorizationUri: {
      brandApiType: 'BrandApiType'
    },
    getBenchmarkMetrics: {
      benchmarkType: 'BenchmarkType'
    },
    getBrand: {},
    getBrandApi: {
      api: 'BrandApiType'
    },
    getCampaign: {},
    getCampaignBenchmark: {},
    getCampaignGoalsSummary: {},
    getCampaignPerformance: {},
    getCampaignPerformanceProduct: {},
    getCampaignReviewMetrics: {
      endDate: 'AWSDate',
      startDate: 'AWSDate'
    },
    getCompany: {},
    getNotification: {},
    getPWProductSeoSummary: {},
    getPricing: {
      store: 'Store'
    },
    getProduct: {},
    getProductFeedbackSummary: {},
    getProductRecommendation: {
      recommendationType: 'RecommendationType'
    },
    getProductSeoSummary: {},
    getProposal: {},
    getUser: {},
    getUserAgency: {},
    getUserBrand: {},
    getUserCompany: {},
    getUserInvitation: {},
    listAgencies: {
      sortDirection: 'ModelSortDirection'
    },
    listBrandApis: {
      api: 'ModelBrandApiSkKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listBrandCampaigns: {},
    listBrandsByActive: {
      sortDirection: 'ModelSortDirection'
    },
    listBrandsByActiveBySubscriptionEndsAt: {
      sortDirection: 'ModelSortDirection',
      subscriptionEndsAt: 'ModelStringKeyConditionInput'
    },
    listBrandsByCompanyId: {
      sortDirection: 'ModelSortDirection'
    },
    listCampaignClicks: {
      date: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listCampaignCreatorsForApproval: {},
    listCompanies: {
      sortDirection: 'ModelSortDirection'
    },
    listContentPendingApproval: {},
    listEtailerProductMetrics: {
      date: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listInvitationsByAgency: {
      sortDirection: 'ModelSortDirection',
      status: 'ModelMomentumInvitationSkKeyConditionInput'
    },
    listInvitationsByCompany: {
      sortDirection: 'ModelSortDirection',
      status: 'ModelMomentumInvitationSkKeyConditionInput'
    },
    listInvitationsByStatus: {
      createdAt: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection',
      status: 'UserInvitationStatus'
    },
    listPricings: {
      sortDirection: 'ModelSortDirection',
      store: 'ModelCreatorPricingSkKeyConditionInput'
    },
    listProductKeywordRecords: {
      date: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listProductKeywordSummaries: {
      keyword: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listProductRecommendations: {
      recommendationType: 'ModelProductRecommendationsSkKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listProducts: {
      sortDirection: 'ModelSortDirection'
    },
    listProductsByBrandId: {
      sortDirection: 'ModelSortDirection'
    },
    listProposals: {
      sortDirection: 'ModelSortDirection'
    },
    listProposalsByBrandId: {
      createdAt: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listProposalsByProductId: {
      createdAt: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listSubscriptionCreditActions: {
      actionId: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listUserAgencies: {
      agencyId: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listUserAgenciesByAgencyId: {
      createdAt: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listUserBrands: {
      brandId: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listUserCompanies: {
      companyId: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listUserCompaniesByCompanyId: {
      createdAt: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    listUsers: {
      sortDirection: 'ModelSortDirection'
    },
    requestExchangeRate: {
      input: 'RequestExchangeRateInput'
    },
    scrapeRequestByBrandIdBySku: {
      sku: 'ModelStringKeyConditionInput',
      sortDirection: 'ModelSortDirection'
    },
    searchBrandContent: {
      excludedContentTypes: 'ContentType',
      sortBy: 'AWSJSON'
    },
    searchBrandCounts: {},
    searchBrandProducts: {},
    searchRecommendations: {
      recommendationTypes: 'RecommendationType',
      sortBy: 'AWSJSON',
      stores: 'Store'
    }
  },
  AccessLevel: 'enum' as const,
  BenchmarkType: 'enum' as const,
  BrandApiType: 'enum' as const,
  BrandApiVersion: 'enum' as const,
  CampaignCreatorApprovalAction: 'enum' as const,
  CampaignCreatorStatus: 'enum' as const,
  CampaignEnderType: 'enum' as const,
  Channel: 'enum' as const,
  ContentType: 'enum' as const,
  ContentUploadType: 'enum' as const,
  CreatorApprovalStatus: 'enum' as const,
  CreatorType: 'enum' as const,
  CreditAction: 'enum' as const,
  Currency: 'enum' as const,
  DraftStatus: 'enum' as const,
  InvoiceMethod: 'enum' as const,
  InvoicePaymentType: 'enum' as const,
  InvoiceStatus: 'enum' as const,
  ItemClassification: 'enum' as const,
  ListingBadge: 'enum' as const,
  ListingFulfillmentType: 'enum' as const,
  ModelSearchStringFieldInput: 'enum' as const,
  ModelSortDirection: 'enum' as const,
  NotificationChannel: 'enum' as const,
  NotificationStatus: 'enum' as const,
  NotificationType: 'enum' as const,
  PageOneWinType: 'enum' as const,
  PauseReason: 'enum' as const,
  PaymentTermsType: 'enum' as const,
  PerformanceComparisonMetric: 'enum' as const,
  PerformanceSummaryTitleType: 'enum' as const,
  PricingCreatorType: 'enum' as const,
  ProductCreationSource: 'enum' as const,
  ProductFeedbackStatus: 'enum' as const,
  ProposalGoal: 'enum' as const,
  ProposalPromoEventType: 'enum' as const,
  ProposalStatus: 'enum' as const,
  RecommendationType: 'enum' as const,
  Region: 'enum' as const,
  Role: 'enum' as const,
  ScrapeRequestStatus: 'enum' as const,
  Store: 'enum' as const,
  SubscriptionLevel: 'enum' as const,
  SubscriptionType: 'enum' as const,
  UploadRequestType: 'enum' as const,
  UserInvitationStatus: 'enum' as const,
  UserStatus: 'enum' as const,
  AdjustCampaignStartDateInput: {
    newCampaignStartDate: 'AWSDateTime'
  },
  AdjustCreditInput: {
    action: 'CreditAction'
  },
  AdjustProductRecommendationVisibilityInput: {},
  ApproveContentInput: {},
  BillingContactInput: {},
  CreateBrandInput: {
    brandApis: 'BrandApiType',
    region: 'Region'
  },
  CreateProductFromUrlInput: {},
  CreateProposalSlideInput: {
    creatorPricing: 'CreatorPricingInput',
    goal: 'ProposalGoal'
  },
  CreatorPricingInput: {
    type: 'PricingCreatorType'
  },
  ModelBrandApiSkKeyConditionInput: {
    beginsWith: 'BrandApiType',
    between: 'BrandApiType',
    eq: 'BrandApiType',
    ge: 'BrandApiType',
    gt: 'BrandApiType',
    le: 'BrandApiType',
    lt: 'BrandApiType'
  },
  ModelCreatorPricingSkKeyConditionInput: {
    beginsWith: 'Store',
    between: 'Store',
    eq: 'Store',
    ge: 'Store',
    gt: 'Store',
    le: 'Store',
    lt: 'Store'
  },
  ModelInputCreateAgency: {
    createdAt: 'AWSDateTime',
    paymentTermsType: 'PaymentTermsType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreateCampaignBenchmark: {
    benchmarkType: 'BenchmarkType',
    createdAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreateCampaignPerformance: {
    comparisonMetric: 'PerformanceComparisonMetric',
    createdAt: 'AWSDateTime',
    summaryTitle: 'PerformanceSummaryTitleType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreateCampaignPerformanceProduct: {
    comparisonMetric: 'PerformanceComparisonMetric',
    createdAt: 'AWSDateTime',
    productPerformanceRecords: 'ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput: {
    date: 'AWSDate'
  },
  ModelInputCreateCompany: {
    createdAt: 'AWSDateTime',
    invoiceMethod: 'InvoiceMethod',
    paymentTermsType: 'PaymentTermsType',
    productCostPaymentTermsType: 'PaymentTermsType',
    subscriptionEndsAt: 'AWSDateTime',
    subscriptionLevel: 'SubscriptionLevel',
    subscriptionStartsAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreateNotification: {
    channel: 'NotificationChannel',
    createdAt: 'AWSDateTime',
    scheduledAt: 'AWSDateTime',
    scheduledFor: 'AWSDateTime',
    sentAt: 'AWSDateTime',
    status: 'NotificationStatus',
    type: 'NotificationType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreatePWProductSeoSummary: {
    createdAt: 'AWSDateTime',
    keywords: 'ModelInputCreatePWProductSeoSummaryKeywordsInput',
    productSEOSummaryRecords: 'ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreatePWProductSeoSummaryKeywordsInput: {
    badges: 'ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput',
    pageOneWinDate: 'AWSDate'
  },
  ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput: {
    badge: 'ListingBadge',
    date: 'AWSDate'
  },
  ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput: {
    categoryRanks: 'ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput'
  },
  ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput: {},
  ModelInputCreatePricing: {
    createdAt: 'AWSDateTime',
    creatorPricing: 'ModelInputCreatePricingCreatorPricingInput',
    store: 'Store',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreatePricingCreatorPricingInput: {
    type: 'PricingCreatorType'
  },
  ModelInputCreateProposal: {
    billingContacts: 'ModelInputCreateProposalBillingContactsInput',
    createdAt: 'AWSDateTime',
    creatorPricing: 'ModelInputCreateProposalCreatorPricingInput',
    eventDate: 'AWSDate',
    eventType: 'ProposalPromoEventType',
    goal: 'ProposalGoal',
    invoiceDueDate: 'AWSDate',
    invoicePaidDate: 'AWSDate',
    invoiceProductCostDueDate: 'AWSDate',
    invoiceProductCostPaidDate: 'AWSDate',
    invoiceProductCostStatus: 'InvoiceStatus',
    invoiceStatus: 'InvoiceStatus',
    launchDate: 'AWSDate',
    paymentBillingContact: 'ModelInputCreateProposalPaymentBillingContactInput',
    paymentType: 'InvoicePaymentType',
    status: 'ProposalStatus',
    statusChangedAt: 'AWSDateTime',
    submittedAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputCreateProposalBillingContactsInput: {},
  ModelInputCreateProposalCreatorPricingInput: {
    contentRequirements: 'ModelInputCreateProposalCreatorPricingInputContentRequirementsInput',
    type: 'CreatorType'
  },
  ModelInputCreateProposalCreatorPricingInputContentRequirementsInput: {
    contentType: 'ContentType'
  },
  ModelInputCreateProposalPaymentBillingContactInput: {},
  ModelInputCreateScrapeRequestJob: {
    createdAt: 'AWSDateTime',
    expirationTime: 'AWSDateTime',
    status: 'ScrapeRequestStatus',
    updatedAt: 'AWSDateTime'
  },
  ModelInputDeleteCampaignPerformance: {},
  ModelInputDeleteCampaignPerformanceProduct: {},
  ModelInputDeletePricing: {
    store: 'Store'
  },
  ModelInputDeleteProposal: {},
  ModelInputDeleteUserInvitation: {},
  ModelInputUpdateAgency: {
    createdAt: 'AWSDateTime',
    paymentTermsType: 'PaymentTermsType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateCampaignBenchmark: {
    benchmarkType: 'BenchmarkType',
    createdAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateCampaignPerformance: {
    comparisonMetric: 'PerformanceComparisonMetric',
    createdAt: 'AWSDateTime',
    summaryTitle: 'PerformanceSummaryTitleType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateCampaignPerformanceProduct: {
    comparisonMetric: 'PerformanceComparisonMetric',
    createdAt: 'AWSDateTime',
    productPerformanceRecords: 'ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput: {
    date: 'AWSDate'
  },
  ModelInputUpdateCompany: {
    createdAt: 'AWSDateTime',
    invoiceMethod: 'InvoiceMethod',
    paymentTermsType: 'PaymentTermsType',
    productCostPaymentTermsType: 'PaymentTermsType',
    subscriptionEndsAt: 'AWSDateTime',
    subscriptionLevel: 'SubscriptionLevel',
    subscriptionStartsAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateNotification: {
    channel: 'NotificationChannel',
    createdAt: 'AWSDateTime',
    scheduledAt: 'AWSDateTime',
    scheduledFor: 'AWSDateTime',
    sentAt: 'AWSDateTime',
    status: 'NotificationStatus',
    type: 'NotificationType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdatePWProductSeoSummary: {
    createdAt: 'AWSDateTime',
    keywords: 'ModelInputUpdatePWProductSeoSummaryKeywordsInput',
    productSEOSummaryRecords: 'ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdatePWProductSeoSummaryKeywordsInput: {
    badges: 'ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput',
    pageOneWinDate: 'AWSDate'
  },
  ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput: {
    badge: 'ListingBadge',
    date: 'AWSDate'
  },
  ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput: {
    categoryRanks: 'ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput'
  },
  ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput: {},
  ModelInputUpdatePricing: {
    createdAt: 'AWSDateTime',
    creatorPricing: 'ModelInputUpdatePricingCreatorPricingInput',
    store: 'Store',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdatePricingCreatorPricingInput: {
    type: 'PricingCreatorType'
  },
  ModelInputUpdateProductKeywordSummary: {
    createdAt: 'AWSDateTime',
    organicPageOneWinDate: 'AWSDate',
    organicPageOneWinType: 'PageOneWinType',
    sponsoredPageOneWinDate: 'AWSDate',
    sponsoredPageOneWinType: 'PageOneWinType',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateProductSeoSummary: {
    createdAt: 'AWSDateTime',
    productSeoSummaryRecords: 'ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput: {},
  ModelInputUpdateProposal: {
    billingContacts: 'ModelInputUpdateProposalBillingContactsInput',
    createdAt: 'AWSDateTime',
    creatorPricing: 'ModelInputUpdateProposalCreatorPricingInput',
    eventDate: 'AWSDate',
    eventType: 'ProposalPromoEventType',
    goal: 'ProposalGoal',
    invoiceDueDate: 'AWSDate',
    invoicePaidDate: 'AWSDate',
    invoiceProductCostDueDate: 'AWSDate',
    invoiceProductCostPaidDate: 'AWSDate',
    invoiceProductCostStatus: 'InvoiceStatus',
    invoiceStatus: 'InvoiceStatus',
    launchDate: 'AWSDate',
    paymentBillingContact: 'ModelInputUpdateProposalPaymentBillingContactInput',
    paymentType: 'InvoicePaymentType',
    status: 'ProposalStatus',
    statusChangedAt: 'AWSDateTime',
    submittedAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateProposalBillingContactsInput: {},
  ModelInputUpdateProposalCreatorPricingInput: {
    contentRequirements: 'ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput',
    type: 'CreatorType'
  },
  ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput: {
    contentType: 'ContentType'
  },
  ModelInputUpdateProposalPaymentBillingContactInput: {},
  ModelInputUpdateScrapeRequestJob: {
    createdAt: 'AWSDateTime',
    expirationTime: 'AWSDateTime',
    status: 'ScrapeRequestStatus',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateUser: {
    accessLevel: 'AccessLevel',
    createdAt: 'AWSDateTime',
    email: 'AWSEmail',
    lastActiveAt: 'AWSDateTime',
    role: 'Role',
    status: 'UserStatus',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateUserAgency: {
    createdAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateUserCampaignSocialContent: {
    createdAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime'
  },
  ModelInputUpdateUserInvitation: {
    accessLevel: 'AccessLevel',
    createdAt: 'AWSDateTime',
    email: 'AWSEmail',
    phoneNumber: 'AWSPhone',
    role: 'Role',
    status: 'UserInvitationStatus',
    updatedAt: 'AWSDateTime'
  },
  ModelIntKeyConditionInput: {},
  ModelMomentumInvitationSkKeyConditionInput: {
    beginsWith: 'UserInvitationStatus',
    between: 'UserInvitationStatus',
    eq: 'UserInvitationStatus',
    ge: 'UserInvitationStatus',
    gt: 'UserInvitationStatus',
    le: 'UserInvitationStatus',
    lt: 'UserInvitationStatus'
  },
  ModelProductRecommendationsSkKeyConditionInput: {
    beginsWith: 'RecommendationType',
    between: 'RecommendationType',
    eq: 'RecommendationType',
    ge: 'RecommendationType',
    gt: 'RecommendationType',
    le: 'RecommendationType',
    lt: 'RecommendationType'
  },
  ModelSearchStringInput: {
    field: 'ModelSearchStringFieldInput'
  },
  ModelStringKeyConditionInput: {},
  ProductInput: {
    estimatedAvailabilityDate: 'AWSDate',
    store: 'Store'
  },
  ProductSeoKeywordUploadRecord: {
    date: 'AWSDate'
  },
  PurchaseCreditsInput: {
    billingContacts: 'BillingContactInput',
    invoiceDueDate: 'AWSDate',
    paymentBillingContact: 'BillingContactInput'
  },
  PurchaseSubscriptionInput: {
    type: 'SubscriptionType'
  },
  RequestContentEditsInput: {},
  RequestExchangeRateInput: {
    from: 'Currency',
    to: 'Currency'
  },
  SubmitCampaignCreatorApprovalInput: {
    action: 'CampaignCreatorApprovalAction'
  },
  ToggleContentVisibilityInput: {},
  UpdateBrandInput: {
    enabledBrandApiTypes: 'BrandApiType',
    paymentBillingContact: 'BillingContactInput',
    region: 'Region',
    subscriptionEndsAt: 'AWSDateTime',
    subscriptionStartsAt: 'AWSDateTime'
  },
  UpdateManualProductInput: {
    store: 'Store'
  },
  UploadCreatorContentInput: {
    channel: 'Channel',
    contentType: 'ContentType',
    uploadType: 'ContentUploadType'
  },
  UploadProductSeoDataInput: {
    campaignStartDate: 'AWSDate',
    records: 'ProductSeoKeywordUploadRecord'
  },
  UserInvitationInput: {
    accessLevel: 'AccessLevel',
    email: 'AWSEmail',
    phoneNumber: 'AWSPhone',
    role: 'Role'
  }
}

export const ReturnTypes: Record<string, any> = {
  AdjustCampaignStartDateOutput: {
    startDate: 'AWSDateTime'
  },
  Agency: {
    clientPricing: 'Pricing',
    clientPricingId: 'String',
    createdAt: 'AWSDateTime',
    id: 'String',
    logo: 'String',
    name: 'String',
    paymentTermsCustomNetDays: 'Int',
    paymentTermsType: 'PaymentTermsType',
    pricing: 'Pricing',
    updatedAt: 'AWSDateTime',
    websiteUrl: 'String'
  },
  ApproveContentResonse: {
    userCampaignSocialContentId: 'String'
  },
  BillingContact: {
    email: 'String',
    name: 'String'
  },
  Brand: {
    amazonBrandName: 'String',
    amazonBrandStoreUrl: 'String',
    brandApis: 'BrandApi',
    companyId: 'String',
    createdAt: 'AWSDateTime',
    id: 'String',
    isBrandStoreScrapeEnabled: 'Boolean',
    isWalmartScrapeEnabled: 'Boolean',
    lastScrapedAt: 'AWSDateTime',
    logo: 'String',
    name: 'String',
    nameAudioKey: 'String',
    paymentBillingContact: 'BillingContact',
    pricing: 'Pricing',
    products: 'ModelItemsProduct',
    proposals: 'Proposal',
    region: 'Region',
    subscriptionCredits: 'SubscriptionCreditAction',
    subscriptionEndsAt: 'AWSDateTime',
    subscriptionStartsAt: 'AWSDateTime',
    trialSubscriptionStartedAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime',
    walmartBrandName: 'String',
    walmartLastScrapedAt: 'AWSDateTime',
    walmartScrapeUrl: 'String'
  },
  BrandApi: {
    accessToken: 'String',
    api: 'BrandApiType',
    apiVersion: 'BrandApiVersion',
    brandId: 'String',
    createdAt: 'AWSDateTime',
    enabled: 'Boolean',
    isIntegrated: 'Boolean',
    refreshToken: 'String',
    updatedAt: 'AWSDateTime'
  },
  Campaign: {
    brandId: 'String',
    cost: 'Int',
    createdAt: 'AWSDateTime',
    endDate: 'AWSDateTime',
    goal: 'ProposalGoal',
    id: 'String',
    numCreators: 'Int',
    numCreatorsJoined: 'Int',
    pauseReason: 'PauseReason',
    preLaunchDate: 'AWSDateTime',
    product: 'Product',
    proposal: 'Proposal',
    skuId: 'String',
    startDate: 'AWSDateTime',
    title: 'String'
  },
  CampaignBenchmark: {
    benchmarkGoal: 'Int',
    benchmarkType: 'BenchmarkType',
    benchmarkedProductIds: 'String',
    campaignId: 'String',
    createdAt: 'AWSDateTime',
    skuId: 'String',
    updatedAt: 'AWSDateTime'
  },
  CampaignClick: {
    clicks: 'Int',
    createdAt: 'AWSDateTime',
    date: 'AWSDate',
    updatedAt: 'AWSDateTime'
  },
  CampaignCreator: {
    content: 'ContentGroup',
    creator: 'CampaignCreatorCreator',
    creatorType: 'CreatorType',
    leftProductFeedback: 'Boolean',
    purchaseDetails: 'PurchaseDetails',
    status: 'CampaignCreatorStatus',
    userCampaignId: 'String'
  },
  CampaignCreatorCreator: {
    email: 'String',
    firstName: 'String',
    id: 'String',
    instagramProfile: 'SocialProfile',
    lastName: 'String',
    sponsoredPosts: 'String',
    tiktokProfile: 'SocialProfile',
    youtubeProfile: 'SocialProfile'
  },
  CampaignCreatorForApproval: {
    approvalState: 'CreatorApprovalState',
    creator: 'CampaignCreatorCreator',
    creatorType: 'CreatorType',
    dateOfBirth: 'AWSDate',
    locale: 'Region',
    userCampaignId: 'String'
  },
  CampaignDetails: {
    anticipatedStartDate: 'AWSDateTime',
    brandId: 'String',
    cost: 'Int',
    createdAt: 'AWSDateTime',
    creators: 'CampaignCreator',
    endDate: 'AWSDateTime',
    endedByType: 'CampaignEnderType',
    expectedClicksCounts: 'Int',
    expectedPlacementWins: 'Int',
    expectedPremiumUgcPostCount: 'Int',
    expectedReviewsCount: 'Int',
    expectedSocialPostCount: 'Int',
    expectedUgcPostCount: 'Int',
    goal: 'ProposalGoal',
    id: 'String',
    liftCampaignId: 'String',
    numAdditionalBrandAdvocateCreators: 'Int',
    numAdditionalBrandAdvocateCreatorsJoined: 'Int',
    numBrandAdvocateCreators: 'Int',
    numCreators: 'Int',
    numCreatorsJoined: 'Int',
    numPremiumUgcCreators: 'Int',
    numSocialCreators: 'Int',
    numUgcCreators: 'Int',
    pauseReason: 'PauseReason',
    preLaunchDate: 'AWSDateTime',
    product: 'Product',
    proposal: 'Proposal',
    releaseSchedule: 'ReleaseSchedule',
    skuId: 'String',
    startDate: 'AWSDateTime',
    title: 'String'
  },
  CampaignPerformance: {
    campaignId: 'String',
    comparisonMetric: 'PerformanceComparisonMetric',
    comparisonMetricValue: 'Int',
    createdAt: 'AWSDateTime',
    isBsrDataUnavailable: 'Boolean',
    isBsrVisible: 'Boolean',
    isTtrDataUnavailable: 'Boolean',
    isTtrVisible: 'Boolean',
    isVisible: 'Boolean',
    products: 'CampaignPerformanceProduct',
    promotedProductId: 'String',
    skuId: 'String',
    summaryTitle: 'PerformanceSummaryTitleType',
    updatedAt: 'AWSDateTime'
  },
  CampaignPerformanceProduct: {
    bestSellerRankDataUploaded: 'Boolean',
    campaignId: 'String',
    comparisonMetric: 'PerformanceComparisonMetric',
    createdAt: 'AWSDateTime',
    id: 'String',
    isCategoryAverage: 'Boolean',
    isVisible: 'Boolean',
    productImage: 'String',
    productName: 'String',
    productPerformanceRecords: 'ProductPerformanceRecord',
    productUrl: 'String',
    reviewDataUploaded: 'Boolean',
    skuId: 'String',
    updatedAt: 'AWSDateTime'
  },
  CampaignProductReviewRatingMetric: {
    fromDate: 'AWSDate',
    momentumRating: 'Float',
    momentumRatingCount: 'Int',
    momentumReviewCount: 'Int',
    momentumReviewRating: 'Float',
    numRatings: 'Int',
    numReviews: 'Int',
    organicRating: 'Float',
    organicRatingCount: 'Int',
    rating: 'Float',
    ratingsInPeriod: 'Int',
    reviews: 'Review',
    reviewsInPeriod: 'Int',
    toDate: 'AWSDate',
    vineRating: 'Float',
    vineReviewCount: 'Int'
  },
  CategoryRank: {
    category: 'String',
    rank: 'Int'
  },
  Company: {
    agency: 'Agency',
    agencyId: 'String',
    brands: 'Brand',
    createdAt: 'AWSDateTime',
    hasSeparateProductCostTerms: 'Boolean',
    id: 'String',
    invoiceMethod: 'InvoiceMethod',
    logo: 'String',
    name: 'String',
    paymentTermsCustomNetDays: 'Int',
    paymentTermsType: 'PaymentTermsType',
    pricing: 'Pricing',
    productCostPaymentTermsCustomNetDays: 'Int',
    productCostPaymentTermsType: 'PaymentTermsType',
    subscriptionEndsAt: 'AWSDateTime',
    subscriptionLevel: 'SubscriptionLevel',
    subscriptionStartsAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime',
    websiteUrl: 'String'
  },
  Content: {
    assets: 'ContentAsset',
    caption: 'String',
    comments: 'Int',
    engagements: 'Int',
    id: 'String',
    likes: 'Int',
    qualityScore: 'Int',
    views: 'Int'
  },
  ContentAsset: {
    type: 'String',
    url: 'String'
  },
  ContentGroup: {
    channel: 'Channel',
    content: 'Content',
    contentType: 'ContentType',
    createdAt: 'AWSDateTime',
    groupId: 'String',
    isFavorite: 'Boolean',
    isHidden: 'Boolean',
    platformContentLink: 'String',
    submittedAt: 'AWSDateTime'
  },
  ContentRequirement: {
    bonus: 'Int',
    contentType: 'ContentType'
  },
  CreateCampaignOutput: {
    id: 'String',
    invoiceLink: 'String'
  },
  CreateProposalSlideResult: {
    link: 'String'
  },
  CreatedByUser: {
    firstName: 'String',
    lastName: 'String'
  },
  Creator: {
    email: 'String',
    firstName: 'String',
    id: 'String',
    instagramProfile: 'SocialProfile',
    lastName: 'String',
    tiktokProfile: 'SocialProfile',
    youtubeProfile: 'SocialProfile'
  },
  CreatorApprovalState: {
    feedback: 'String',
    reviewedByUserEmail: 'String',
    reviewedByUserId: 'String',
    reviewedByUserName: 'String',
    status: 'CreatorApprovalStatus',
    updatedAt: 'AWSDateTime'
  },
  CreatorContent: {
    content: 'ContentGroup',
    creator: 'Creator',
    userCampaignId: 'String'
  },
  CreatorDraftContent: {
    creator: 'Creator',
    draftContent: 'DraftContent',
    id: 'String'
  },
  CreatorPricing: {
    price: 'Int',
    type: 'PricingCreatorType'
  },
  DraftContent: {
    channel: 'Channel',
    contentType: 'ContentType',
    draftMedia: 'String',
    draftStatus: 'DraftStatus',
    draftSubmittedAt: 'AWSDateTime',
    draftText: 'String',
    id: 'String',
    isHidden: 'Boolean',
    reviewFeedback: 'ReviewFeedback',
    reviewedAt: 'AWSDateTime',
    reviewerFirstName: 'String',
    reviewerLastName: 'String'
  },
  EndCampaignOutput: {
    endDate: 'String'
  },
  EtailerProductMetrics: {
    createdAt: 'AWSDateTime',
    date: 'AWSDate',
    pageViews: 'Int',
    skuId: 'String',
    totalSalesAmount: 'Int',
    unitsSold: 'Int',
    updatedAt: 'AWSDateTime'
  },
  GetCampaignGoalsSummaryOutput: {
    reviewsGoalHitAt: 'String'
  },
  LambdaResponse: {
    code: 'String',
    message: 'String',
    success: 'Boolean'
  },
  ListingBadgeRecord: {
    badge: 'ListingBadge',
    date: 'AWSDate'
  },
  ModelItemsAgency: {
    items: 'Agency',
    nextToken: 'String'
  },
  ModelItemsBrand: {
    items: 'Brand',
    nextToken: 'String'
  },
  ModelItemsBrandApi: {
    items: 'BrandApi',
    nextToken: 'String'
  },
  ModelItemsCampaignClick: {
    items: 'CampaignClick',
    nextToken: 'String'
  },
  ModelItemsCompany: {
    items: 'Company',
    nextToken: 'String'
  },
  ModelItemsEtailerProductMetrics: {
    items: 'EtailerProductMetrics',
    nextToken: 'String'
  },
  ModelItemsPricing: {
    items: 'Pricing',
    nextToken: 'String'
  },
  ModelItemsProduct: {
    items: 'Product',
    nextToken: 'String'
  },
  ModelItemsProductKeywordRecord: {
    items: 'ProductKeywordRecord',
    nextToken: 'String'
  },
  ModelItemsProductKeywordSummary: {
    items: 'ProductKeywordSummary',
    nextToken: 'String'
  },
  ModelItemsProductRatingSnapshot: {
    items: 'ProductRatingSnapshot',
    nextToken: 'String'
  },
  ModelItemsProductRecommendation: {
    items: 'ProductRecommendation',
    nextToken: 'String'
  },
  ModelItemsProposal: {
    items: 'Proposal',
    nextToken: 'String'
  },
  ModelItemsScrapeRequestJob: {
    items: 'ScrapeRequestJob',
    nextToken: 'String'
  },
  ModelItemsSubscriptionCreditAction: {
    items: 'SubscriptionCreditAction',
    nextToken: 'String'
  },
  ModelItemsUser: {
    items: 'User',
    nextToken: 'String'
  },
  ModelItemsUserAgency: {
    items: 'UserAgency',
    nextToken: 'String'
  },
  ModelItemsUserBrand: {
    items: 'UserBrand',
    nextToken: 'String'
  },
  ModelItemsUserCompany: {
    items: 'UserCompany',
    nextToken: 'String'
  },
  ModelItemsUserInvitation: {
    items: 'UserInvitation',
    nextToken: 'String'
  },
  ModelSearchItemssearchBrandContent: {
    items: 'CreatorContent',
    total: 'Int'
  },
  ModelSearchItemssearchBrandCounts: {
    items: 'SearchBrandCountsOutput',
    total: 'Int'
  },
  ModelSearchItemssearchBrandProducts: {
    items: 'Product',
    total: 'Int'
  },
  ModelSearchItemssearchRecommendations: {
    items: 'Product',
    total: 'Int'
  },
  Mutation: {
    adjustCampaignStartDate: 'AdjustCampaignStartDateOutput',
    adjustProductRecommendationVisibility: 'Product',
    adjustSubscriptionCredits: 'SubscriptionCreditAction',
    approveContent: 'ApproveContentResonse',
    createAgency: 'Agency',
    createBrand: 'Brand',
    createCampaign: 'CreateCampaignOutput',
    createCampaignBenchmark: 'CampaignBenchmark',
    createCampaignPerformance: 'CampaignPerformance',
    createCampaignPerformanceProduct: 'CampaignPerformanceProduct',
    createCompany: 'Company',
    createManualProduct: 'Product',
    createNotification: 'Notification',
    createPWProductSeoSummary: 'PWProductSeoSummary',
    createPricing: 'Pricing',
    createProductFromUrl: 'Product',
    createProposal: 'Proposal',
    createProposalSlide: 'CreateProposalSlideResult',
    createScrapeRequestJob: 'ScrapeRequestJob',
    createSupportTicket: 'LambdaResponse',
    deactivateUser: 'User',
    deleteCampaignPerformance: 'CampaignPerformance',
    deleteCampaignPerformanceProduct: 'CampaignPerformanceProduct',
    deletePricing: 'Pricing',
    deleteProposal: 'Proposal',
    deleteUserInvitation: 'UserInvitation',
    endCampaign: 'EndCampaignOutput',
    generateFeedbackReport: 'ProductFeedbackSummary',
    inviteUserToMomentum: 'UserInvitation',
    pingActivity: 'Boolean',
    purchaseSubscription: 'PurchaseSubscriptionOutput',
    purchaseSubscriptionCredits: 'SubscriptionCreditAction',
    reactivateUser: 'User',
    refreshRecommendations: 'RefreshRecommendationsOutput',
    requestContentEdits: 'ReviewFeedback',
    requestUploadFileLink: 'RequestUploadFileLinkResult',
    resetPassword: 'LambdaResponse',
    sendProposalInvoice: 'SendProposalInvoiceResult',
    signupUser: 'LambdaResponse',
    submitCampaignCreatorApproval: 'CampaignCreatorForApproval',
    toggleContentVisibility: 'ToggleContentVisibilityResonse',
    updateAgency: 'Agency',
    updateBrand: 'Brand',
    updateCampaignBenchmark: 'CampaignBenchmark',
    updateCampaignPerformance: 'CampaignPerformance',
    updateCampaignPerformanceProduct: 'CampaignPerformanceProduct',
    updateCampaignProduct: 'UpdateCampaignProductOutput',
    updateCompany: 'Company',
    updateManualProduct: 'Product',
    updateNotification: 'Notification',
    updatePWProductSeoSummary: 'PWProductSeoSummary',
    updatePricing: 'Pricing',
    updateProductKeywordSummary: 'ProductKeywordSummary',
    updateProductSeoSummary: 'ProductSeoSummary',
    updateProposal: 'Proposal',
    updateScrapeRequestJob: 'ScrapeRequestJob',
    updateUser: 'User',
    updateUserAgency: 'UserAgency',
    updateUserCampaignSocialContent: 'UserCampaignSocialContent',
    updateUserInvitation: 'UserInvitation',
    uploadCreatorContent: 'ContentGroup',
    uploadProductSeoData: 'ProductSeoSummary'
  },
  Notification: {
    address: 'String',
    channel: 'NotificationChannel',
    createdAt: 'AWSDateTime',
    id: 'String',
    resourceId: 'String',
    scheduledAt: 'AWSDateTime',
    scheduledFor: 'AWSDateTime',
    sentAt: 'AWSDateTime',
    status: 'NotificationStatus',
    type: 'NotificationType',
    updatedAt: 'AWSDateTime',
    userId: 'String'
  },
  PWProductSEOSummaryKeyword: {
    badges: 'ListingBadgeRecord',
    highestRank: 'Int',
    impressions: 'Int',
    keyword: 'String',
    pageOneWin: 'Boolean',
    pageOneWinDate: 'AWSDate',
    sponsoredHighestRank: 'Int',
    sponsoredStartRank: 'Int',
    startRank: 'Int'
  },
  PWProductSEOSummaryRecord: {
    categoryRanks: 'CategoryRank',
    impressions: 'Int',
    isOutOfStock: 'Boolean',
    topPageWins: 'Int',
    weekIndex: 'Int'
  },
  PWProductSeoSummary: {
    campaignId: 'String',
    createdAt: 'AWSDateTime',
    keywords: 'PWProductSEOSummaryKeyword',
    productSEOSummaryRecords: 'PWProductSEOSummaryRecord',
    updatedAt: 'AWSDateTime'
  },
  Pricing: {
    createdAt: 'AWSDateTime',
    creatorPricing: 'CreatorPricing',
    id: 'String',
    store: 'Store',
    updatedAt: 'AWSDateTime'
  },
  Product: {
    active: 'String',
    availabilityDate: 'AWSDateTime',
    brandId: 'String',
    categories: 'String',
    childSkus: 'ModelItemsProduct',
    createdAt: 'AWSDateTime',
    createdByUserId: 'String',
    creationSource: 'ProductCreationSource',
    creationSourceUrl: 'String',
    estimatedAvailabilityDate: 'AWSDate',
    id: 'String',
    image: 'String',
    initialEtailerProductMetric: 'EtailerProductMetrics',
    isRecommendationsHidden: 'Boolean',
    itemClassification: 'ItemClassification',
    lastScrapedAt: 'AWSDateTime',
    listingFulfillmentType: 'ListingFulfillmentType',
    listingLink: 'String',
    name: 'String',
    parentSku: 'Product',
    parentSkuId: 'String',
    price: 'String',
    priceCents: 'Int',
    productImageKey: 'String',
    ratingSnapshots: 'ModelItemsProductRatingSnapshot',
    ratingSummary: 'ProductRatingSnapshot',
    recommendations: 'ProductRecommendation',
    skuId: 'String',
    store: 'Store',
    updatedAt: 'AWSDateTime'
  },
  ProductFeedbackSummary: {
    campaignId: 'String',
    createdAt: 'AWSDateTime',
    failureReason: 'String',
    jsonDownloadUrl: 'String',
    status: 'ProductFeedbackStatus',
    updatedAt: 'AWSDateTime'
  },
  ProductKeywordRecord: {
    campaignId: 'String',
    campaignId_keyword: 'String',
    createdAt: 'AWSDateTime',
    date: 'AWSDate',
    keyword: 'String',
    keywordSales: 'Int',
    organicRank: 'Int',
    searchVolume: 'Int',
    sponsoredRank: 'Int',
    updatedAt: 'AWSDateTime'
  },
  ProductKeywordSummary: {
    campaignId: 'String',
    campaignId_keyword: 'String',
    createdAt: 'AWSDateTime',
    hasAmazonChoiceBadge: 'Boolean',
    keyword: 'String',
    organicHighestRank: 'Int',
    organicPageOneWin: 'Boolean',
    organicPageOneWinDate: 'AWSDate',
    organicPageOneWinType: 'PageOneWinType',
    organicStartRank: 'Int',
    records: 'ProductKeywordRecord',
    searchVolume: 'Int',
    sponsoredHighestRank: 'Int',
    sponsoredPageOneWin: 'Boolean',
    sponsoredPageOneWinDate: 'AWSDate',
    sponsoredPageOneWinType: 'PageOneWinType',
    sponsoredStartRank: 'Int',
    updatedAt: 'AWSDateTime'
  },
  ProductPerformanceRecord: {
    date: 'AWSDate',
    rating: 'Float',
    reviewCount: 'Int',
    salesRank: 'Int'
  },
  ProductRatingSnapshot: {
    numRatings: 'Int',
    numReviews: 'Int',
    rating: 'Float',
    sku: 'String',
    skuId: 'String',
    snapshotAt: 'AWSDateTime'
  },
  ProductRecommendation: {
    createdAt: 'AWSDateTime',
    recommendationType: 'RecommendationType',
    skuId: 'String',
    updatedAt: 'AWSDateTime'
  },
  ProductSeoSummary: {
    campaignId: 'String',
    createdAt: 'AWSDateTime',
    productSeoSummaryRecords: 'ProductSeoSummaryRecord',
    updatedAt: 'AWSDateTime'
  },
  ProductSeoSummaryRecord: {
    fromDate: 'String',
    isOutOfStock: 'Boolean',
    organicPageOneWins: 'Int',
    searchVolume: 'Int',
    sponsoredPageOneWins: 'Int',
    toDate: 'String'
  },
  Proposal: {
    benchmarkProducts: 'String',
    billingContacts: 'BillingContact',
    brand: 'Brand',
    brandId: 'String',
    createdAt: 'AWSDateTime',
    createdBy: 'User',
    createdByUserId: 'String',
    creatorPricing: 'ProposalCreatorPricing',
    creditActionId: 'String',
    durationWeeks: 'Int',
    estimatedAverageRating: 'Float',
    estimatedUnitsSoldPerMonth: 'Int',
    estimatedUnitsSoldPerWeek: 'Int',
    eventDate: 'AWSDate',
    eventType: 'ProposalPromoEventType',
    exchangeRate: 'Float',
    goal: 'ProposalGoal',
    hiddenEndRating: 'Boolean',
    hiddenNumReviews: 'Boolean',
    hiddenPdpTraffic: 'Boolean',
    hiddenRetailEfficiency: 'Boolean',
    hiddenSeo: 'Boolean',
    hiddenSocialContent: 'Boolean',
    hiddenUgcContent: 'Boolean',
    hubspotDealId: 'String',
    id: 'String',
    initialBenchmarkProducts: 'String',
    initialSearchTerms: 'String',
    invoiceAdditionalInformation: 'String',
    invoiceDueDate: 'AWSDate',
    invoiceId: 'String',
    invoiceLink: 'String',
    invoiceNumber: 'String',
    invoicePONumber: 'String',
    invoicePOSystem: 'String',
    invoicePaidAmount: 'Int',
    invoicePaidDate: 'AWSDate',
    invoiceProductCostDueDate: 'AWSDate',
    invoiceProductCostId: 'String',
    invoiceProductCostLink: 'String',
    invoiceProductCostNumber: 'String',
    invoiceProductCostPONumber: 'String',
    invoiceProductCostPaidAmount: 'Int',
    invoiceProductCostPaidDate: 'AWSDate',
    invoiceProductCostStatus: 'InvoiceStatus',
    invoiceStatus: 'InvoiceStatus',
    isDailyScheduling: 'Boolean',
    launchDate: 'AWSDate',
    liftCloseoutBonus: 'Int',
    liftNumCreators: 'Int',
    managedServiceFee: 'Float',
    operatingCost: 'Int',
    orderFormKey: 'String',
    paymentBillingContact: 'BillingContact',
    paymentType: 'InvoicePaymentType',
    product: 'Product',
    productId: 'String',
    productPrice: 'Int',
    productPriceOverride: 'Int',
    productRating: 'Float',
    productRatingCount: 'Int',
    productVariationSkus: 'String',
    ratingGoal: 'Float',
    referralCost: 'Int',
    searchTerms: 'String',
    status: 'ProposalStatus',
    statusChangedAt: 'AWSDateTime',
    submittedAt: 'AWSDateTime',
    submittedBy: 'User',
    submittedByUserId: 'String',
    title: 'String',
    totalCredits: 'Int',
    totalCreditsPaid: 'Int',
    totalProductCostCredits: 'Int',
    updatedAt: 'AWSDateTime',
    updatedBy: 'User',
    updatedByUserId: 'String'
  },
  ProposalCreatorPricing: {
    closeoutBonus: 'Int',
    contentRequirements: 'ContentRequirement',
    hidden: 'Boolean',
    numCreators: 'Int',
    price: 'Int',
    schedule: 'Int',
    type: 'CreatorType'
  },
  PurchaseDetails: {
    amountCents: 'Int',
    purchaseDate: 'AWSDateTime'
  },
  PurchaseSubscriptionOutput: {
    brandId: 'String',
    endsAt: 'AWSDateTime',
    startsAt: 'AWSDateTime',
    type: 'SubscriptionType'
  },
  Query: {
    companiesByAgency: 'ModelItemsCompany',
    generateCompanyRollupReport: 'String',
    getAgency: 'Agency',
    getAmazonAuthorizationUri: 'String',
    getBenchmarkMetrics: 'EtailerProductMetrics',
    getBrand: 'Brand',
    getBrandApi: 'BrandApi',
    getCampaign: 'CampaignDetails',
    getCampaignBenchmark: 'CampaignBenchmark',
    getCampaignGoalsSummary: 'GetCampaignGoalsSummaryOutput',
    getCampaignPerformance: 'CampaignPerformance',
    getCampaignPerformanceProduct: 'CampaignPerformanceProduct',
    getCampaignReviewMetrics: 'CampaignProductReviewRatingMetric',
    getCompany: 'Company',
    getNotification: 'Notification',
    getPWProductSeoSummary: 'PWProductSeoSummary',
    getPricing: 'Pricing',
    getProduct: 'Product',
    getProductFeedbackSummary: 'ProductFeedbackSummary',
    getProductRecommendation: 'ProductRecommendation',
    getProductSeoSummary: 'ProductSeoSummary',
    getProposal: 'Proposal',
    getUser: 'User',
    getUserAgency: 'UserAgency',
    getUserBrand: 'UserBrand',
    getUserCompany: 'UserCompany',
    getUserInvitation: 'UserInvitation',
    listAgencies: 'ModelItemsAgency',
    listBrandApis: 'ModelItemsBrandApi',
    listBrandCampaigns: 'Campaign',
    listBrandsByActive: 'ModelItemsBrand',
    listBrandsByActiveBySubscriptionEndsAt: 'ModelItemsBrand',
    listBrandsByCompanyId: 'ModelItemsBrand',
    listCampaignClicks: 'ModelItemsCampaignClick',
    listCampaignCreatorsForApproval: 'CampaignCreatorForApproval',
    listCompanies: 'ModelItemsCompany',
    listContentPendingApproval: 'CreatorDraftContent',
    listEtailerProductMetrics: 'ModelItemsEtailerProductMetrics',
    listInvitationsByAgency: 'ModelItemsUserInvitation',
    listInvitationsByCompany: 'ModelItemsUserInvitation',
    listInvitationsByStatus: 'ModelItemsUserInvitation',
    listPricings: 'ModelItemsPricing',
    listProductKeywordRecords: 'ModelItemsProductKeywordRecord',
    listProductKeywordSummaries: 'ModelItemsProductKeywordSummary',
    listProductRecommendations: 'ModelItemsProductRecommendation',
    listProducts: 'ModelItemsProduct',
    listProductsByBrandId: 'ModelItemsProduct',
    listProposals: 'ModelItemsProposal',
    listProposalsByBrandId: 'ModelItemsProposal',
    listProposalsByProductId: 'ModelItemsProposal',
    listSubscriptionCreditActions: 'ModelItemsSubscriptionCreditAction',
    listUserAgencies: 'ModelItemsUserAgency',
    listUserAgenciesByAgencyId: 'ModelItemsUserAgency',
    listUserBrands: 'ModelItemsUserBrand',
    listUserCompanies: 'ModelItemsUserCompany',
    listUserCompaniesByCompanyId: 'ModelItemsUserCompany',
    listUsers: 'ModelItemsUser',
    requestExchangeRate: 'RequestExchangeRateOutput',
    scrapeRequestByBrandIdBySku: 'ModelItemsScrapeRequestJob',
    searchBrandContent: 'ModelSearchItemssearchBrandContent',
    searchBrandCounts: 'ModelSearchItemssearchBrandCounts',
    searchBrandProducts: 'ModelSearchItemssearchBrandProducts',
    searchRecommendations: 'ModelSearchItemssearchRecommendations'
  },
  RefreshRecommendationsOutput: {
    isSuccess: 'Boolean'
  },
  ReleaseSchedule: {
    brandAdvocatesWeeklySchedule: 'Int',
    premiumUgcWeeklySchedule: 'Int',
    socialWeeklySchedule: 'Int',
    ugcWeeklySchedule: 'Int'
  },
  RequestExchangeRateOutput: {
    rate: 'Float'
  },
  RequestUploadFileLinkResult: {
    key: 'String',
    link: 'String'
  },
  Review: {
    attributedUserId: 'String',
    id: 'String',
    isVine: 'Boolean',
    name: 'String',
    rating: 'Float',
    reviewedAt: 'AWSDateTime',
    text: 'String',
    title: 'String'
  },
  ReviewFeedback: {
    atTime: 'Int',
    createdAt: 'AWSDateTime',
    createdByFirstName: 'String',
    createdByLastName: 'String',
    forAssetAtIndex: 'Int',
    message: 'String',
    visibleToUser: 'Boolean'
  },
  ScrapeRequestJob: {
    brandId: 'String',
    createdAt: 'AWSDateTime',
    expirationTime: 'AWSDateTime',
    id: 'String',
    message: 'String',
    sku: 'String',
    skuId: 'String',
    status: 'ScrapeRequestStatus',
    updatedAt: 'AWSDateTime'
  },
  SearchBrandCountsOutput: {
    brandId: 'String',
    totalProducts: 'Int',
    totalRecommendations: 'Int'
  },
  SendProposalInvoiceResult: {
    sent: 'Boolean'
  },
  SocialProfile: {
    engagementRate: 'Float',
    followers: 'Int',
    handle: 'String',
    isPrimary: 'Boolean'
  },
  SubscriptionCreditAction: {
    action: 'CreditAction',
    actionCreatedByFirstName: 'String',
    actionCreatedByIP: 'String',
    actionCreatedById: 'String',
    actionCreatedByLastName: 'String',
    actionId: 'String',
    associatedProposalId: 'String',
    billingContacts: 'BillingContact',
    createdAt: 'AWSDateTime',
    hubspotDealId: 'String',
    id: 'String',
    invoiceAdditionalInformation: 'String',
    invoiceDueDate: 'AWSDate',
    invoiceId: 'String',
    invoiceNumber: 'String',
    invoicePONumber: 'String',
    invoicePOSystem: 'String',
    invoicePaidDate: 'AWSDate',
    invoiceStatus: 'InvoiceStatus',
    label: 'String',
    numCredits: 'Int',
    orderFormKey: 'String',
    paymentBillingContact: 'BillingContact',
    proposal: 'Proposal',
    updatedAt: 'AWSDateTime'
  },
  ToggleContentVisibilityResonse: {
    isHidden: 'Boolean',
    userCampaignSocialContentId: 'String'
  },
  UpdateCampaignProductOutput: {
    productId: 'String'
  },
  User: {
    accessLevel: 'AccessLevel',
    companies: 'UserCompany',
    createdAt: 'AWSDateTime',
    email: 'AWSEmail',
    firstName: 'String',
    id: 'String',
    invitationId: 'String',
    lastActiveAt: 'AWSDateTime',
    lastName: 'String',
    phoneNumber: 'String',
    primaryBrandId: 'String',
    profilePicture: 'String',
    role: 'Role',
    status: 'UserStatus',
    updatedAt: 'AWSDateTime'
  },
  UserAgency: {
    agency: 'Agency',
    agencyId: 'String',
    companies: 'Company',
    createdAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime',
    user: 'User',
    userId: 'String'
  },
  UserBrand: {
    brand: 'Brand',
    brandId: 'String',
    companyId: 'String',
    createdAt: 'AWSDateTime',
    updatedAt: 'AWSDateTime',
    userId: 'String'
  },
  UserCampaignSocialContent: {
    createdAt: 'AWSDateTime',
    id: 'String',
    isFavorite: 'Boolean',
    isHidden: 'Boolean',
    updatedAt: 'AWSDateTime'
  },
  UserCompany: {
    company: 'Company',
    companyId: 'String',
    createdAt: 'AWSDateTime',
    isAllBrandAccess: 'Boolean',
    updatedAt: 'AWSDateTime',
    user: 'User',
    userBrands: 'UserBrand',
    userId: 'String'
  },
  UserInvitation: {
    accessLevel: 'AccessLevel',
    agencyId: 'String',
    brandIds: 'String',
    company: 'Company',
    companyId: 'String',
    createdAt: 'AWSDateTime',
    email: 'AWSEmail',
    firstName: 'String',
    id: 'String',
    isAllBrandAccess: 'Boolean',
    lastName: 'String',
    phoneNumber: 'AWSPhone',
    primaryBrandId: 'String',
    role: 'Role',
    status: 'UserInvitationStatus',
    updatedAt: 'AWSDateTime'
  }
}

export const Ops = {
  mutation: 'Mutation' as const,
  query: 'Query' as const
}
