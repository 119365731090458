import { PaymentTermsType } from '@productwindtom/shared-momentum-zeus-types'

export type AgencyInputData = {
  name: string
  logo: string
  websiteUrl: string
  id: string
  existingAgency?: boolean | undefined
  paymentTermsType?: PaymentTermsType | undefined
  paymentTermsCustomNetDays?: number | undefined
}

export enum PaymentTermsConfiguration {
  SINGLE = 'SINGLE',
  SPLIT = 'SPLIT'
}
