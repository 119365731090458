import { yupResolver } from '@hookform/resolvers/yup'
import { AudioSelect } from '@momentum/components/audio-select'
import { BrandNameInput } from '@momentum/forms/brand-form/BrandNameInput'
import {
  BrandStoreUrlInput,
  amazonBrandStoreCheck,
  walmartBrandStoreCheck
} from '@momentum/components/brand-store-url-input'
import { ImageSelect } from '@momentum/components/image-select'
import { BRAND_API_TYPE_BY_REGION, BRAND_API_TYPE_LABELS } from '@momentum/utils/brandApiUtils'
import { getImageKeyFromUrl } from '@momentum/utils/imageUtils'
import { REGION_OPTIONS, RETAILER_OPTION_CONFIGS } from '@momentum/utils/selectOptions'
import { urlPreTransform } from '@momentum/utils/validationUtils'
import { Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  BrandApiType,
  Region,
  UploadRequestType,
  PaymentTermsType,
  InvoiceMethod
} from '@productwindtom/shared-momentum-zeus-types'
import {
  CheckInput,
  Form,
  SelectInput,
  SubmitButton,
  SwitchInput,
  TextInput,
  RadioInput
} from '@productwindtom/ui-base'
import { useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import * as yup from 'yup'
import { BackButton } from '../../components/back-button'
import { Retailer } from '@productwindtom/shared-momentum'
import SelectInputAdd from '../../components/form-inputs/SelectInputAdd'
import { AgencyInputData, PaymentTermsConfiguration } from '../types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { agencyFieldsSchema } from '../agency-form/validation'
import PaymentTermsFields from '../inputs/PaymentTermsFields'
import AgencyFormFields from '../agency-form/AgencyFormFields'
import { useFlag } from '@unleash/proxy-client-react'

const schema = (existingBrandNames: string[]) =>
  yup
    .object({
      companyId: yup.string().optional(),
      existingCompany: yup.boolean().optional(),
      companyName: yup.string().required('Required'),
      companyLogo: yup
        .string()
        .required('Required')
        .transform(s => (s ? getImageKeyFromUrl(s) : s)),
      companyWebsiteUrl: yup.string().transform(urlPreTransform).url('Not a valid URL').required('Required'),
      companyPaymentTermsConfiguration: yup.string<PaymentTermsConfiguration>().optional(),
      companyPaymentTermsType: yup.string<PaymentTermsType>().required('Required'),
      companyPaymentTermsCustomNetDays: yup.number().when('paymentTermsType', {
        is: PaymentTermsType.NET_CUSTOM,
        then: s => s.required('Required'),
        otherwise: s => s.nullable().optional()
      }),
      companyProductCostPaymentTermsType: yup.string<PaymentTermsType>().when('companyHasSeparateProductCostTerms', {
        is: true,
        then: s => s.required('Required'),
        otherwise: s => s.nullable().optional()
      }),
      companyProductCostPaymentTermsCustomNetDays: yup.number().when('companyProductCostPaymentTermsType', {
        is: PaymentTermsType.NET_CUSTOM,
        then: s => s.required('Required'),
        otherwise: s => s.nullable().optional()
      }),
      existingBrand: yup.boolean().optional(),
      name: yup
        .string()
        .required('Required')
        .test(
          'unique',
          'Brand with this name already exists',
          value => !value.trim() || !existingBrandNames.includes(value.toLowerCase().trim())
        ),
      nameAudioKey: yup
        .string()
        .optional()
        .nullable()
        .transform(s => (s ? getImageKeyFromUrl(s) : s)),
      region: yup.string().required('Required'),
      logo: yup
        .string()
        .required('Required')
        .transform(s => (s ? getImageKeyFromUrl(s) : s)),
      retailers: yup.array().of(yup.string()).required('Required').min(1, 'Minimum of one selected'),
      amazonBrandStoreUrl: amazonBrandStoreCheck.optional(),
      isBrandStoreScrapeEnabled: yup.boolean().optional(),
      walmartScrapeUrl: walmartBrandStoreCheck.optional(),
      isWalmartScrapeEnabled: yup.boolean().optional(),
      enabledBrandApiTypes: yup.array().of(yup.string()),
      agency: agencyFieldsSchema
    })
    .noUnknown(true)

export type BrandFormData = {
  companyId: string
  existingCompany?: boolean
  companyName: string
  companyLogo?: string
  companyWebsiteUrl: string
  companyInvoiceMethod: InvoiceMethod
  companyPaymentTermsConfiguration: PaymentTermsConfiguration
  companyPaymentTermsType: PaymentTermsType
  companyPaymentTermsCustomNetDays?: number
  companyProductCostPaymentTermsType?: PaymentTermsType
  companyProductCostPaymentTermsCustomNetDays?: number
  id: string
  name: string
  existingBrand?: boolean
  region: Region
  nameAudioKey?: string
  logo?: string
  retailers: Retailer[]
  amazonBrandStoreUrl?: string
  isBrandStoreScrapeEnabled?: boolean
  walmartScrapeUrl?: string
  isWalmartScrapeEnabled?: boolean
  enabledBrandApiTypes?: BrandApiType[]
  agency?: AgencyInputData
}

export const BrandForm = ({
  onSubmit,
  onCancel
}: {
  onCancel: () => void
  onSubmit: (data: BrandFormData) => Promise<void>
}) => {
  const { brands } = useUserSessionContext()
  const handleSubmit = async (submitValues: BrandFormData) => {
    await onSubmit(submitValues)
  }

  const existingBrandNames = brands.map(b => b.name.toLowerCase().trim())

  return (
    <Form onSubmit={handleSubmit} resolver={yupResolver(schema(existingBrandNames)) as any}>
      <Stack spacing={3}>
        <CompanyFormFields />
        <AgencyFormFields />
        <BrandFormBody existingBrandNames={existingBrandNames} />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false}>
            Save
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const BrandFormBody = ({ existingBrandNames }: { existingBrandNames: string[] }) => {
  const { watch } = useFormContext()
  const data = watch()
  const regionWatch = watch(`region`)
  const retailersWatch = watch(`retailers`)
  const isBrandStoreScrapeEnabledWatch = watch(`isBrandStoreScrapeEnabled`)
  const availableRetailers = RETAILER_OPTION_CONFIGS.filter(r => !r.regions || r.regions.includes(regionWatch))

  return (
    <Stack spacing={4}>
      <Typography variant={'h4'}>Add new brand</Typography>

      <Stack direction={'row'} spacing={6}>
        <Stack spacing={2} width={'65%'}>
          <BrandNameInput existingBrandNames={existingBrandNames} name={'name'} />
          <SelectInput options={REGION_OPTIONS} name={`region`} primaryText={'Brand region'} />
          <ImageSelect
            name={`logo`}
            primaryText={'Upload brand logo'}
            buttonText={'Click to upload'}
            buttonProps={{
              variant: 'outlined'
            }}
            brandId={data.id}
            uploadType={UploadRequestType.UPLOAD_BRAND_LOGO}
          />
        </Stack>
        {!!regionWatch && (
          <CheckInput
            options={availableRetailers.map(r => ({ ...r, label: r.value }))}
            primaryText={'Retailers'}
            name={`retailers`}
          />
        )}
      </Stack>
      {retailersWatch?.includes(Retailer.AMAZON) && (
        <>
          <Stack spacing={1} maxWidth={'85%'}>
            <Typography variant={'label2'}>Weekly products and recommendations</Typography>
            <Typography variant={'label4'}>
              Turn on weekly products & recommendations ONLY for Enterprise clients and active prospects. Go to the
              recommendations page if you need to immediately refresh your recommendations.
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <SwitchInput name="isBrandStoreScrapeEnabled" />
              <Typography variant={'body1'}>
                Weekly products & recommendations {isBrandStoreScrapeEnabledWatch ? 'on' : 'off'}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1} maxWidth={'85%'}>
            <Typography variant={'label2'}>Retailer integrations</Typography>
            <Typography variant={'label4'}>
              Momentum will show 1P brand recommendations if you select “Amazon vendor” and 3P recommendations if you
              select “Amazon seller.”
            </Typography>
            <CheckInput
              options={Object.values(
                BRAND_API_TYPE_BY_REGION[regionWatch as keyof typeof BRAND_API_TYPE_BY_REGION]
              ).map(apiType => ({
                value: apiType,
                label: BRAND_API_TYPE_LABELS[apiType]
              }))}
              name={'enabledBrandApiTypes'}
            />
          </Stack>
        </>
      )}
      <Stack spacing={1}>
        <Typography variant={'label2'}>Brand name pronunciation</Typography>
        <AudioSelect
          uploadType={UploadRequestType.UPLOAD_BRAND_AUDIO}
          name={'nameAudioKey'}
          brandId={data.id}
          primaryText={'Add an audio clip of someone pronouncing the brand correctly'}
          buttonProps={{
            variant: 'outlined'
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <Typography variant={'label4'}>
          <Typography variant={'label2'}>Optional:</Typography> Add the links to your brand stores so we can review your
          products.
        </Typography>
        <Grid container>
          <Grid item xs={6} sm={4}>
            <Typography variant={'label2'}>Retailer</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'label2'}>Brand Store URL</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={4} mt={1}>
            <Typography variant={'label3'}>Amazon Brand Store</Typography>
          </Grid>
          <Grid item xs>
            <BrandStoreUrlInput name={`amazonBrandStoreUrl`} placeholder={'Amazon Brand Store URL'} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={4} mt={1}>
            <Typography variant={'label3'}>Walmart Brand Store</Typography>
          </Grid>
          <Grid item xs>
            <BrandStoreUrlInput name={`walmartScrapeUrl`} placeholder={'Walmart Brand Store URL'} />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

const CompanyFormFields = () => {
  const { companies, agencies } = useUserSessionContext()
  const { setValue: setFormValue, trigger } = useFormContext()
  const companySplitCostsFlag = useFlag('CompanySplitCosts')
  const companyId = useWatch({ name: 'companyId' })
  const companyHasSplitPaymentTerms =
    useWatch({ name: 'companyPaymentTermsConfiguration' }) === PaymentTermsConfiguration.SPLIT

  const handleChange = useCallback(
    (value?: { value: string; name: string } | null) => {
      if (value) {
        const company = companies.find(({ id }) => id === value.value)
        const agency = agencies.find(({ id }) => company?.agencyId === id)

        setFormValue('existingCompany', !!company)
        setFormValue('companyId', company?.id || v4())
        setFormValue('companyName', value.name || company?.name)
        setFormValue('companyWebsiteUrl', company?.websiteUrl || '')
        setFormValue('companyLogo', company?.logo || null)
        setFormValue(
          'companyPaymentTermsConfiguration',
          company?.hasSeparateProductCostTerms ? PaymentTermsConfiguration.SPLIT : PaymentTermsConfiguration.SINGLE
        )
        setFormValue('companyPaymentTermsType', company?.paymentTermsType || PaymentTermsType.DEFAULT_7_DAYS)
        setFormValue('companyPaymentTermsCustomNetDays', company?.paymentTermsCustomNetDays)
        setFormValue('companyInvoiceMethod', company?.invoiceMethod || InvoiceMethod.BILL)
        setFormValue(
          'agency',
          agency && {
            ...agency,
            existingAgency: true
          }
        )
      } else {
        setFormValue('existingCompany', false)
        setFormValue('companyId', null)
        setFormValue('companyName', '')
        setFormValue('companyWebsiteUrl', '')
        setFormValue('companyLogo', null)
        setFormValue('agency', undefined)
        setFormValue('companyPaymentTermsConfiguration', PaymentTermsConfiguration.SINGLE)
        setFormValue('companyPaymentTermsType', PaymentTermsType.DEFAULT_7_DAYS)
        setFormValue('companyInvoiceMethod', InvoiceMethod.BILL)
        setFormValue('companyPaymentTermsCustomNetDays', undefined)
      }

      trigger(['companyName', 'companyWebsiteUrl', 'companyLogo', 'agency'])
    },
    [companies, agencies, setFormValue, trigger]
  )

  return (
    <Stack spacing={1}>
      <SelectInputAdd
        name={'companyName'}
        primaryText="Search existing or add new company"
        options={companies.map(company => ({ name: company.name, value: company.id }))}
        onChange={handleChange}
      />

      {companyId && (
        <Stack spacing={2}>
          <TextInput name={'companyWebsiteUrl'} primaryText={'Company website'} placeholder={'Company website URL'} />
          {companySplitCostsFlag && (
            <RadioInput
              name={'companyPaymentTermsConfiguration'}
              primaryText={'Does this company have the same payment terms for creator and product costs?'}
              options={[
                {
                  label: (
                    <Typography variant={'label3'}>
                      Yes, the company pays one invoice for the entire campaign.
                    </Typography>
                  ),
                  value: PaymentTermsConfiguration.SINGLE
                },
                {
                  label: (
                    <Typography variant={'label3'}>
                      No, the company pays one invoice for creator costs and a separate invoice for product costs.
                    </Typography>
                  ),
                  value: PaymentTermsConfiguration.SPLIT
                }
              ]}
            />
          )}
          {companyHasSplitPaymentTerms ? (
            <>
              <PaymentTermsFields
                paymentTermsTypeName="companyPaymentTermsType"
                paymentTermsCustomNetDaysName="companyPaymentTermsCustomNetDays"
                primaryText={'What are the payment terms for the creator cost?'}
              />
              <PaymentTermsFields
                paymentTermsTypeName="companyProductCostPaymentTermsType"
                paymentTermsCustomNetDaysName="companyProductCostPaymentTermsCustomNetDays"
                primaryText={'What are the payment terms for the product cost?'}
              />
            </>
          ) : (
            <PaymentTermsFields
              paymentTermsTypeName="companyPaymentTermsType"
              paymentTermsCustomNetDaysName="companyPaymentTermsCustomNetDays"
            />
          )}

          <RadioInput
            primaryText={'What is this company’s invoice method?'}
            name={'companyInvoiceMethod'}
            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
            options={[
              {
                label: <Typography variant={'label3'}>Generate invoices using Bill.com</Typography>,
                value: InvoiceMethod.BILL
              },
              {
                label: <Typography variant={'label3'}>Generate invoices using a PO</Typography>,
                value: InvoiceMethod.CUSTOM_PO
              }
            ]}
          />
          <ImageSelect
            name={'companyLogo'}
            primaryText="Upload company logo"
            buttonText={'Click to upload'}
            companyId={companyId}
            buttonProps={{
              variant: 'outlined'
            }}
            uploadType={UploadRequestType.UPLOAD_COMPANY_LOGO}
          />
        </Stack>
      )}
    </Stack>
  )
}
