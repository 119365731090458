import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-ws-zeus-types'

export const userSelector = Selector('User')({
  id: true,
  firstName: true,
  lastName: true,
  email: true,
  date_of_birth: true,
  addressComponents: true,
  signupCountry: true,

  instagramHandle: true,
  instagramFollowersInt: true,
  instagramTrailingPostEngagementRateAvg: true,
  instagramPriority: true,

  tiktokHandle: true,
  tiktokFollowersInt: true,
  tiktokMedianPostEngagementRateViews: true,
  tiktokPriority: true,

  youtubeChannelHandle: true,
  youtubeChannelFollowersInt: true,

  contentRatingSponsorshipContent: {
    link: true,
    isInappropriate: true,
    isCompliant: true
  }
})

export const contentMetricsSelector = Selector('SocialMetric')({
  name: true,
  value: true
})

export const socialContentSelector = Selector('UserCampaignSocialContent')({
  id: true,
  groupId: true,
  isHidden: true,
  isFavorite: true,
  caption: true,
  channel: true,
  contentType: true,
  postedAt: true,
  mediaUrls: true,
  mediaId: true,
  externalUrl: true,
  videoStartTime: true,
  qualityScore: true,
  createdAt: true,
  metrics: contentMetricsSelector
})

export const purchaseDetailsSelector = Selector('Purchase')({
  status: true,
  amount: true,
  purchasedAt: true,
  currency: true
})

export const userCampaignSelectorDetail = Selector('UserCampaign')({
  campaignStatus: true,
  selectedFlow: true,
  coarseCampaignStatus: true,
  productId: true,
  id: true,
  productCriticalFeedback: true,
  productPositiveFeedback: true,
  closeoutSurvey: true,
  statusHistory: true,
  user: userSelector,
  purchases: [{}, { items: purchaseDetailsSelector }],
  socialContent: [{}, { items: socialContentSelector }]
})

const autobuyReleaseSchedule = Selector('AutoBuyFlowReleaseSchedule')({
  date: true,
  released: true,
  adjustedDesiredPurchases: true,
  actualPurchases: true
})

const autobuyConfiguration = Selector('AutoBuyFlowConfiguration')({
  pauseReason: true
})

export const productSelector = Selector('Product')({
  id: true,
  qualityLevel: true,

  campaignStateMachine: true,

  brandAdvocateCount: true,
  creatorCount: true,
  unitsPurchased: true,

  contentRequirement: {
    type: true,
    channel: true,
    requirementLevel: true
  },
  autoBuyReleaseSchedule: [{}, { items: autobuyReleaseSchedule }],
  autoBuyConfiguration: autobuyConfiguration
})

export const campaignSelector = Selector('Campaign')({
  id: true,
  createdAt: true,
  brandId: true,
  title: true,
  goal: true,
  committedClicks: true,
  skuId: true,

  anticipatedStartDate: true,
  actualStartDate: true,
  preLaunchDate: true,
  endDate: true,
  endedById: true,
  endedByType: true,

  marketingFee: true,
  socialCommittedContentCount: true,
  lifestyleCommittedContentCount: true,
  premiumLifestyleCommittedContentCount: true,
  committedReviewsCount: true,
  committedPlacementWinsCount: true,
  campaignProducts: [
    {},
    {
      items: productSelector
    }
  ]
})

export type Campaign = InputType<GraphQLTypes['Campaign'], typeof campaignSelector>
export type AutoBuyReleaseSchedule = InputType<
  GraphQLTypes['AutoBuyFlowReleaseSchedule'],
  typeof autobuyReleaseSchedule
>
export type UserCampaign = InputType<GraphQLTypes['UserCampaign'], typeof userCampaignSelectorDetail>
export type SocialContent = InputType<GraphQLTypes['UserCampaignSocialContent'], typeof socialContentSelector>
export type SocialMetric = InputType<GraphQLTypes['SocialMetric'], typeof contentMetricsSelector>
export type Purchase = InputType<GraphQLTypes['Purchase'], typeof purchaseDetailsSelector>
