import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { RoutesBreadcrumb } from '@momentum/components/routes-breadcrumb-v2'
import { DataGrid } from '@momentum/components/table'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { CompanyForm, CompanyFormData } from '@momentum/forms/company-form'
import { useClientBreadcrumbs } from '@momentum/hooks/useClientBreadcrumbs'
import { CompanyPricing } from '@momentum/routes/company/CompanyPricing'
import { BRAND_TABLE_DEFINITION } from '@momentum/routes/company/constants'
import { ROUTES } from '@momentum/routes/RouteNames'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { RegionToName } from '@momentum/utils/regionMappings'
import { Close, Search } from '@mui/icons-material'
import { Autocomplete, Box, Button, Dialog, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { PaymentTermsType, Region, InvoiceMethod } from '@productwindtom/shared-momentum-zeus-types'
import { LinkRouter } from '@productwindtom/ui-base'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import { generatePath, Navigate, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createAgency, updateAgency } from '../brands/queries'
import { updateCompany } from './queries'
import { Company as CompanyType, getCompany } from '@momentum/routes/queries'
import Loading from '@momentum/components/loading'
import { PaymentTermsConfiguration } from '@momentum/forms/types'

export const Company = () => {
  const { companyId, agencyId } = useParams<{ companyId: string; agencyId?: string }>()
  const { state } = useLocation()
  const { isAdminView, brands, refreshCompany, agencies } = useUserSessionContext()

  const [search, setSearch] = useState('')
  const [regionFilter, setRegionFilter] = useState<Region | null>(null)
  const [isEditOpen, setIsEditOpen] = useState(false)

  const [company, setCompany] = useState<CompanyType>()

  useEffect(() => {
    if (companyId) {
      getSetCompany(companyId)
    }
  }, [companyId])

  const getSetCompany = async (id: string) => {
    setCompany(undefined)
    getCompany(id).then(setCompany)
  }

  const companyBrands = brands.filter(b => b.companyId === companyId)
  const agency = agencies.find(a => a.id === agencyId)

  const crumbs = useClientBreadcrumbs(company)

  useEffect(() => {
    if (state?.brand?.name) {
      setSearch(state.brand.name)
    }
  }, [companyId, state])

  const handleEditClick = () => {
    setIsEditOpen(true)
  }

  const handleClose = () => {
    setIsEditOpen(false)
  }

  const onEdit = async ({ agency, paymentTermsConfiguration, ...data }: CompanyFormData) => {
    if (companyId) {
      let agencyRes
      if (agency?.id) {
        const input = {
          id: agency.id,
          name: agency.name,
          logo: agency.logo,
          websiteUrl: agency.websiteUrl,
          paymentTermsType: agency.paymentTermsType,
          paymentTermsCustomNetDays: agency.paymentTermsCustomNetDays
        }
        agencyRes = agency.existingAgency ? await updateAgency(input) : await createAgency(input)
      }

      const agencyId = agencyRes?.id

      const hasSplitCosts = paymentTermsConfiguration === PaymentTermsConfiguration.SPLIT
      await updateCompany({
        id: companyId,
        ...data,
        agencyId: agencyId ? agencyId : null,
        hasSeparateProductCostTerms: hasSplitCosts,
        productCostPaymentTermsType: hasSplitCosts ? data.productCostPaymentTermsType : null,
        productCostPaymentTermsCustomNetDays: hasSplitCosts ? data.productCostPaymentTermsCustomNetDays : null
      })
      getSetCompany(companyId)
      await refreshCompany(companyId)
    }

    handleClose()
    toast(<Typography variant={'subtitle2'}>Successfully updated company!</Typography>, { type: 'success' })
  }

  if (!companyId) {
    return <Navigate to={ROUTES.HOME} />
  }

  if (!company) {
    return (
      <Box mt={10}>
        <Loading />
      </Box>
    )
  }

  const lowerSearch = search.toLowerCase()

  const rows = orderBy(
    companyBrands
      .filter(
        b => (!search || b.name.toLowerCase().includes(lowerSearch)) && (!regionFilter || b.region === regionFilter)
      )
      .map(brand => ({
        ...brand,
        image: brand.logo,
        products: brand.totalProducts,
        proposals: brand.proposals.length,
        recommendations: brand.totalRecommendations,
        agencyId
      })),
    b => b.name,
    'asc'
  )

  return (
    <Stack mb={5} mt={3}>
      <RoutesBreadcrumb crumbs={crumbs} />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2} mb={3}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Paper sx={{ width: '73px', height: '73px', p: 1 }}>
            <img
              src={getCdnImageUrl(company.logo)}
              alt={company.name}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Paper>
          <Stack spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant={'h3'}>{company.name}</Typography>

              {isAdminView && (
                <Button variant={'text'} color={'primary'} onClick={handleEditClick}>
                  Edit
                </Button>
              )}
            </Stack>
            {agency?.name && (
              <LinkRouter to={generatePath(ROUTES.AGENCY, { agencyId: agency.id })}>{agency.name}</LinkRouter>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={2}>
        <Box>
          <DebouncedTextField
            defaultValue={state?.brand?.name}
            placeholder={'Search brands'}
            sx={{ mb: 4, width: 300 }}
            onChange={setSearch}
            InputProps={{
              endAdornment: <Search />
            }}
          />
        </Box>
        <Box>
          <Autocomplete
            sx={{ width: 200 }}
            value={regionFilter}
            onChange={(_e, value) => setRegionFilter(value)}
            options={[null, ...Object.values(Region)]}
            getOptionLabel={option => (option ? RegionToName[option] : 'All')}
            renderInput={params => <TextField {...params} placeholder="Filter by country" />}
          />
        </Box>
      </Stack>
      <DataGrid
        autoHeight
        rows={rows}
        columns={BRAND_TABLE_DEFINITION.map(d => ({ ...d, resizeable: false, sortable: d.sortable || true }))}
        disableColumnMenu={true}
        disableColumnReorder={true}
        disableRowSelectionOnClick
      />
      {isAdminView && <CompanyPricing company={company} />}

      <Dialog open={isEditOpen} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <Stack p={3}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>Edit company</Typography>
            <Box>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <CompanyForm
            defaultData={{
              ...company,
              websiteUrl: company.websiteUrl || '',
              invoiceMethod: company.invoiceMethod || InvoiceMethod.BILL,
              paymentTermsConfiguration: company.hasSeparateProductCostTerms
                ? PaymentTermsConfiguration.SPLIT
                : PaymentTermsConfiguration.SINGLE,
              paymentTermsType: company.paymentTermsType || PaymentTermsType.DEFAULT_7_DAYS,
              productCostPaymentTermsType: company.productCostPaymentTermsType || PaymentTermsType.DEFAULT_7_DAYS
            }}
            onCancel={handleClose}
            onSubmit={onEdit}
          />
        </Stack>
      </Dialog>
    </Stack>
  )
}
