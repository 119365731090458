import { SvgIcon, SvgIconProps } from '@mui/material'
export default function RestartCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M9.02648 1.71143C7.23007 1.408 5.4036 1.80337 3.90372 2.86979C0.803949 5.07372 0.0670532 9.43645 2.27099 12.5362C3.53624 14.4216 5.57369 15.3817 7.67008 15.4718L7.53134 13.6887C6.0482 13.6141 4.59511 12.8408 3.67088 11.5409C2.03571 9.24106 2.58476 5.99041 4.89904 4.26968C5.99896 3.48764 7.45435 3.20557 8.7375 3.4223L8.49187 4.87654L11.8732 3.07202L9.31268 0L9.02648 1.71143ZM11.9374 14.1689C14.5372 12.3205 15.4713 8.53606 14.3795 5.62413L12.8656 7.11102C13.4887 9.15192 12.742 11.4893 10.8565 12.7546C10.3566 13.1101 9.78552 13.3656 9.14337 13.521L9.36766 15.3186C10.2809 15.1209 11.1375 14.7377 11.9374 14.1689Z"
        fill="#324AA8"
      />
      <path
        d="M7.53134 13.6887L7.67008 15.4718C8.38744 15.5026 8.69827 15.4635 9.36766 15.3186L9.14337 13.521C8.42552 13.6342 8.29299 13.6914 7.53134 13.6887Z"
        fill="#324AA8"
      />
      <path
        d="M7.16069 9.24128L5.3963 7.5055L4.45312 8.42867L7.16069 11.0399L14.04 4.29219L13.101 3.4209L7.16069 9.24128Z"
        fill="#12B76A"
      />
    </SvgIcon>
  )
}
