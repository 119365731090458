import { GraphQLTypes, InputType, Selector } from '@productwindtom/shared-momentum-zeus-types'

export const productSeoSummarySelector = Selector('ProductSeoSummary')({
  campaignId: true,
  productSeoSummaryRecords: {
    fromDate: true,
    toDate: true,
    searchVolume: true,
    organicPageOneWins: true,
    sponsoredPageOneWins: true,
    isOutOfStock: true
  }
})

export const productKeywordRecordSelector = Selector('ProductKeywordRecord')({
  date: true,
  searchVolume: true,
  organicRank: true,
  sponsoredRank: true,
  keywordSales: true
})

export const productKeywordSummarySelector = Selector('ProductKeywordSummary')({
  campaignId: true,
  campaignId_keyword: true,
  keyword: true,
  searchVolume: true,
  organicStartRank: true,
  organicHighestRank: true,
  sponsoredStartRank: true,
  sponsoredHighestRank: true,
  organicPageOneWinDate: true,
  organicPageOneWin: true,
  organicPageOneWinType: true,
  sponsoredPageOneWinDate: true,
  sponsoredPageOneWinType: true,
  sponsoredPageOneWin: true,
  hasAmazonChoiceBadge: true,
  records: productKeywordRecordSelector
})

export type ProductSeoSummary = InputType<GraphQLTypes['ProductSeoSummary'], typeof productSeoSummarySelector>
export type ProductSeoSummaryRecord = InputType<
  GraphQLTypes['ProductSeoSummaryRecord'],
  typeof productSeoSummarySelector.productSeoSummaryRecords
>
export type ProductKeywordSummary = InputType<
  GraphQLTypes['ProductKeywordSummary'],
  typeof productKeywordSummarySelector
>
export type ProductKeywordRecord = InputType<GraphQLTypes['ProductKeywordRecord'], typeof productKeywordRecordSelector>
export type ExtendedProductKeywordSummary = ProductKeywordSummary & {
  isClientAdded: boolean
  hasUploadedData: boolean
}
