import { Button, Stack, Typography } from '@mui/material'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { DateTime } from 'luxon'
import AppointmentSuccessImage from '../AppointmentSuccess.png'
import React from 'react'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

export const TrialSuccess = () => {
  const { selectedBrand } = useUserSessionContext()
  return (
    <Stack spacing={5} py={3}>
      <Stack spacing={2}>
        <Typography variant={'h3'}>Subscription</Typography>
        <Typography variant={'h5'}>{selectedBrand?.name}</Typography>
      </Stack>
      <Stack spacing={3} alignItems={'center'} mt={10}>
        <img src={AppointmentSuccessImage} alt={'Success'} width={426} />
        <Typography variant={'h4'} width={426} textAlign={'center'}>
          You’re all set! Your Momentum subscription trial is active, effective today.
        </Typography>
        <Typography variant={'h5'} width={426} textAlign={'center'}>
          Your subscription trial period ends on{' '}
          {selectedBrand?.trialSubscriptionStartedAt && selectedBrand.subscriptionEndsAt
            ? DateTime.fromISO(selectedBrand.subscriptionEndsAt).toLocaleString(DateTime.DATE_SHORT)
            : undefined}
        </Typography>
        <Button
          variant={'contained'}
          component={RouterLink}
          to={generatePath(`${ROUTES.BRAND_PROFILE}`, { brandId: selectedBrand?.id })}
        >
          View brand profile
        </Button>
      </Stack>
    </Stack>
  )
}
