import { yupResolver } from '@hookform/resolvers/yup'
import { ImageSelect } from '@momentum/components/image-select'
import { AgencyInputData, PaymentTermsConfiguration } from '@momentum/forms/types'
import { getImageKeyFromUrl } from '@momentum/utils/imageUtils'
import { urlPreTransform } from '@momentum/utils/validationUtils'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { PaymentTermsType, UploadRequestType, InvoiceMethod } from '@productwindtom/shared-momentum-zeus-types'
import { Form, RadioInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { omit } from 'lodash'
import { useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import * as yup from 'yup'
import { BackButton } from '../../components/back-button'
import AgencyFormFields from '../agency-form/AgencyFormFields'
import { agencyFieldsSchema } from '../agency-form/validation'
import PaymentTermsFields from '@momentum/forms/inputs/PaymentTermsFields'
import { useFlag } from '@unleash/proxy-client-react'

const schema = yup
  .object({
    id: yup.string().optional(),
    name: yup.string().required('Required'),
    logo: yup
      .string()
      .notRequired()
      .transform(s => (s ? getImageKeyFromUrl(s) : s)),
    websiteUrl: yup.string().transform(urlPreTransform).url('Not a valid URL').required('Required'),
    paymentTermsConfiguration: yup.string<PaymentTermsConfiguration>().optional(),
    paymentTermsType: yup.string<PaymentTermsType>().required('Required'),
    paymentTermsCustomNetDays: yup.number().when('paymentTermsType', {
      is: PaymentTermsType.NET_CUSTOM,
      then: s => s.required('Required'),
      otherwise: s => s.nullable().optional()
    }),
    productCostPaymentTermsType: yup.string<PaymentTermsType>().optional(),
    productCostPaymentTermsCustomNetDays: yup.number().when('productCostPaymentTermsType', {
      is: PaymentTermsType.NET_CUSTOM,
      then: s => s.required('Required'),
      otherwise: s => s.nullable().optional()
    }),
    invoiceMethod: yup.string<InvoiceMethod>().required('Required'),
    agency: agencyFieldsSchema.notRequired()
  })
  .noUnknown(true)

export type CompanyFormData = {
  id?: string
  name: string
  logo?: string | null
  websiteUrl: string
  paymentTermsConfiguration?: PaymentTermsConfiguration
  paymentTermsType: PaymentTermsType
  paymentTermsCustomNetDays?: number
  productCostPaymentTermsType?: PaymentTermsType
  productCostPaymentTermsCustomNetDays?: number
  invoiceMethod: InvoiceMethod
  agency?: AgencyInputData | null
}

export const CompanyForm = ({
  defaultData,
  onSubmit,
  onCancel
}: {
  defaultData?: CompanyFormData
  onCancel: () => void
  onSubmit: (data: CompanyFormData) => Promise<void>
}) => {
  const handleSubmit = async (submitValues: CompanyFormData) => {
    await onSubmit({
      ...submitValues,
      paymentTermsCustomNetDays:
        submitValues.paymentTermsType === PaymentTermsType.NET_CUSTOM
          ? submitValues.paymentTermsCustomNetDays
          : undefined
    })
  }

  const companySplitCostsFlag = useFlag('CompanySplitCosts')

  const initialCompany: CompanyFormData = defaultData
    ? {
        paymentTermsType: defaultData.paymentTermsType ? defaultData.paymentTermsType : PaymentTermsType.DEFAULT_7_DAYS,
        productCostPaymentTermsType: defaultData.productCostPaymentTermsType
          ? defaultData.productCostPaymentTermsType
          : PaymentTermsType.DEFAULT_7_DAYS,
        ...omit(defaultData, 'paymentTermsType')
      }
    : {
        id: v4(),
        name: '',
        websiteUrl: '',
        paymentTermsConfiguration: PaymentTermsConfiguration.SINGLE,
        paymentTermsType: PaymentTermsType.DEFAULT_7_DAYS,
        productCostPaymentTermsType: PaymentTermsType.DEFAULT_7_DAYS,
        invoiceMethod: InvoiceMethod.BILL
      }

  return (
    <Form onSubmit={handleSubmit} defaultValues={initialCompany} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <TextInput name={'name'} primaryText={'Company name'} placeholder={'Company name'} />
        <TextInput name={'websiteUrl'} primaryText={'Company website'} placeholder={'Company website URL'} />

        {companySplitCostsFlag && (
          <RadioInput
            name={'paymentTermsConfiguration'}
            primaryText={'Does this company have the same payment terms for creator and product costs?'}
            options={[
              {
                label: (
                  <Typography variant={'label3'}>Yes, the company pays one invoice for the entire campaign.</Typography>
                ),
                value: PaymentTermsConfiguration.SINGLE
              },
              {
                label: (
                  <Typography variant={'label3'}>
                    No, the company pays one invoice for creator costs and a separate invoice for product costs.
                  </Typography>
                ),
                value: PaymentTermsConfiguration.SPLIT
              }
            ]}
          />
        )}

        <PaymentTermsWrapper />

        <ImageSelect
          name={'logo'}
          primaryText="Upload company logo"
          buttonText={'Click to upload'}
          companyId={initialCompany.id}
          buttonProps={{
            variant: 'outlined'
          }}
          uploadType={UploadRequestType.UPLOAD_COMPANY_LOGO}
        />
        <RadioInput
          primaryText={'What is this company’s invoice method?'}
          name={'invoiceMethod'}
          radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
          options={[
            {
              label: <Typography variant={'label3'}>Generate invoices using Bill.com</Typography>,
              value: InvoiceMethod.BILL
            },
            {
              label: <Typography variant={'label3'}>Generate invoices using a PO</Typography>,
              value: InvoiceMethod.CUSTOM_PO
            }
          ]}
        />
        <AgencyFormFields />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} />
          <SubmitButton variant={'contained'} disableOnDirty={false}>
            Save
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}

const PaymentTermsWrapper = () => {
  const hasSeparateProductCostTerms =
    useWatch({ name: 'paymentTermsConfiguration' }) === PaymentTermsConfiguration.SPLIT

  return hasSeparateProductCostTerms ? (
    <>
      <PaymentTermsFields
        paymentTermsTypeName="paymentTermsType"
        paymentTermsCustomNetDaysName="paymentTermsCustomNetDays"
        primaryText={'What are the payment terms for the creator cost?'}
      />
      <PaymentTermsFields
        paymentTermsTypeName="productCostPaymentTermsType"
        paymentTermsCustomNetDaysName="productCostPaymentTermsCustomNetDays"
        primaryText={'What are the payment terms for the product cost?'}
      />
    </>
  ) : (
    <PaymentTermsFields
      paymentTermsTypeName="paymentTermsType"
      paymentTermsCustomNetDaysName="paymentTermsCustomNetDays"
    />
  )
}
