import { mApi } from '@momentum/api'
import { ValueTypes, SubscriptionType } from '@productwindtom/shared-momentum-zeus-types'

export const updateBrand = async (input: ValueTypes['UpdateBrandInput']) => {
  return (await mApi('mutation')({ updateBrand: [{ input }, { id: true }] })).updateBrand
}

export const purchaseTrial = async (brandId: string) => {
  return (
    await mApi('mutation')({
      purchaseSubscription: [
        { input: { brandId, type: SubscriptionType.TRIAL } },
        { brandId: true, startsAt: true, endsAt: true }
      ]
    })
  ).purchaseSubscription
}
