import { Typography } from '@mui/material'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { findMostCommonValue, notEmpty } from '@productwindtom/shared-node'
import { captureException } from '@sentry/react'
import { camelCase, chunk, groupBy } from 'lodash'
import { DateTime } from 'luxon'
import Papa from 'papaparse'
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useCampaignContext } from '../../../context/CampaignContext'
import { updateProductKeywordSummary, updateProductSeoSummary, uploadProductSeoData } from './mutations'
import { getProductSeoSummary, listProductKeywordRecords, listProductKeywordSummaries } from './queries'
import { ExtendedProductKeywordSummary, ProductKeywordSummary, ProductSeoSummary } from './selectors'

export const CSV_DATE_FORMAT_1 = 'yyyy-MM-dd h:mm:ss'
export const CSV_DATE_FORMAT_2 = 'M/d/yyyy h:mm'
export const MAX_UPLOAD_RECORDS = 750

export enum SeoReportMode {
  ORGANIC = 'organic',
  SPONSORED = 'sponsored'
}

export type SeoContextType = {
  productSeoSummary?: ProductSeoSummary
  productKeywordSummaries?: ExtendedProductKeywordSummary[]
  updateProductSeoSummaryRecords: (
    records: Array<ValueTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']>
  ) => Promise<void>
  updateProductKeywordSummaries: (
    keywords: Array<Partial<ValueTypes['ModelInputUpdateProductKeywordSummary']>>
  ) => Promise<void>
  uploadCsv: (file: File) => Promise<void>
  loadAllKeywordRecords: (keyword: string) => Promise<ProductKeywordSummary | undefined>
  search?: string
  setSearch: Dispatch<SetStateAction<string>>
  isUpdating: boolean
  isLoading: boolean
  seoReportMode?: SeoReportMode
  setSeoReportMode: (mode: SeoReportMode | undefined) => void
}

export const SeoContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { campaignDetails } = useCampaignContext()
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [productSeoSummary, setProductSeoSummary] = useState<ProductSeoSummary>()
  const [allProductKeywordSummaries, setAllProductKeywordSummaries] = useState<ProductKeywordSummary[]>()
  const [productKeywordSummaries, setProductKeywordSummaries] = useState<ExtendedProductKeywordSummary[]>()
  const [seoReportMode, setSeoReportMode] = useState<SeoReportMode>()

  useEffect(() => {
    if (campaignDetails) {
      setIsLoading(true)
      Promise.all([
        getProductSeoSummary(campaignDetails.id).then(seoSummary =>
          setProductSeoSummary(
            seoSummary ?? {
              campaignId: campaignDetails.id,
              productSeoSummaryRecords: []
            }
          )
        ),
        listProductKeywordSummaries(campaignDetails.id).then(setAllProductKeywordSummaries)
      ])
        .catch(e => {
          captureException(e)
          toast(<Typography variant={'subtitle2'}>Unknown issue, please try again later</Typography>, { type: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [campaignDetails.id])

  useEffect(() => {
    const uploadedKeywords: ExtendedProductKeywordSummary[] =
      allProductKeywordSummaries
        ?.map(s => ({
          ...s,
          isClientAdded: campaignDetails.proposal?.searchTerms?.includes(s.keyword) ?? false,
          hasUploadedData: true
        }))
        .filter(s =>
          s.isClientAdded || seoReportMode === SeoReportMode.SPONSORED ? !!s.sponsoredStartRank : !!s.organicStartRank
        ) ?? []

    const manuallyAddedKeywords: ExtendedProductKeywordSummary[] =
      campaignDetails.proposal?.searchTerms
        ?.filter(k => !uploadedKeywords?.map(s => s.keyword).includes(k))
        .map(keyword => ({
          campaignId: campaignDetails.id,
          keyword,
          campaignId_keyword: `${campaignDetails.id}_${keyword}`,
          isClientAdded: true,
          hasUploadedData: false,
          records: []
        })) ?? []

    setProductKeywordSummaries([...uploadedKeywords, ...manuallyAddedKeywords])
  }, [allProductKeywordSummaries, seoReportMode])

  const updateProductSeoSummaryRecords = async (
    records: Array<ValueTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']>
  ) => {
    if (productSeoSummary) {
      const updated = await updateProductSeoSummary({
        ...productSeoSummary,
        productSeoSummaryRecords: productSeoSummary.productSeoSummaryRecords.map(record => ({
          ...record,
          ...records.find(d => d.fromDate === record.fromDate)
        }))
      })
      setProductSeoSummary(updated)
    }
  }

  const updateProductKeywordSummaries = async (
    keywords: Array<Partial<ValueTypes['ModelInputUpdateProductKeywordSummary']>>
  ) => {
    if (productKeywordSummaries) {
      const results = await Promise.all(
        keywords.map(update => {
          const summaryToUpdate = productKeywordSummaries.find(s => s.keyword === update.keyword)
          if (summaryToUpdate) {
            return updateProductKeywordSummary({
              ...update,
              campaignId: summaryToUpdate.campaignId,
              campaignId_keyword: summaryToUpdate.campaignId_keyword,
              keyword: summaryToUpdate.keyword
            })
          }
        })
      )

      setAllProductKeywordSummaries(results.filter(notEmpty))
    }
  }

  const uploadCsv = (file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsText(file)

      reader.onload = async () => {
        setIsUpdating(true)
        try {
          const csvData = Papa.parse<{
            asin: string
            keyword: string
            dateAdded: string
            keywordSales?: number | null
            searchVolume?: number | null
            organicRank?: number | null
            sponsoredPosition?: number | null
          }>(reader.result as string, {
            header: true,
            transformHeader: h => {
              if (h === 'ID') {
                // for walmart
                return 'asin'
              }
              return camelCase(h)
            },
            delimiter: ',',
            skipEmptyLines: true,
            transform: (value, col) => {
              if (
                col === 'keywordSales' ||
                col === 'searchVolume' ||
                col === 'organicRank' ||
                col === 'sponsoredPosition'
              ) {
                try {
                  const parsed = parseInt(value.replaceAll('>', ''))
                  if (isNaN(parsed)) {
                    return null
                  }
                  return parsed
                } catch (e) {
                  return null
                }
              }
              return value
            }
          })

          if (csvData.errors.length) {
            throw new Error(csvData.errors[0].message)
          }

          const firstAllowedDate = DateTime.fromISO(campaignDetails.startDate)
            .minus({ weeks: 2 })
            .startOf('week')
            .toISODate()!

          const filteredByCurrentSku = csvData.data.filter(r =>
            [campaignDetails.product.skuId, campaignDetails.product.parentSkuId].includes(r.asin)
          )
          const filtered = csvData.data
            .filter(r => r.keyword && r.dateAdded)
            .map(row => ({
              ...row,
              dateAdded:
                DateTime.fromFormat(row.dateAdded, CSV_DATE_FORMAT_1, { zone: 'utc' }).toISODate() ??
                DateTime.fromFormat(row.dateAdded, CSV_DATE_FORMAT_2, { zone: 'utc' }).toISODate()!
            }))
            .filter(row => row.dateAdded && row.dateAdded >= firstAllowedDate)

          if (!filteredByCurrentSku.length || !filtered.length) {
            toast(
              <Typography variant={'subtitle2'}>
                No keyword records found for this product ({campaignDetails.product.skuId})
              </Typography>,
              {
                type: 'error'
              }
            )
            return
          }

          // multiple records for the same keyword and dateAdded are possible, so group them and get the best values
          const grouped = groupBy(filtered, d => d.keyword + '_' + d.dateAdded)
          const records = Object.values(grouped).map(rows => {
            const row = rows[0]
            return {
              date: row.dateAdded,
              keyword: row.keyword,
              keywordSales: findMostCommonValue(rows, 'keywordSales'),
              searchVolume: findMostCommonValue(rows, 'searchVolume'),
              organicRank: findMostCommonValue(rows, 'organicRank'),
              sponsoredRank: findMostCommonValue(rows, 'sponsoredPosition')
            }
          })

          let seoSummary: ProductSeoSummary | undefined
          const chunked = chunk(records, MAX_UPLOAD_RECORDS)
          for (let index = 0; index < chunked.length; index++) {
            const records = chunked[index]
            seoSummary = await uploadProductSeoData({
              campaignId: campaignDetails.id,
              campaignStartDate: DateTime.fromISO(campaignDetails.startDate).toISODate()!,
              records,
              shouldUpdateSummary: chunked.length - 1 === index
            })
          }
          if (!seoSummary) {
            throw new Error('Failed to update summary')
          }

          setProductSeoSummary(seoSummary)
          setProductKeywordSummaries(undefined)
          listProductKeywordSummaries(campaignDetails.id).then(setAllProductKeywordSummaries)

          resolve()
        } catch (e) {
          console.error(e)
          toast(<Typography variant={'subtitle2'}>Unknown issue, please try again later</Typography>, { type: 'error' })
          setIsUpdating(false)
          reject(e)
        } finally {
          setIsUpdating(false)
        }
      }
    })
  }

  const loadAllKeywordRecords = async (keyword: string) => {
    const summary = productKeywordSummaries?.find(s => s.keyword === keyword)

    if (summary) {
      const records = await listProductKeywordRecords(summary.campaignId, keyword)

      const updated = {
        ...summary,
        records
      }
      setAllProductKeywordSummaries(productKeywordSummaries?.map(s => (s.keyword === keyword ? updated : s)))
      return updated
    }
  }

  return (
    <SeoContext.Provider
      value={{
        productSeoSummary,
        productKeywordSummaries,
        updateProductSeoSummaryRecords,
        updateProductKeywordSummaries,
        uploadCsv,
        loadAllKeywordRecords,
        search,
        setSearch,
        isUpdating,
        isLoading,
        seoReportMode,
        setSeoReportMode
      }}
    >
      {children}
    </SeoContext.Provider>
  )
}

const SeoContext = createContext<SeoContextType>({} as any)
SeoContext.displayName = 'SeoContextType'

export default SeoContext

export const useSeoContext = () => useContext(SeoContext)
