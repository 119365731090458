import { Stack } from '@mui/system'
import { useState } from 'react'
import { DebouncedTextField } from '@momentum/components/debounced-text-field'
import { FileDownloadOutlined, Search } from '@mui/icons-material'
import { useBrandContext } from '../context/BrandContext'
import { CampaignCard } from '@momentum/components/campaign-card'
import { EmptyState } from './EmptyState'
import { orderBy } from 'lodash'
import Loading from '@momentum/components/loading'
import { DateTime } from 'luxon'
import { generateCompanyRollup } from '../queries'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { Button, CircularProgress } from '@mui/material'

export const BrandCampaigns = () => {
  const { campaigns, brand, company } = useBrandContext()
  const [search, setSearch] = useState('')
  const [isCompanyRollUpLoading, setIsCompanyRollUpLoading] = useState(false)

  const lowerSearch = search.toLowerCase()
  const filteredCampaigns = orderBy(
    lowerSearch
      ? (campaigns || []).filter(
          c => c.product?.name.toLowerCase().includes(lowerSearch) || c.title.toLowerCase().includes(lowerSearch)
        )
      : campaigns,
    c => c.startDate,
    'desc'
  )
  const isEmpty = filteredCampaigns.length === 0

  if (!campaigns) {
    return <Loading />
  }

  const handleCompanyRollup = async () => {
    try {
      setIsCompanyRollUpLoading(true)
      const fileName = `${company.name} & ProductWind Company Summary - ${DateTime.now().toLocaleString(DateTime.DATE_SHORT)}.csv`
      const data = await generateCompanyRollup(company.id)
      const file = new Blob([data], { type: 'text/csv' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(file)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      successToast('Company campaign summary downloaded successfully!')
    } catch (err) {
      console.log('err', err)
      errorToast('Something went wrong, please try again or contact your client success manager.')
    } finally {
      setIsCompanyRollUpLoading(false)
    }
  }

  return !isEmpty ? (
    <Stack spacing={3} mt={2} pb={2}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
        <DebouncedTextField
          placeholder={'Search campaigns'}
          sx={{ mb: 4, width: 330 }}
          onChange={setSearch}
          InputProps={{
            endAdornment: <Search />
          }}
        />
        <Button
          disabled={isCompanyRollUpLoading}
          variant="text"
          disableRipple
          startIcon={isCompanyRollUpLoading ? <CircularProgress size={12} color="inherit" /> : <FileDownloadOutlined />}
          onClick={handleCompanyRollup}
        >
          Download company campaign summary
        </Button>
      </Stack>

      <Stack spacing={2}>
        {filteredCampaigns.map(c => (
          <CampaignCard key={c.id} campaign={c} brandId={brand.id} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <EmptyState brandId={brand.id} />
  )
}
