import { yupResolver } from '@hookform/resolvers/yup'
import { ImageSelect } from '@momentum/components/image-select'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CsvInput } from '@momentum/routes/campaign/e-commerce/performance/components/CsvInput'
import { mergeArrays } from '@momentum/routes/campaign/e-commerce/performance/mergeUtils'
import { useCampaignOverviewPerformanceContext } from '@momentum/routes/campaign/e-commerce/performance/overviewPerformanceContext'
import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { CheckCircleOutline, Close } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { PerformanceComparisonMetric, UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import { CheckInput, Form, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import * as yup from 'yup'
import { FormType } from '../..'
import { parseBSRFile, parseReviewFile } from './input-file-parser'

const schema = yup.object().shape({
  productName: yup.string().required('Required'),
  productUrl: yup.string().url('Invalid URL').required('Required'),
  productImage: yup.string().optional(),
  isCategoryAverage: yup.boolean().optional()
})

type AddProductFormType = {
  productName: string
  productUrl: string
  productImage?: string
  bestSellerRankFile?: File
  reviewsFile?: File
  reviewDataUploaded?: boolean
  bestSellerRankDataUploaded?: boolean
  isCategoryAverage?: boolean
}

export const AddProductForm = ({
  product,
  productIndex,
  onClose
}: {
  product?: CampaignPerformanceProduct
  productIndex?: number
  onClose: () => void
}) => {
  const { createCampaignPerformanceProduct, updateCampaignPerformanceProduct } = useCampaignOverviewPerformanceContext()
  const { getValues, control } = useFormContext<FormType>()
  const { append, update } = useFieldArray({ control, name: 'products' })
  const values = getValues()
  const defaultValues = {
    productUrl: product?.productUrl || '',
    productName: product?.productName || '',
    isCategoryAverage: product?.isCategoryAverage || false,
    productImage: product?.productImage || undefined,
    reviewDataUploaded: product?.reviewDataUploaded,
    bestSellerRankDataUploaded: product?.bestSellerRankDataUploaded
  }

  const onSubmitForm = async (data: AddProductFormType) => {
    const values = getValues()

    const reviewRows = data.reviewsFile
      ? await parseReviewFile(data.reviewsFile)
      : product?.productPerformanceRecords.map(r => ({
          date: r.date,
          rating: r.rating,
          reviewCount: r.reviewCount
        })) || []

    const bestSellerRankRows = data.bestSellerRankFile
      ? await parseBSRFile(data.bestSellerRankFile)
      : product?.productPerformanceRecords.map(r => ({
          date: r.date,
          salesRank: r.salesRank
        })) || []
    const mergedRows = mergeArrays(reviewRows, bestSellerRankRows, 'date')

    const input = {
      id: product?.id || v4(),
      ...product,
      isVisible: product?.isVisible || true,
      isCategoryAverage: !!data.isCategoryAverage,
      productUrl: data.productUrl,
      productName: data.productName,
      productImage: data.productImage,
      reviewDataUploaded: product?.reviewDataUploaded || !!data.reviewsFile,
      bestSellerRankDataUploaded: product?.bestSellerRankDataUploaded || !!data.bestSellerRankFile,
      productPerformanceRecords: mergedRows,
      campaignId: values.campaignId,
      skuId: values.skuId,
      comparisonMetric: values.comparisonMetric
    }

    if (product && productIndex == null) {
      throw new Error('Product index is required when updating')
    }

    if (product && productIndex != null) {
      const updatedProduct = await updateCampaignPerformanceProduct(input)
      update(productIndex, {
        ...product,
        ...updatedProduct
      })
    } else {
      const createdProduct = await createCampaignPerformanceProduct(input)
      append(createdProduct)
    }
    onClose()
  }

  return (
    <Form defaultValues={defaultValues} resolver={yupResolver(schema)} onSubmit={onSubmitForm}>
      <FormBody comparisonMetric={values.comparisonMetric!} />
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton variant={'contained'}>Submit</SubmitButton>
      </Stack>
    </Form>
  )
}

const FormBody = ({ comparisonMetric }: { comparisonMetric: PerformanceComparisonMetric }) => {
  const { campaignDetails } = useCampaignContext()

  const { setValue } = useFormContext()
  const reviewDataUploaded = useWatch({ name: 'reviewDataUploaded' })
  const bestSellerRankDataUploaded = useWatch({ name: 'bestSellerRankDataUploaded' })

  return (
    <Stack spacing={3}>
      <Typography variant={'label3'}>Add a similar product to compare it against the promoted product.</Typography>
      <TextInput name={'productName'} primaryText={'Product name'} />
      <TextInput name={'productUrl'} primaryText={'Product URL'} />
      <CheckInput
        name={'isCategoryAverage'}
        label={<Typography variant={'label3'}>Category average</Typography>}
        checkboxProps={{ size: 'medium' }}
      />

      {comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK && (
        <Typography variant={'h4'}>Upload BSR data CSV</Typography>
      )}
      {comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK && (
        <Stack spacing={2}>
          <Typography variant={'label3'}>
            Upload BSR data from Helium to Momentum. This will override any existing BSR data for this product.
          </Typography>

          {bestSellerRankDataUploaded && (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <CheckCircleOutline fontSize={'mSmall'} color={'success'} />
              <Typography variant={'label4'} color={theme => theme.palette.grey.A700}>
                BSR data
              </Typography>
              <IconButton size={'small'} onClick={() => setValue('bestSellerRankDataUploaded', false)}>
                <Close fontSize={'mSmall'} />
              </IconButton>
            </Stack>
          )}
          {!bestSellerRankDataUploaded && <CsvInput name={'bestSellerRankFile'} />}
        </Stack>
      )}

      {comparisonMetric === PerformanceComparisonMetric.REVIEWS && (
        <Typography variant={'h4'}>Upload reviews data CSV</Typography>
      )}
      {comparisonMetric === PerformanceComparisonMetric.REVIEWS && (
        <Stack spacing={2}>
          <Typography variant={'label3'}>
            Upload reviews data from Helium to Momentum. This will override any existing reviews data for this product.
          </Typography>

          {reviewDataUploaded && (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <CheckCircleOutline fontSize={'mSmall'} color={'success'} />
              <Typography variant={'label4'} color={theme => theme.palette.grey.A700}>
                Reviews data
              </Typography>
              <IconButton size={'small'} onClick={() => setValue('reviewDataUploaded', false)}>
                <Close fontSize={'mSmall'} />
              </IconButton>
            </Stack>
          )}
          {!reviewDataUploaded && <CsvInput name={'reviewsFile'} />}
        </Stack>
      )}
      <Typography variant={'h4'}>Upload product image</Typography>
      <ImageSelect
        uploadType={UploadRequestType.UPLOAD_CAMPAIGN_PRODUCT_IMAGE}
        campaignId={campaignDetails.id}
        name={'productImage'}
        buttonText={'Attach image'}
        replacementText={'Replace image'}
        buttonProps={{ variant: 'outlined' }}
        showDeleteButton={true}
      />
    </Stack>
  )
}
