import { Font, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import { DateTime } from 'luxon'

const styles = StyleSheet.create({
  page: {
    padding: 45
  },
  title1: {
    fontFamily: 'Avenir',
    fontSize: '14px'
  },
  body: {
    fontFamily: 'Avenir',
    fontSize: '11px'
  },
  body2: {
    fontFamily: 'Avenir',
    fontSize: '11px',
    fontWeight: 'heavy'
  },

  section: {
    textAlign: 'center',
    marginBottom: 15,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center'
  },
  column: { flexDirection: 'column' },
  row: { width: '100%', display: 'flex', flexDirection: 'row' },

  cellHeader: {
    textAlign: 'center',
    alignContent: 'center',
    padding: 5
  },
  firstCell: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingVertical: 10,
    paddingRight: 5,
    width: '25%',
    borderTop: '1px solid black',
    borderRight: '1px solid black'
  },
  cell: {
    textAlign: 'left',
    paddingLeft: 5,
    width: '75%',
    paddingVertical: 10,
    paddingRight: 10,
    borderTop: '1px solid black'
  }
})

export const TrialSubscriptionAgreementPdf = ({
  startDate,
  endDate,
  brandName,
  companyName,
  creationDate,
  paymentTerms,
  primaryContactEmail,
  primaryContactName,
  billingContactEmail,
  billingContactName
}: {
  startDate: string
  endDate: string
  brandName: string
  companyName: string
  creationDate: string
  paymentTerms: string
  primaryContactName: string
  primaryContactEmail: string
  billingContactName?: string
  billingContactEmail?: string
}) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: '/fonts/Avenir/AvenirRegular.ttf'
      },
      {
        src: '/fonts/Avenir/AvenirHeavy.ttf',
        fontWeight: 'heavy'
      }
    ]
  })

  return (
    <Document
      pageLayout="oneColumn"
      pageMode="useNone"
      title={`ProductWind Trial Subscription Agreement`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <Page style={styles.page}>
        <View style={[styles.section]}>
          <Text style={[styles.title1]}>Trial Subscription Agreement</Text>
        </View>
        <View style={[styles.section]}>
          <Text style={[styles.body]}>
            This Trial Subscription Agreement (the “
            <Text style={[styles.body2, { fontWeight: 'heavy' }]}>Trial Subscription Agreement</Text>”) is entered into
            by and between ProductWind, Inc., a Delaware corporation (“
            <Text style={styles.body2}>ProductWind</Text>”) and {companyName} (“
            <Text style={styles.body2}>Client</Text>”), effective as of {creationDate} (the “
            <Text style={styles.body2}>Effective Date</Text>
            ”).
          </Text>
        </View>
        <View style={[{ flexDirection: 'column', border: '1px solid black' }]}>
          <View style={[styles.cellHeader]}>
            <Text style={[styles.body2, { textDecoration: 'underline' }]}>TRIAL SUBSCRIPTION</Text>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Subscription</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                The Client will receive access to ProductWind’s Momentum platform (the “Momentum Platform”) until the
                Subscription End Date.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Trial Subscription Term: </Text>
            </View>
            <View style={[styles.cell, { width: '37.5%', borderRight: '1px solid black' }]}>
              <Text style={[styles.body2]}>Subscription Start Date:</Text>
              <Text style={[styles.body]}>{startDate}</Text>
            </View>
            <View style={[styles.cell, { width: '37.5%' }]}>
              <Text style={[styles.body2]}>Subscription End Date:</Text>
              <Text style={[styles.body]}>{endDate}</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Trial Subscription Fee:</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                $0.00. The Subscription Fee has been waived for the Trial Subscription Term. As of today, the
                ProductWind annual Subscription Fee is $30,000 per product group or $100,000 for company-level
                subscription. This Subscription Fee is subject to change.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Subscription Scope:</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                This Trial Subscription Agreement gives the {brandName} brand access to the Momentum Platform.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Platform Payment Terms:</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{paymentTerms}</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Marketing:</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                In exchange for this subscription, the Client agrees to grant ProductWind permission to publicly display
                their logo, issue a press release on our partnership and a case study showcasing the results.
                ProductWind is responsible for creating the content and Client will have final editorial rights on these
                items.
              </Text>
            </View>
          </View>
        </View>
      </Page>

      <Page style={styles.page}>
        <View style={[{ flexDirection: 'column', border: '1px solid black' }]}>
          <View style={[styles.cellHeader]}>
            <Text style={[styles.body2, { textDecoration: 'underline' }]}>CLIENT DETAILS</Text>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Company</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{brandName}</Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Primary Contact and Email</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                {primaryContactName} {primaryContactEmail}
              </Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Billing Contact</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{billingContactName}</Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Billing Email</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{billingContactEmail}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
