import { useTheme } from '@mui/material'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { keyBy, mapValues, sortBy, uniq } from 'lodash'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormType } from '.'
import { CampaignPerformanceProduct } from './queries'

const PREDEFINED_REVIEW_MILESTONES = [5, 10, 20, 50, 75, 100]

export const useMilestones = () => {
  const { watch } = useFormContext<FormType>()

  const comparisonMetric = watch('comparisonMetric')
  const comparisonMetricValue = watch('comparisonMetricValue')

  const milestones = useMemo(
    () =>
      comparisonMetric === PerformanceComparisonMetric.REVIEWS
        ? sortBy(uniq([...PREDEFINED_REVIEW_MILESTONES, comparisonMetricValue].filter(notEmpty)))
        : [],
    [comparisonMetric, comparisonMetricValue]
  )

  return milestones
}

export const useProductColors = () => {
  const theme = useTheme()
  const { watch } = useFormContext<FormType>()

  const promotedProductId = watch('promotedProductId')
  const products = watch('products') as CampaignPerformanceProduct[]
  const comparisonMetric = watch('comparisonMetric') as PerformanceComparisonMetric

  const productsForMetric = products.filter(
    p => p.id === promotedProductId || !p.comparisonMetric || p.comparisonMetric === comparisonMetric
  )

  const productColors = [
    theme.palette.info.main,
    theme.palette.warning.light,
    theme.palette.error.light,
    theme.palette.success.light
  ]

  return mapValues(
    keyBy(
      productsForMetric.map((p, idx) => ({
        id: p.id,
        color: productColors[idx % productColors.length]
      })),
      p => p.id
    ),
    p => p.color
  )
}
