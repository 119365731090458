import { Stack, Typography } from '@mui/material'
import { PaymentTermsType } from '@productwindtom/shared-momentum-zeus-types'
import { RadioInput, NumberInput } from '@productwindtom/ui-base'
import { useWatch } from 'react-hook-form'

const PaymentTermsFields = ({
  paymentTermsTypeName,
  paymentTermsCustomNetDaysName,
  primaryText = 'What are the default payment terms for the company?',
  subtext
}: {
  paymentTermsTypeName: string
  paymentTermsCustomNetDaysName: string
  primaryText?: string
  subtext?: string
}) => {
  const paymentTermsType = useWatch({ name: paymentTermsTypeName })

  return (
    <Stack>
      <RadioInput
        primaryText={primaryText}
        subtext={subtext}
        name={paymentTermsTypeName}
        radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
        options={[
          {
            label: (
              <Typography variant={'label3'}>
                Payment is due 7 days before campaign start date. The campaign cannot start until payment is received.
              </Typography>
            ),
            value: PaymentTermsType.DEFAULT_7_DAYS
          },
          {
            label: <Typography variant={'label3'}>This brand has negotiated payment terms.</Typography>,
            value: PaymentTermsType.NET_CUSTOM
          }
        ]}
      />
      {paymentTermsType === PaymentTermsType.NET_CUSTOM && (
        <Stack direction={'row'} ml={4} spacing={2}>
          <Typography variant={'label3'} pt={1}>
            Net
          </Typography>
          <NumberInput name={paymentTermsCustomNetDaysName} returnAsNumber hideZero />
        </Stack>
      )}
    </Stack>
  )
}

export default PaymentTermsFields
