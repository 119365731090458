import { Selector, InputType, GraphQLTypes } from '@productwindtom/shared-momentum-zeus-types'
import { EnvType, getProcessEnv, isEnv, notEmpty } from '@productwindtom/shared-node'
import { uniq } from 'lodash'

const proposalSelector = Selector('Proposal')({
  paymentBillingContact: {
    email: true
  },
  billingContacts: {
    email: true
  }
})

type GetProposalEmailContactsInput = InputType<GraphQLTypes['Proposal'], typeof proposalSelector>

export const getProposalEmailContacts = (proposal: GetProposalEmailContactsInput) => {
  return (
    !isEnv([EnvType.production, EnvType.master])
      ? [getProcessEnv('MOMENTUM_EMAIL_ADDRESS')]
      : uniq(
          [proposal.paymentBillingContact?.email, ...(proposal.billingContacts || []).map(contact => contact.email)]
            .map(c => c?.trim())
            .filter(c => c)
            .filter(notEmpty)
        )
  ).filter(e => !isEnv(EnvType.master) || e !== 'billing@productwind.com')
}
