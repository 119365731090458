import { Stack, Typography } from '@mui/material'
import React from 'react'
import { SummaryTitleSelector } from '@momentum/routes/campaign/e-commerce/performance/components/SummaryTitleSelector'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useWatch } from 'react-hook-form'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { CheckInput, SwitchInput } from '@productwindtom/ui-base'

export const Summary = ({ hasData }: { hasData: boolean }) => {
  const { isAdminView } = useUserSessionContext()
  const summaryTitle = useWatch({ name: 'summaryTitle' })
  const comparisonMetric = useWatch({ name: 'comparisonMetric' })

  return (
    <Stack spacing={4}>
      {comparisonMetric === PerformanceComparisonMetric.REVIEWS && (
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography variant={'h4'}>Time to review</Typography>
            {isAdminView && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={'caption2'} color={'grey.A700'}>
                  Visible to admins only
                </Typography>
                <SwitchInput size={'small'} name="isTtrVisible" controlLabelProps={{ label: 'Visible to client' }} />
              </Stack>
            )}
          </Stack>
          {isAdminView && (
            <CheckInput
              name={'isTtrDataUnavailable'}
              label={'Time to review data unavailable'}
              checkboxProps={{ size: 'medium' }}
              controlLabelProps={{
                slotProps: {
                  typography: { variant: 'caption2' }
                }
              }}
            />
          )}
        </Stack>
      )}
      {comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK && (
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant={'h4'}>Best seller rank</Typography>
            {isAdminView && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={'caption2'} color={'grey.A700'}>
                  Visible to admins only
                </Typography>{' '}
                <SwitchInput name="isBsrVisible" disabled controlLabelProps={{ label: 'Visible to client' }} />
              </Stack>
            )}
          </Stack>
          {isAdminView && (
            <CheckInput
              name={'isBsrDataUnavailable'}
              label={'Time to review data unavailable'}
              checkboxProps={{ size: 'medium' }}
              controlLabelProps={{
                slotProps: {
                  typography: { variant: 'caption2' }
                }
              }}
            />
          )}
        </Stack>
      )}
      {hasData && (isAdminView || !!summaryTitle) && <SummaryTitleSelector name={'summaryTitle'} />}
    </Stack>
  )
}
