import Metric from '@momentum/components/metric'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { getCampaignCreatorCounts } from '@productwindtom/shared-momentum'
import CampaignSummaryPDF from './CampaignSummaryPDF'

export const Summary = () => {
  const { campaignDetails } = useCampaignContext()
  const {
    brandAdvocatesJoined,
    brandAdvocatesTotal,
    numSocialCreators,
    socialJoined,
    numUgcCreators,
    ugcJoined,
    numPremiumUgcCreators,
    premiumUgcJoined
  } = getCampaignCreatorCounts(campaignDetails)

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant={'h4'}>Participating creators</Typography>
          <Typography variant={'caption2'} color={theme => theme.palette.grey.A700}>
            {DateTime.fromISO(campaignDetails.startDate).toLocaleString(DateTime.DATE_MED)} -{' '}
            {campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate).toLocaleString(DateTime.DATE_MED) : ''}
          </Typography>
        </Stack>
        <CampaignSummaryPDF />
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'Brand advocates'} value={getMetricOutOfString(brandAdvocatesJoined, brandAdvocatesTotal)} />
        <Metric label={'UGC creators'} value={getMetricOutOfString(ugcJoined, numUgcCreators)} />
        <Metric label={'Premium UGC creators'} value={getMetricOutOfString(premiumUgcJoined, numPremiumUgcCreators)} />
        <Metric label={`Social creators`} value={getMetricOutOfString(socialJoined, numSocialCreators)} />
      </Stack>
    </Stack>
  )
}

const getMetricOutOfString = (joined: number, total?: number) => {
  if (!total) {
    return '--'
  }
  return `${joined} out of ${total}`
}
