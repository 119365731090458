import { Box, Stack, Theme } from '@mui/material'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Circle } from '@mui/icons-material'
import React from 'react'

type BoxToggleProps = {
  value: boolean
  color: string
  title?: React.ReactNode
  maxWidth?: string
  readonly?: boolean
  padding?: number
  sx?: SxProps<Theme>
  onChange: (value: boolean) => void
  children: React.ReactNode
}

export const BoxToggleV2 = ({
  value,
  readonly,
  children,
  color,
  title,
  onChange,
  padding = 2,
  maxWidth,
  sx
}: BoxToggleProps) => {
  return (
    <Box
      flex={1}
      position={'relative'}
      borderRadius={'4px'}
      borderColor={theme => (value ? theme.palette.primary.main : theme.palette.grey.A200)}
      onClick={() => !readonly && onChange(!value)}
      sx={{
        maxWidth: maxWidth,
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: value ? 2 : 1,
        ...sx
      }}
    >
      <Stack p={padding} alignItems={'center'}>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Circle sx={{ color, fontSize: '14px' }} />
          {title}
        </Stack>
        {children}
      </Stack>
    </Box>
  )
}
