import { Link, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import NoSubscriptionImage from '@momentum/routes/subscription/NoSubscription.png'

export const SubscriptionEnded = ({ endDate }: { endDate: DateTime }) => {
  return (
    <Stack alignItems={'center'} spacing={4} py={8}>
      <img src={NoSubscriptionImage} alt={'You are not subscribed'} width={285} />
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Typography variant={'h5'}>Your subscription ended on {endDate.toLocaleString(DateTime.DATE_SHORT)}</Typography>
      </Stack>
      <Stack alignItems={'center'}>
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Contact Momentum to resubscribe
        </Typography>
        <Link href={'mailto:sales@productwind.com'} variant={'label3'} target={'_blank'}>
          sales@productwind.com
        </Link>
      </Stack>
    </Stack>
  )
}
