import { Link, Stack, Typography, Button, DialogTitle, Dialog, DialogContent } from '@mui/material'
import NoSubscriptionImage from './NoSubscription.png'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import React, { useState } from 'react'
import { useDisclose } from '@momentum/hooks/useDisclose'
import CloseButton from '@momentum/components/close-button'
import { CalendarTodayOutlined } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { getPaymentTerms, TRIAL_SUBSCRIPTION_DURATION_MONTHS } from '@productwindtom/shared-momentum'
import { PaymentTermsType } from '@productwindtom/shared-momentum-zeus-types'
import { LoadingButton } from '@mui/lab'
import { useFlag } from '@unleash/proxy-client-react'
import { pdf } from '@react-pdf/renderer'
import { TrialSubscriptionAgreementPdf } from '@momentum/routes/subscription/TrialSubscriptionAgreementPdf'
import { errorToast } from '@momentum/utils/toastUtils'
import { purchaseTrial } from '@momentum/routes/subscription/queries'
import { captureException } from '@sentry/react'
import { useNavigate, generatePath } from 'react-router-dom'
import { ROUTES } from '@momentum/routes/RouteNames'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'

export const NotSubscribed = () => {
  const { profile, isAdmin, selectedBrand, selectedCompany, refreshBrand } = useUserSessionContext()
  const [startingTrial, setStartingTrial] = useState(false)
  const startTrialSubscriptionFlag = useFlag('StartTrialSubscription')
  const navigate = useNavigate()
  const { onClose, onOpen, isOpen } = useDisclose()

  const onStartTrial = async () => {
    if (!selectedBrand) {
      return
    }
    setStartingTrial(true)

    try {
      const resp = await purchaseTrial(selectedBrand.id)
      await refreshBrand(resp.brandId)
      navigate(generatePath(ROUTES.BRAND_SUBSCRIPTION_TRIAL_SUCCESS, { brandId: resp.brandId }))
      onClose()
      setStartingTrial(false)
    } catch (error) {
      captureException(error)
      console.error(error)
      errorToast('Unable to start trial subscription')
      setStartingTrial(false)
    }
  }

  const trialStartDate = DateTime.now()
  const trialEndDate = trialStartDate.plus({ months: TRIAL_SUBSCRIPTION_DURATION_MONTHS })

  const paymentTerms = selectedCompany
    ? getPaymentTerms({
        paymentTermsType: selectedCompany.paymentTermsType || PaymentTermsType.DEFAULT_7_DAYS,
        paymentTermsNetDays: selectedCompany.paymentTermsCustomNetDays,
        productCostPaymentTermsNetDays: selectedCompany.paymentTermsCustomNetDays,
        productCostPaymentTermsType: selectedCompany.paymentTermsType
      })
    : ''

  const generatePdf = async () => {
    const pdfDocument = (
      <TrialSubscriptionAgreementPdf
        brandName={selectedBrand!.name}
        companyName={selectedCompany!.name}
        creationDate={DateTime.now().toLocaleString(DateTime.DATE_MED)}
        startDate={trialStartDate.toLocaleString(DateTime.DATE_MED)}
        endDate={trialEndDate.toLocaleString(DateTime.DATE_MED)}
        paymentTerms={paymentTerms}
        primaryContactName={`${profile.firstName} ${profile.lastName}`}
        primaryContactEmail={profile.email}
        billingContactName={selectedBrand?.paymentBillingContact?.name}
        billingContactEmail={selectedBrand?.paymentBillingContact?.email}
      />
    )

    await pdf(pdfDocument)
      .toBlob()
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `trial-subscription-agreement.pdf`
        a.click()
      })
  }

  return (
    <Stack alignItems={'center'} spacing={4} py={8}>
      <img src={NoSubscriptionImage} alt={'You are not subscribed'} width={203} />
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Typography variant={'h5'}>You are not subscribed.</Typography>
      </Stack>
      {startTrialSubscriptionFlag && !isAdmin && !selectedBrand?.trialSubscriptionStartedAt && (
        <Button variant={'contained'} size={'large'} onClick={onOpen}>
          Start Momentum Trial
        </Button>
      )}
      <Stack alignItems={'center'}>
        <Typography variant={'label3'}>Contact Momentum to subscribe</Typography>
        <Link href={'mailto:sales@productwind.com'} variant={'label3'} target={'_blank'}>
          sales@productwind.com
        </Link>
      </Stack>
      <Dialog open={isOpen}>
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <CloseButton iconButtonProps={{ onClick: onClose }} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems={'center'} spacing={5}>
            <Stack spacing={0.5} alignItems={'center'}>
              <Typography variant={'h4'} align={'center'}>
                Start Momentum subscription trial for {selectedBrand?.name}!
              </Typography>
              <Typography variant={'subtitle2'} align={'center'} color={t => t.palette.grey.A700}>
                Start your free {TRIAL_SUBSCRIPTION_DURATION_MONTHS}-month Momentum subscription trial. This trial gives
                your business group access to the ProductWind Momentum platform.
              </Typography>
            </Stack>

            <Stack spacing={1} alignItems={'center'}>
              <Typography variant={'h4'}>Trial subscription fee: $0.00</Typography>
              <Typography variant={'subtitle2'} align={'center'}>
                The subscription fee has been waived for the the trial subscription term.
              </Typography>
              <Typography variant={'body2'} align={'center'}>
                As of today, the ProductWind annual subscription fee is $30,000.00 per product group or $100,000.00 for
                company-level subscription.
              </Typography>
            </Stack>

            <Stack spacing={1} alignItems={'center'} width={'100%'}>
              <Typography variant={'h4'}>Subscription trial dates:</Typography>
              <Stack direction={'row'} justifyContent={'space-evenly'} width={'100%'}>
                <Stack spacing={1}>
                  <Typography variant={'label3'}>Start date:</Typography>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <CalendarTodayOutlined color={'primary'} />
                    <Typography variant={'label3'}>{trialStartDate.toLocaleString()}</Typography>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant={'label3'}>End date:</Typography>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <CalendarTodayOutlined color={'primary'} />
                    <Typography variant={'label3'}>{trialEndDate.toLocaleString()}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack spacing={1} alignItems={'center'}>
              <Typography variant={'h4'}>{selectedBrand?.name} payment terms:</Typography>
              <Typography variant={'subtitle2'} align={'center'}>
                {paymentTerms}
              </Typography>
            </Stack>

            <Stack spacing={1} alignItems={'center'}>
              <LoadingButton variant={'contained'} loading={startingTrial} onClick={onStartTrial}>
                Start Momentum trial
              </LoadingButton>
              <Button variant={'text'} disabled={startingTrial} onClick={onClose}>
                Cancel
              </Button>
              <Typography variant={'body2'}>
                By clicking “Start Momentum trial" you agree to the{' '}
                <Typography
                  variant={'body2'}
                  onClick={generatePdf}
                  component={'span'}
                  color={'primary'}
                  sx={{ cursor: 'pointer', fontWeight: 800 }}
                >
                  Momentum Trial Subscription Agreement
                </Typography>{' '}
                and the{' '}
                <Link
                  variant={'formSubText'}
                  sx={{ fontWeight: 800 }}
                  href={getCdnImageUrl('documents/Momentum+Subscription+Terms+and+Conditions.pdf')}
                  target={'_blank'}
                >
                  Momentum Subscription Terms and Conditions
                </Link>
                .
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}
