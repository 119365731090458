import AmazonChoiceIcon from '@momentum/components/icons/amazon-choice'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { SwitchInput } from '@productwindtom/ui-base'
import { maxBy } from 'lodash'
import { DateTime } from 'luxon'
import { SeoReportMode, useSeoContext } from '../../context'
import { ProductKeywordRecord } from '../../context/selectors'
import { KeywordWinCell } from './KeywordWinsCell'
import { TrendMiniGraph } from './TrendMiniGraph'

export type KeywordWin = {
  type: KeywordWinType
  date?: string
}

// Keyword win types with sorted by priority
export enum KeywordWinType {
  PAGE_ONE_WIN = 4,
  PAGE_ONE_WIN_MAINTAINED = 3,
  RANK_SURGE = 2,
  NEWLY_RANKED = 1 // Not used yet
}

export type KeywordRowType = {
  id: string
  keyword: string
  trend: ProductKeywordRecord[]
  searchVolume: number
  organicKeywordWins?: KeywordWin[]
  sponsoredKeywordWins?: KeywordWin[]
  organicStartRank?: number
  organicHighestRank?: number
  sponsoredStartRank?: number
  sponsoredHighestRank?: number
  organicSlotsImproved: number
  sponsoredSlotsImproved: number
  hasAmazonChoiceBadge: boolean
  isEditMode: boolean
  idx: number
  isClientAdded: boolean
  hasUploadedData: boolean
}

export const useColumnDefinitions = (): GridColDef<KeywordRowType>[] => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails } = useCampaignContext()
  const { seoReportMode } = useSeoContext()

  if (!seoReportMode) {
    return []
  }

  const showColumnData = DateTime.now() >= DateTime.fromISO(campaignDetails.startDate).startOf('day') || isAdminView

  const cols: Array<GridColDef<KeywordRowType> | undefined> = [
    {
      headerName: 'Search term',
      field: 'keyword',
      flex: seoReportMode === SeoReportMode.ORGANIC ? 3 : 2,
      display: 'flex',
      align: 'left',
      renderCell: ({ value, row }) => {
        return (
          <Stack spacing={1}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant="label4">{value}</Typography>
              {!row.isEditMode && row.hasAmazonChoiceBadge && showColumnData && <AmazonChoiceIcon />}
              {row.isClientAdded && isAdminView && (
                <Chip label={'Added by client'} color="primary" size="small" variant="outlined" />
              )}
            </Stack>
            {row.isEditMode && row.hasUploadedData && (
              <SwitchInput
                name={`keywords[${row.idx}].hasAmazonChoiceBadge`}
                controlLabelProps={{
                  label: 'Amazon choice badge'
                }}
                size="small"
                sx={{
                  marginLeft: -1
                }}
              />
            )}
          </Stack>
        )
      }
    },
    seoReportMode === SeoReportMode.ORGANIC && isAdminView
      ? {
          headerName: 'Trend',
          field: 'trend',
          flex: 1,
          display: 'flex',
          align: 'left',
          sortable: false,
          renderHeader: () =>
            showColumnData && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant="inherit">Trend</Typography>
                <Chip label={'Admin'} color="secondary" size="small" />
              </Stack>
            ),
          renderCell: ({ row }) => {
            return <TrendMiniGraph row={row} />
          }
        }
      : undefined,
    campaignDetails.product.store !== Store.walmart
      ? {
          headerName: 'Monthly search volume',
          field: 'searchVolume',
          flex: 1.5,
          display: 'flex',
          align: 'center',
          renderCell: ({ value }) => !!value && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
        }
      : undefined,
    {
      headerName: 'Keyword wins',
      field: seoReportMode === SeoReportMode.ORGANIC ? 'organicKeywordWins' : 'sponsoredKeywordWins',
      flex: 2,
      display: 'flex',
      align: 'left',
      renderCell: ({ value, row }) => showColumnData && <KeywordWinCell key={row.keyword} keywordWins={value} />,
      sortComparator: (v1: KeywordWin[] | undefined, v2: KeywordWin[] | undefined) => {
        const v1Type = maxBy(v1, kw => kw.type)?.type
        const v2Type = maxBy(v2, kw => kw.type)?.type
        return (v2Type ?? 0) - (v1Type ?? 0)
      }
    },
    {
      headerName: 'Starting rank',
      field: seoReportMode === SeoReportMode.ORGANIC ? 'organicStartRank' : 'sponsoredStartRank',
      flex: 1,
      display: 'flex',
      align: 'center',
      renderCell: ({ value }) =>
        !!value && showColumnData && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
    },
    {
      headerName: 'Highest rank',
      field: seoReportMode === SeoReportMode.ORGANIC ? 'organicHighestRank' : 'sponsoredHighestRank',
      flex: 1,
      display: 'flex',
      align: 'center',
      renderCell: ({ value }) =>
        !!value && showColumnData && <Typography variant="caption2">{value?.toLocaleString()}</Typography>
    },
    {
      headerName: 'Slots improved',
      field: seoReportMode === SeoReportMode.ORGANIC ? 'organicSlotsImproved' : 'sponsoredSlotsImproved',
      flex: 1,
      display: 'flex',
      align: 'center',
      renderCell: ({ value }) =>
        !!value &&
        showColumnData && (
          <Typography variant="caption1" color={theme => (value ? theme.palette.success.main : undefined)}>
            {value ? `+${value.toLocaleString()}` : 0}
          </Typography>
        )
    }
  ]
  return cols.filter(notEmpty)
}
