import RestartCheckIcon from '@momentum/components/icons/restart-check'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DateTime } from 'luxon'
import { KeywordWin, KeywordWinType } from './utils'

export const KeywordWinCell = ({ keywordWins }: { keywordWins?: KeywordWin[] }) => {
  if (!keywordWins?.length) {
    return null
  }

  const iconStyle = {
    fontSize: 13,
    height: 13,
    width: 13
  }

  return (
    <Stack my={2}>
      {keywordWins.map((keywordWin, idx) => (
        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
          {keywordWin.type === KeywordWinType.PAGE_ONE_WIN ? (
            <>
              <CheckCircleOutlineOutlinedIcon color="success" sx={iconStyle} />
              <Typography variant="caption2">Page 1 win!</Typography>
            </>
          ) : keywordWin.type === KeywordWinType.PAGE_ONE_WIN_MAINTAINED ? (
            <>
              <RestartCheckIcon sx={iconStyle} />
              <Typography variant="caption2">Page 1 win maintained</Typography>
            </>
          ) : keywordWin.type === KeywordWinType.RANK_SURGE ? (
            <>
              <TrendingUpIcon color="primary" sx={iconStyle} />
              <Typography variant="caption2">Moved up 100+ slots!</Typography>
            </>
          ) : keywordWin.type === KeywordWinType.NEWLY_RANKED ? (
            <>
              <NewReleasesIcon color="primary" sx={iconStyle} />
              <Typography variant="caption2">Product got ranked!</Typography>
            </>
          ) : null}
          {keywordWin.date && (
            <Typography variant="caption2">
              ({DateTime.fromISO(keywordWin.date).toLocaleString(DateTime.DATE_SHORT)})
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  )
}
