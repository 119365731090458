import { StarOutline } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'

const StarRating = ({ rating }: { rating: number | string }) => {
  return (
    <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
      <StarOutline
        sx={{
          color: 'grey',
          fontSize: 14
        }}
      />

      <Typography variant="label4">{rating}</Typography>
    </Stack>
  )
}

export default StarRating
