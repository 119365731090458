import { ROUTES } from '@momentum/routes/RouteNames'
import { CampaignGoalTypeToName } from '@momentum/utils/campaignGoalUtils'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { OpenInNewOutlined, WarningAmberOutlined } from '@mui/icons-material'
import { Box, Button, Chip, Grid, Link, Paper, Stack, Typography } from '@mui/material'
import { CampaignStatus, getCampaignStatus } from '@productwindtom/shared-campaign'
import {
  GraphQLTypes,
  InputType,
  InvoiceStatus,
  PauseReason,
  Selector,
  STORE_TO_LOCALE
} from '@productwindtom/shared-momentum-zeus-types'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'
import { min } from 'lodash'
import { DateTime } from 'luxon'
import { generatePath, Link as RouterLink } from 'react-router-dom'

type Campaign = InputType<GraphQLTypes['Campaign'], typeof campaignSelector>

export type CampaignCardProps = {
  brandId: string
  campaign: Campaign
}
export const CampaignCard = ({ campaign, brandId }: CampaignCardProps) => {
  const { product } = campaign
  return (
    <Paper sx={{ py: 2, px: 3 }} data-cy={`campaignCard`}>
      <Grid container spacing={2} columns={32}>
        <Grid item xs={4}>
          <Grid container justifyContent={'center'}>
            <Box>
              <img
                style={{ width: 80, height: 80, objectFit: 'contain' }}
                src={getCdnImageUrl(product?.image) || '/images/no-product.png'}
                alt={`${product?.name} image`}
                onError={noProductImageAlt}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Stack spacing={1}>
            <Stack direction={'row'}>
              <Typography variant={'link1'}>
                {!!product?.name && ellipsisString(product.name, 75)}{' '}
                {!!product?.listingLink && (
                  <Link
                    href={product?.listingLink}
                    target={'_blank'}
                    display={'inline-flex'}
                    sx={{ verticalAlign: 'sub' }}
                  >
                    <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
                  </Link>
                )}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              {product?.store && StoreToIcon[product.store]({ fontSize: 'mSmall' })}
              {product?.priceCents != null && (
                <Typography variant={'body2'} color={theme => theme.palette.grey.A700} data-cy={'campaignProductPrice'}>
                  {toCurrencyStringCents(product.priceCents, STORE_TO_LOCALE[product.store])}
                </Typography>
              )}
            </Stack>
            {campaign.goal && (
              <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                <Box color={'primary'} display={'inline-flex'}>
                  {CampaignGoalTypeToIcon[campaign.goal]?.({
                    fontSize: 'mSmall',
                    sx: { color: theme => theme.palette.grey.A700 }
                  })}
                </Box>
                <Typography variant={'body1'} color={theme => theme.palette.grey.A700} data-cy={'campaignGoal'}>
                  {CampaignGoalTypeToName[campaign.goal]}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs alignSelf={'center'}>
          <Grid container>
            <Grid item xs={4} alignSelf={'center'} data-cy={'campaignStatus'}>
              {getStatus(campaign)}
            </Grid>
            <Grid item xs={4} alignSelf={'center'}>
              <Stack alignItems={'center'}>
                <Typography variant={'label1'} data-cy={'campaignCreatorsJoined'}>
                  {campaign.numCreatorsJoined}/{campaign.numCreators}
                </Typography>
                <Typography variant={'body1'}>Creators joined</Typography>
              </Stack>
            </Grid>
            <Grid item xs={4} alignSelf={'center'} data-cy={'campaignNotifications'}>
              {getNotification(campaign)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignSelf={'center'} ml={'auto'}>
          <Button
            component={RouterLink}
            data-cy={'viewCampaignButton'}
            variant={'contained'}
            to={generatePath(ROUTES.CAMPAIGN, { brandId, campaignId: campaign.id })}
            data-viewcampaignname={`${campaign.title}`}
          >
            View campaign
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

const getNotification = ({ proposal }: Campaign) => {
  if (
    proposal?.invoiceStatus === InvoiceStatus.NOT_PAID ||
    proposal?.invoiceProductCostStatus === InvoiceStatus.NOT_PAID
  ) {
    const minDueDate = min([
      ...(proposal?.invoiceStatus === InvoiceStatus.NOT_PAID ? [proposal.invoiceDueDate] : []),
      ...(proposal?.invoiceProductCostStatus === InvoiceStatus.NOT_PAID ? [proposal.invoiceProductCostDueDate] : [])
    ])

    return (
      <Stack alignItems={'center'} spacing={1}>
        <Box>
          <Chip
            label={'Not paid'}
            variant={'filled'}
            color={'warning'}
            size={'small'}
            icon={<WarningAmberOutlined color={'warning'} fontSize={'mSmall'} />}
          />
        </Box>
        {!!minDueDate && (
          <Typography variant={'body1'}>
            Payment due {DateTime.fromISO(minDueDate).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        )}
      </Stack>
    )
  }
  return null
}

const getStatus = ({ startDate, preLaunchDate, endDate, numCreatorsJoined, pauseReason }: Campaign) => {
  const start = DateTime.fromISO(startDate)
  const end = endDate ? DateTime.fromISO(endDate) : undefined
  const campaignStatus = getCampaignStatus(startDate, preLaunchDate, endDate, numCreatorsJoined > 0, pauseReason)

  switch (campaignStatus) {
    case CampaignStatus.ACTIVE:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Active'} color={'success'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Launched {start.toLocaleString(DateTime.DATE_MED)}</Typography>
        </Stack>
      )
    case CampaignStatus.PRE_LAUNCH:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Pre-Launch'} sx={{ backgroundColor: '#94D5D9' }} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Pre-Launch</Typography>
        </Stack>
      )
    case CampaignStatus.UPCOMING:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Upcoming'} sx={{ backgroundColor: '#94D5D9' }} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Launching {start.toLocaleString(DateTime.DATE_MED)}</Typography>
        </Stack>
      )
    case CampaignStatus.DELAYED: {
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Delayed'} color={'error'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Delayed</Typography>
        </Stack>
      )
    }
    case CampaignStatus.FINISHED:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Completed'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Finished {end?.toLocaleString(DateTime.DATE_MED)}</Typography>
        </Stack>
      )
    case CampaignStatus.PAUSED:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Paused'} color="warning" size={'small'} />
          </Box>
          {pauseReason && PauseReasonDescription[pauseReason] && (
            <Typography variant={'body1'}>{PauseReasonDescription[pauseReason]}</Typography>
          )}
        </Stack>
      )
    default:
      return (
        <Stack alignItems={'center'} spacing={1}>
          <Box>
            <Chip label={'Unknown'} size={'small'} />
          </Box>
          <Typography variant={'body1'}>Unknown status</Typography>
        </Stack>
      )
  }
}

const PauseReasonDescription: Partial<Record<PauseReason, string>> = {
  [PauseReason.OUT_OF_STOCK]: 'Out of stock'
}

const campaignSelector = Selector('Campaign')({
  id: true,
  startDate: true,
  preLaunchDate: true,
  endDate: true,
  goal: true,
  skuId: true,
  numCreators: true,
  numCreatorsJoined: true,
  title: true,
  product: {
    name: true,
    priceCents: true,
    image: true,
    store: true,
    listingLink: true
  },
  proposal: {
    invoiceDueDate: true,
    invoiceStatus: true,
    invoiceProductCostStatus: true,
    invoiceProductCostDueDate: true,
    launchDate: true
  },
  pauseReason: true
})
