import { Box, Stack, Typography } from '@mui/material'

type ToggleSwitchProps<T> = {
  value: T
  values: { label: string; value: T }[]
  onChange?: (value: T) => void
}

export const ToggleSwitch = <T,>({ value, values, onChange }: ToggleSwitchProps<T>) => {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      sx={{
        borderRadius: '50px',
        padding: '8px',
        background: theme => theme.palette.grey.A100
      }}
    >
      {values.map((v, i) => (
        <Box
          key={i}
          onClick={() => v.value !== value && v.value && onChange?.(v.value)}
          sx={{
            cursor: 'pointer',
            padding: '8px 12px',
            transition: 'all 0.3s',
            ...(v.value === value && {
              borderRadius: '100px',
              background: 'white',
              boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)'
            })
          }}
        >
          <Typography
            variant={'label2'}
            sx={{
              ...(v.value !== value && { opacity: 0.5 })
            }}
          >
            {v.label}
          </Typography>
        </Box>
      ))}
    </Stack>
  )
}
