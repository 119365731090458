import { Stack, Typography, Box, Divider, Switch } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { Brand, getBrandWithSubscriptions } from './queries'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import Loading from '@momentum/components/loading'
import { keyBy } from 'lodash'
import { DateTime } from 'luxon'
import { CreditAction } from '@productwindtom/shared-momentum-zeus-types'
import { gridClasses } from '@mui/x-data-grid-pro'
import { DataGrid } from '@momentum/components/table'
import { tableDefinition, BrandAlert, RowData } from '@momentum/routes/all-subscriptions/tableDefinition'
import { Search, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput, SelectInput, CheckInput } from '@productwindtom/ui-base'
import { notEmpty } from '@productwindtom/shared-node'
import { ColumnSelector } from '@momentum/routes/all-subscriptions/ColumnSelector'
import { useGridApiRef, GridColDef } from '@mui/x-data-grid-premium'
import AmazonIcon from '@momentum/components/icons/amazon'
import WalmartIcon from '@momentum/components/icons/walmart'
import { mApi } from '@momentum/api'

type FilterData = {
  search?: string
  status?: boolean
  alerts: BrandAlert
  hideAgency?: boolean
}

export const AllSubscriptions = () => {
  const { companies, agencies, refreshBrand } = useUserSessionContext()
  const [brands, setBrands] = useState<Brand[]>()

  const keyedCompanies = keyBy(companies, 'id')

  useEffect(() => {
    getBrandWithSubscriptions().then(setBrands)
  }, [])

  const methods = useForm<FilterData>({
    mode: 'onChange'
  })
  const gridApiRef = useGridApiRef()

  const { watch } = methods

  const filterData = watch()

  const searchFilter = (filterData.search || '').trim().toLowerCase()
  const now = DateTime.now()

  const mappedBrands = useMemo(() => {
    return (brands || []).map(b => ({
      ...b,
      companyName: keyedCompanies[b.companyId || '']?.name,
      agencyId: keyedCompanies[b.companyId || '']?.agencyId,
      creditsRemaining: getRemainingCredits(b),
      active: !!b.subscriptionEndsAt && DateTime.fromISO(b.subscriptionEndsAt) > now,
      trial: !!b.trialSubscriptionStartedAt && b.subscriptionStartsAt === b.trialSubscriptionStartedAt,
      alert: getBrandAlert(b),
      integration: getIntegrationStatus(b)
    }))
  }, [brands, companies, agencies])

  const filteredBrands = useMemo(
    () => mappedBrands.filter(b => handleFilter(b, { ...filterData, search: searchFilter })),
    [mappedBrands, filterData]
  )

  const updateBrand = async (input: {
    id: string
    isBrandStoreScrapeEnabled?: boolean
    isWalmartScrapeEnabled?: boolean
  }) => {
    const updatedBrands = brands?.map(b => {
      if (b.id === input.id) {
        return {
          ...b,
          ...input
        }
      }
      return b
    })
    setBrands(updatedBrands)
    await mApi('mutation')({
      updateBrand: [{ input }, { id: true }]
    })
    refreshBrand(input.id)
  }

  if (!brands) {
    return <Loading />
  }

  const definition: GridColDef<RowData>[] = [
    ...tableDefinition,
    {
      field: 'brandStoreRefreshStatus',
      headerName: 'Brand store refresh status',
      width: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack spacing={1}>
          {row.amazonBrandStoreUrl && (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <AmazonIcon fontSize={'mSmall'} />
              <Switch
                size={'small'}
                checked={row.isBrandStoreScrapeEnabled}
                onChange={(_, checked) => updateBrand({ id: row.id, isBrandStoreScrapeEnabled: checked })}
              />
              <Typography variant={'label3'}>{row.isBrandStoreScrapeEnabled ? 'On' : 'Off'}</Typography>
            </Stack>
          )}
          {row.walmartScrapeUrl && (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <WalmartIcon fontSize={'mSmall'} />
              <Switch
                size={'small'}
                checked={row.isWalmartScrapeEnabled}
                onChange={(_, checked) => updateBrand({ id: row.id, isWalmartScrapeEnabled: checked })}
              />
              <Typography variant={'label3'}>{row.isWalmartScrapeEnabled ? 'On' : 'Off'}</Typography>
            </Stack>
          )}
        </Stack>
      )
    }
  ]

  return (
    <Stack pb={4}>
      <Stack mb={5} mt={3} spacing={1}>
        <Typography variant={'h3'} data-cy={'profileHeader'}>
          All subscriptions
        </Typography>
        <Typography variant={'h5'}>Momentum admin</Typography>
      </Stack>

      <Stack spacing={4}>
        <FormProvider {...methods}>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} spacing={2} flex={'auto'}>
                <TextInput
                  name="search"
                  placeholder="Search companies and brands"
                  fullWidth
                  InputProps={{
                    autoComplete: 'off',
                    endAdornment: <Search />
                  }}
                />
                <Box>
                  <SelectInput
                    fullWidth
                    name="status"
                    placeholder="Filter by status"
                    sx={{ width: 250 }}
                    options={[
                      { value: true, label: 'Active subscriptions' },
                      { value: false, label: 'Inactive subscriptions' }
                    ]}
                    disableTags
                  />
                </Box>
                <Box>
                  <SelectInput
                    fullWidth
                    name="alerts"
                    placeholder="Filter by alerts"
                    sx={{ width: 250 }}
                    options={[
                      { value: BrandAlert.ENDING_SOON_HIGH, label: BrandAlert.ENDING_SOON_HIGH },
                      { value: BrandAlert.ENDING_SOON_MEDIUM, label: BrandAlert.ENDING_SOON_MEDIUM }
                    ]}
                    disableTags
                  />
                </Box>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction={'row'} spacing={2}>
              {!!gridApiRef.current && <ColumnSelector apiRef={gridApiRef.current} />}
              <Box
                sx={{
                  border: theme => `1px solid ${theme.palette.grey.A200}`,
                  borderRadius: '4px',
                  p: 1,
                  background: theme => theme.palette.grey.A100
                }}
              >
                <CheckInput
                  name={'hideAgency'}
                  label={'Hide agency brands'}
                  checkboxProps={{
                    size: 'medium',
                    checkedIcon: <VisibilityOutlined sx={{ color: theme => theme.palette.grey.A700 }} />,
                    icon: <VisibilityOffOutlined />,
                    sx: { p: 0, mr: 0.5 }
                  }}
                  controlLabelProps={{
                    sx: { m: 0 },
                    slotProps: {
                      typography: { color: 'grey.A700', variant: 'label3' }
                    }
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </FormProvider>
        <DataGrid
          apiRef={gridApiRef}
          autoHeight
          rows={filteredBrands}
          columns={definition}
          disableColumnMenu={true}
          disableColumnReorder={true}
          pagination
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'active',
                  sort: 'desc'
                },
                {
                  field: 'subscriptionEndsAt',
                  sort: 'asc'
                }
              ]
            },
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          rowHeight={60}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none'
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none'
            },
            [`& .${gridClasses.columnHeader}`]: {
              height: 'auto !important',
              paddingBottom: '8px'
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              whiteSpace: 'normal'
            },
            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
              alignItems: 'flex-start'
            },
            [`& .${gridClasses.iconButtonContainer}`]: {
              paddingTop: '4px'
            }
          }}
        />
      </Stack>
    </Stack>
  )
}

const getRemainingCredits = (brand: Brand) => {
  return (brand.subscriptionCredits || []).reduce((total, ele) => {
    const multiplier = ele.action === CreditAction.ADD ? 1 : ele.action === CreditAction.REMOVE ? -1 : 0

    return total + ele.numCredits * multiplier
  }, 0)
}

const getBrandAlert = (brand: Brand) => {
  const now = DateTime.now()
  const thirtyDayDateAlert = now.plus({ days: 45 })
  const sixtyDayDateAlert = now.plus({ days: 90 })
  if (brand.subscriptionEndsAt && DateTime.fromISO(brand.subscriptionEndsAt) > now) {
    if (DateTime.fromISO(brand.subscriptionEndsAt) < thirtyDayDateAlert) {
      return BrandAlert.ENDING_SOON_HIGH
    } else if (DateTime.fromISO(brand.subscriptionEndsAt) < sixtyDayDateAlert) {
      return BrandAlert.ENDING_SOON_MEDIUM
    }
  }
}

const handleFilter = (brand: RowData, filter: FilterData) => {
  if (filter.hideAgency && brand.agencyId) {
    return false
  }

  if (filter.status != null && brand.active !== filter.status) {
    return false
  }

  if (filter.alerts && brand.alert !== filter.alerts) {
    return false
  }
  const searchFilter = (filter.search || '').trim().toLowerCase()

  if (searchFilter) {
    return brand.name.toLowerCase().includes(searchFilter) || brand.companyName?.toLowerCase().includes(searchFilter)
  }
  return true
}

const getIntegrationStatus = (brand: Brand) => {
  const isVendorIntegrated = brand.brandApis.some(b => b.api.includes('vendor') && b.enabled && b.isIntegrated)
  const isSellerIntegrated = brand.brandApis.some(b => b.api.includes('seller') && b.enabled && b.isIntegrated)

  const parts = [
    isSellerIntegrated ? 'Seller integrated' : null,
    isVendorIntegrated ? 'Vendor integrated' : null
  ].filter(notEmpty)

  if (parts.length) {
    return parts.join(', ')
  }
  return 'Not integrated'
}
