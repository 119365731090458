import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Stack,
  Typography,
  styled,
  TableContainer
} from '@mui/material'
import { DateTime } from 'luxon'
import { first, last, orderBy, sumBy } from 'lodash'
import { useReviewsContext, TopLevelViewType } from '@momentum/routes/campaign/e-commerce/reviews/reviewsContext'
import { TableHeadCell } from '../../common/TableHeadCell'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { retailerProgramReviewsName } from '@momentum/utils/storeUtils'

import { STORE_TO_RETAILER } from '@productwindtom/shared-momentum'

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.label2,
  fontWeight: 400
}))

export const ReviewsTable = () => {
  const { campaignDetails } = useCampaignContext()
  const { filteredReviewData, topLevelViewType, is3pReviewsVisible } = useReviewsContext()

  const minDateString = first(filteredReviewData)?.fromDate
  const maxDateString = last(filteredReviewData)?.toDate

  if (!minDateString || !maxDateString) return null

  const minDate = DateTime.fromISO(minDateString)
  const maxDate = DateTime.fromISO(maxDateString)

  const weeks = Math.ceil(maxDate.diff(minDate, 'weeks').weeks || 1)
  const weeksArray = new Array(weeks).fill(0)

  const weekData = weeksArray.map((_, index) => {
    const start = minDate.plus({ weeks: index })
    const end = start.plus({ weeks: 1 })
    const startString = start.toISODate()!
    const endString = end.toISODate()!
    const weekRangeData = orderBy(
      filteredReviewData.filter(d => d.fromDate >= startString && d.fromDate < endString),
      'fromDate',
      'asc'
    )
    const momentum = sumBy(weekRangeData, r => r.momentumReviewCount)
    const vine = sumBy(weekRangeData, r => r.vineReviewCount)
    const total = sumBy(weekRangeData, r => r.reviewsInPeriod)
    const organic = total - momentum - vine
    const reviews = (last(weekRangeData)?.numReviews || 0) - (first(weekRangeData)?.numReviews || 0)
    const ratings = (last(weekRangeData)?.numRatings || 0) - (first(weekRangeData)?.numRatings || 0) - reviews

    return {
      start,
      end,
      momentum,
      vine,
      organic,
      total,
      ratings,
      reviews
    }
  })

  return (
    <Stack spacing={3}>
      <Typography variant={'h4'}>Weekly reviews</Typography>

      <TableContainer>
        <Table
          sx={{
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              {weekData.map((d, index) => (
                <TableHeadCell key={index} week={index + 1} date={d.start} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {topLevelViewType === TopLevelViewType.MOMENTUM && (
              <>
                <TableRow>
                  <BodyTableCell>ProductWind</BodyTableCell>
                  {weekData.map((d, index) => (
                    <BodyTableCell key={index}>{d.momentum}</BodyTableCell>
                  ))}
                </TableRow>
                {is3pReviewsVisible && (
                  <TableRow>
                    <BodyTableCell>
                      {retailerProgramReviewsName(STORE_TO_RETAILER[campaignDetails.product.store])}
                    </BodyTableCell>
                    {weekData.map((d, index) => (
                      <BodyTableCell key={index}>{d.vine}</BodyTableCell>
                    ))}
                  </TableRow>
                )}
                <TableRow>
                  <BodyTableCell>Organic</BodyTableCell>
                  {weekData.map((d, index) => (
                    <BodyTableCell key={index}>{d.organic}</BodyTableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <BodyTableCell sx={{ fontWeight: 800, borderBottomWidth: 0 }}>Total </BodyTableCell>
                  {weekData.map((d, index) => (
                    <BodyTableCell key={index} sx={{ fontWeight: 800, borderBottomWidth: 0 }}>
                      {d.total}
                    </BodyTableCell>
                  ))}
                </TableRow>
              </>
            )}
            {topLevelViewType === TopLevelViewType.LISTING && (
              <>
                <TableRow>
                  <BodyTableCell>Reviews</BodyTableCell>
                  {weekData.map((d, index) => (
                    <BodyTableCell key={index}>{d.reviews}</BodyTableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <BodyTableCell>Ratings</BodyTableCell>
                  {weekData.map((d, index) => (
                    <BodyTableCell key={index}>{d.ratings}</BodyTableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <BodyTableCell sx={{ fontWeight: 800, borderBottomWidth: 0 }}>Total reviews & ratings</BodyTableCell>
                  {weekData.map((d, index) => (
                    <BodyTableCell key={index} sx={{ fontWeight: 800, borderBottomWidth: 0 }}>
                      {d.ratings + d.reviews}
                    </BodyTableCell>
                  ))}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
