import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { createCampaign } from '@momentum/routes/proposals-create/mutations'
import { BillingContactsInput } from '@momentum/routes/proposals-create/review/BillingContactsInput'
import { CreditPayment } from '@momentum/routes/proposals-create/review/CreditPayment'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { useSaveProposal } from '@momentum/routes/proposals-create/useSaveProposal'
import { ROUTE_NAMES_PROPOSAL, ROUTES } from '@momentum/routes/RouteNames'
import { errorToast } from '@momentum/utils/toastUtils'
import { ScheduleOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Stack, Typography, CircularProgress, Link } from '@mui/material'
import { Box } from '@mui/system'
import {
  InvoicePaymentType,
  PaymentTermsType,
  ProposalStatus,
  InvoiceMethod,
  PricingCreatorType
} from '@productwindtom/shared-momentum-zeus-types'
import { toLocaleCurrency } from '@productwindtom/shared-node'
import { DateInput, RadioInput, TextInput } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { max, keyBy } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { Question } from '@momentum/components/proposal-common/Question'
import PlaceholderProductAlert from '../modules/placeholder-product-alert'
import { PaymentBillingContactInput } from '@momentum/routes/proposals-create/review/PaymentBillingContactInput'
import { useFlag } from '@unleash/proxy-client-react'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { pdf } from '@react-pdf/renderer'
import { CampaignOrderFormPdf } from '@momentum/utils/order-forms/CampaignOrderFormPdf'
import { DEFAULT_NET_TERMS } from '@momentum/utils/proposalUtils'

export const Review = () => {
  const { selectedBrand, selectedCompany, agencies, isAdmin, isAdminView, agency, isViewOnly } = useUserSessionContext()
  const { refreshCampaigns, refreshProposal } = useBrandContext()
  const { creditsRemaining, refreshCredits } = useSubscriptionContext()
  const { totalCostCredits, totalCostCreditsForAgency, durationWeeks } = useEstimatedDeliverables()
  const { proposal, selectedProduct } = useCreateProposalContext()
  const adminScheduleCampaign = useFlag('AdminScheduleCampaign')
  const { saveProposal } = useSaveProposal(proposal?.id)
  const {
    watch,
    getValues,
    setValue,
    formState: { isValid }
  } = useFormContext<ProposalCreateForm>()
  const data = watch()
  const [launchDate, paymentType, invoiceDueDate, invoicePONumber] = watch([
    'launchDate',
    'paymentType',
    'invoiceDueDate',
    'invoicePONumber'
  ])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [scheduling, setScheduling] = useState(false)

  const companyAgency = agencies?.find(agency => agency.id === selectedCompany?.agencyId)
  const agencyTerms = companyAgency?.paymentTermsType

  const netTermsType = agencyTerms || selectedCompany?.paymentTermsType
  const netTerms =
    netTermsType === PaymentTermsType.NET_CUSTOM
      ? companyAgency?.paymentTermsCustomNetDays || selectedCompany?.paymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  const productCostNetTermsType = agencyTerms || selectedCompany?.productCostPaymentTermsType
  const productCostNetTerms =
    productCostNetTermsType === PaymentTermsType.NET_CUSTOM
      ? companyAgency?.paymentTermsCustomNetDays || selectedCompany?.productCostPaymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  const hasSplitPaymentTerms = !!selectedCompany?.hasSeparateProductCostTerms
  const isAdminSaveOnly = !adminScheduleCampaign && isAdmin

  useEffect(() => {
    if (!selectedProduct) {
      navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), {
        replace: true
      })
    }
  }, [])

  useEffect(() => {
    if (paymentType === InvoicePaymentType.NEW_INVOICE && netTerms) {
      setValue(
        'invoiceDueDate',
        netTermsType === PaymentTermsType.NET_CUSTOM
          ? DateTime.now().plus({ days: netTerms })
          : max([DateTime.now().plus({ days: 1 }), launchDate.minus({ days: netTerms })])
      )
    } else if (paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE) {
      setValue('invoiceDueDate', DateTime.now().plus({ month: 1 }).startOf('month'))
    }

    if (hasSplitPaymentTerms) {
      if (paymentType === InvoicePaymentType.NEW_INVOICE && productCostNetTerms) {
        setValue(
          'invoiceProductCostDueDate',
          productCostNetTermsType === PaymentTermsType.NET_CUSTOM
            ? DateTime.now().plus({ days: productCostNetTerms })
            : max([DateTime.now().plus({ days: 1 }), launchDate.minus({ days: productCostNetTerms })])
        )
      } else if (paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE) {
        setValue('invoiceProductCostDueDate', DateTime.now().plus({ month: 1 }).startOf('month'))
      }
    }
  }, [paymentType, netTermsType, netTerms])

  const handleBack = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.TIMELINE].join('/'), { replace: true })
  }

  const handleScheduleCampaign = async () => {
    setScheduling(true)
    const updatedProposal = await saveProposal(data)

    try {
      const resp = await createCampaign(updatedProposal.id)
      refreshCampaigns()
      refreshProposal(updatedProposal.id)
      if (data.paymentType === InvoicePaymentType.CREDITS) {
        refreshCredits()
      }
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, ROUTE_NAMES_PROPOSAL.WIN].join('/'), {
          brandId: updatedProposal.brandId,
          id: updatedProposal.id
        }) + `?invoiceLink=${resp.invoiceLink ?? ''}`
      )
    } catch (e: any) {
      console.error(e)
      captureException(e)
      switch (e.message) {
        case 'UNAUTHORIZED':
          errorToast('You are not authorized to schedule this campaign.')
          break
        case 'PROPOSAL_NOT_FOUND':
          errorToast('Proposal not found.')
          break
        case 'CAMPAIGN_ALREADY_EXISTS':
          errorToast('Campaign already exists.')
          break
        case 'INVALID_PROPOSAL':
          errorToast('Invalid proposal.')
          break
        case 'SKU_NOT_FOUND':
          errorToast('SKU not found.')
          break
        case 'BRAND_NOT_FOUND':
          errorToast('Brand not found.')
          break
        case 'PRICE_NOT_FOUND':
          errorToast('SKU price not found.')
          break
        case 'INSUFFICIENT_CREDITS':
          errorToast('Insufficient credits.')
          break
        case 'INVOICE_DUE_DATE_REQUIRED':
          errorToast('Invoice due date required.')
          break
        case 'BILLING_CONTACTS_REQUIRED':
          errorToast('Billing contacts required.')
          break
        case 'CREDITS_CHANGED':
        default:
          errorToast('An error has occurred, please try again later!')
      }
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, ROUTE_NAMES_PROPOSAL.REVIEW].join('/'), {
          brandId: updatedProposal.brandId,
          id: updatedProposal.id
        })
      )
    }
    setScheduling(false)
  }

  const generatePdf = async () => {
    const vals = getValues()
    if (vals.invoiceDueDate && vals.paymentType) {
      const keyedCreatorCounts = keyBy(vals.creatorPricing, c => c.type)

      const pdfDocument = (
        <CampaignOrderFormPdf
          companyName={selectedCompany!.name}
          productName={selectedProduct!.name}
          startDate={vals.launchDate.toLocaleString(DateTime.DATE_MED)}
          endDate={vals.launchDate.plus({ weeks: durationWeeks }).toLocaleString(DateTime.DATE_MED)}
          paymentType={vals.paymentType}
          paymentDueDate={vals.invoiceDueDate.toLocaleString(DateTime.DATE_MED)}
          productCostDueDate={vals.invoiceProductCostDueDate?.toLocaleString(DateTime.DATE_MED)}
          clientContactName={vals.paymentBillingContact?.name || ''}
          clientContactEmail={vals?.paymentBillingContact?.email || ''}
          contacts={(vals.billingContacts || []).map(c => c.email)}
          numCredits={totalCostCredits || 0}
          numBrandAdvocates={keyedCreatorCounts[PricingCreatorType.ADVOCATE]?.numCreators || 0}
          numSocialCreators={keyedCreatorCounts[PricingCreatorType.SOCIAL]?.numCreators || 0}
          numPremiumUgcCreators={keyedCreatorCounts[PricingCreatorType.PREMIUM_UGC]?.numCreators || 0}
          numUgcCreators={keyedCreatorCounts[PricingCreatorType.UGC]?.numCreators || 0}
        />
      )

      await pdf(pdfDocument)
        .toBlob()
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `draft-campaign-receipt.pdf`
          a.click()
        })
    }
  }

  const isPONumberValid =
    selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO ||
    (!!invoicePONumber && (!hasSplitPaymentTerms || !!data.invoiceProductCostPONumber))

  return (
    <Stack spacing={4}>
      <Question primaryText={'Campaign launch date'}>
        <Box sx={{ maxWidth: 165 }}>
          <DateInput fullWidth name={'launchDate'} readOnly disabled />
        </Box>
      </Question>
      <Stack>
        <Typography variant={'label1'}>How would you like to pay for this campaign?</Typography>
        {(companyAgency || isAdminView) && paymentType === InvoicePaymentType.END_OF_MONTH_INVOICE ? (
          <Alert variant="outlined" severity="info">
            <Typography variant="label3">
              {agency?.name} will be billed{' '}
              {toLocaleCurrency(totalCostCreditsForAgency || 0, 'USD', { minimumFractionDigits: 0 })} on{' '}
              {invoiceDueDate?.toLocaleString(DateTime.DATE_MED)} for this campaign. By clicking “schedule campaign” you
              agree to pay this amount.
            </Typography>
          </Alert>
        ) : (
          <RadioInput
            name={'paymentType'}
            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
            options={[
              ...(!selectedCompany?.agencyId
                ? [
                    {
                      label: <Typography variant={'label3'}>Use existing credits</Typography>,
                      value: InvoicePaymentType.CREDITS
                    }
                  ]
                : []),
              {
                label: <Typography variant={'label3'}>Generate a new invoice</Typography>,
                value: InvoicePaymentType.NEW_INVOICE
              }
            ]}
          />
        )}
      </Stack>

      {paymentType === InvoicePaymentType.CREDITS && (
        <CreditPayment creditsRemaining={creditsRemaining} totalCostCredits={totalCostCredits || 0} />
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && hasSplitPaymentTerms && (
        <Question
          primaryText={'When is the product cost due?'}
          subtext={
            productCostNetTermsType === PaymentTermsType.NET_CUSTOM && netTerms
              ? `${companyAgency?.name || selectedCompany?.name} has product cost Net ${productCostNetTerms} payment terms. The due date is set ${productCostNetTerms} days from the date the invoice is generated.`
              : `${companyAgency?.name || selectedCompany?.name} has product cost payment terms which require payment ${productCostNetTerms} days before launch. The due date is set ${productCostNetTerms} days from the campaign launch date of ${launchDate.toLocaleString(DateTime.DATE_SHORT)}.`
          }
        >
          <Box sx={{ maxWidth: 165 }}>
            <DateInput fullWidth name={'invoiceProductCostDueDate'} disabled readOnly />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && hasSplitPaymentTerms && (
        <Question
          primaryText={
            'Enter PO number for product costs' +
            (selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO ? ' (optional)' : '')
          }
        >
          <Box>
            <TextInput name={'invoiceProductCostPONumber'} />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={hasSplitPaymentTerms ? 'When is the creator cost due?' : 'When is the total payment due?'}
          subtext={
            netTermsType === PaymentTermsType.NET_CUSTOM && netTerms
              ? `${companyAgency?.name || selectedCompany?.name} has ${hasSplitPaymentTerms ? 'creator cost ' : ''}Net ${netTerms} payment terms. The due date is set ${netTerms} days from the date the invoice is generated.`
              : `${companyAgency?.name || selectedCompany?.name} has ${hasSplitPaymentTerms ? 'creator cost ' : ''}payment terms which require payment ${netTerms} days before launch. The due date is set ${netTerms} days from the campaign launch date of ${launchDate.toLocaleString(DateTime.DATE_SHORT)}.`
          }
        >
          <Box sx={{ maxWidth: 165 }}>
            <DateInput fullWidth name={'invoiceDueDate'} disabled readOnly />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={
            'Enter PO number' +
            (hasSplitPaymentTerms ? ' for creator costs' : '') +
            (selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO ? ' (optional)' : '')
          }
        >
          <Box>
            <TextInput name={'invoicePONumber'} />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={'Enter the contact responsible for invoice payment'}
          subtext={`An invoice will be generated and emailed to this contact.`}
        >
          <PaymentBillingContactInput name={'paymentBillingContact'} />
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && (
        <Question
          primaryText={"Enter additional contacts to be cc'ed on the invoice"}
          subtext={`An invoice will be generated and cc’ed to these contacts.`}
        >
          <BillingContactsInput name={'billingContacts'} />
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && selectedCompany?.invoiceMethod === InvoiceMethod.CUSTOM_PO && (
        <Question
          primaryText={'What is your invoicing system?'}
          subtext={'Enter your required invoicing system, such as Ariba or EasyO'}
        >
          <Box>
            <TextInput name={'invoicePOSystem'} />
          </Box>
        </Question>
      )}

      {paymentType === InvoicePaymentType.NEW_INVOICE && selectedCompany?.invoiceMethod !== InvoiceMethod.CUSTOM_PO && (
        <Question
          primaryText={
            'Provide any additional information which is required by your finance team to be added to the invoice.'
          }
        >
          <TextInput name={'invoiceAdditionalInformation'} multiline minRows={3} />
        </Question>
      )}

      {!companyAgency && paymentType && paymentType !== InvoicePaymentType.CREDITS && isAdminView && (
        <Question primaryText={'Enter the link to your existing HubSpot deal'}>
          <Box>
            <TextInput name={'hubspotDealLink'} placeholder={'HubSpot deal link'} fullWidth />
          </Box>
        </Question>
      )}

      {isViewOnly && (
        <Alert severity={'info'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            This campaign cannot be scheduled because your access level is View-only. Save the proposal and contact
            Customer Success to schedule this campaign.
          </Typography>
        </Alert>
      )}

      {isAdminSaveOnly && (
        <Alert severity={'info'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            The {selectedBrand?.name} brand must schedule this campaign directly.
          </Typography>
        </Alert>
      )}

      <Stack spacing={2} pb={2}>
        <PlaceholderProductAlert />
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button onClick={handleBack} data-cy="goBackButton">
            Go back
          </Button>

          <LoadingButton
            loading={scheduling}
            variant={'contained'}
            data-cy="scheduleCampaignButton"
            endIcon={<ScheduleOutlined />}
            disabled={
              isAdminSaveOnly ||
              isViewOnly ||
              !paymentType ||
              (!isAdminView && !selectedProduct?.skuId) ||
              proposal?.status === ProposalStatus.SUBMITTED ||
              (paymentType === InvoicePaymentType.CREDITS &&
                (!totalCostCredits || totalCostCredits > creditsRemaining)) ||
              !isValid ||
              !isPONumberValid
            }
            onClick={handleScheduleCampaign}
          >
            {proposal?.status === ProposalStatus.SUBMITTED ? 'Scheduled' : 'Schedule campaign'}
          </LoadingButton>
        </Stack>
        {scheduling && (
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CircularProgress size={24} />
            <Typography variant={'label3'}>
              We're scheduling your campaign! This could take up to 30 seconds.
            </Typography>
          </Stack>
        )}
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          By clicking “schedule campaign” you agree to the{' '}
          <Link
            variant={'label3'}
            sx={{ fontWeight: 800 }}
            href={getCdnImageUrl('documents/Momentum+Terms+and+Conditions.pdf')}
            target={'_blank'}
          >
            Momentum Platform Terms and Conditions
          </Link>{' '}
          and will receive a{' '}
          <Typography
            variant={'label3'}
            onClick={generatePdf}
            component={'span'}
            color={'primary'}
            sx={{ cursor: 'pointer', fontWeight: 800 }}
          >
            campaign receipt
          </Typography>{' '}
          via email.
        </Typography>
      </Stack>
    </Stack>
  )
}
