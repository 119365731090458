import { useFlag } from '@unleash/proxy-client-react'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Brand, Company } from '@momentum/routes/queries'
import { Button, CircularProgress } from '@mui/material'
import { Font, Document, Page, Image, Text, View, pdf } from '@react-pdf/renderer'
import { styles } from './styles'
import {
  getCampaignContentCounts,
  getCampaignCreatorCounts,
  getCampaignReviewMetrics,
  getCampaignSeoMetrics,
  getStringForStep,
  TimelineStep
} from '@productwindtom/shared-momentum'
import LogoWithText from '/images/logo-with-text.png'

import { useCampaignTimelineSteps } from '../Timeline/useCampaignTimelineSteps'
import { DateTime } from 'luxon'
import { CampaignStatus, STORE_DETAILS } from '@productwindtom/shared-campaign'

import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { CampaignContextType } from '@momentum/routes/campaign/context/types'
import { SaveAltOutlined } from '@mui/icons-material'
import { first, isNumber, last, sum, sumBy } from 'lodash'
import { SeoContextType, useSeoContext } from '@momentum/routes/campaign/e-commerce/seoV2/context'
import { ProposalGoal, Store } from '@productwindtom/shared-momentum-zeus-types'

import campaignSummaryBackdrop from './assets/campaign-summary-backdrop.png'
import rocketIcon from './assets/rocket-icon.png'
import circleCheckIcon from './assets/circle-check-icon.png'
import starIcon from './assets/bx-star.png'
import socialIcon from './assets/social-icon.png'
import playIcon from './assets/play-icon.png'
import clickIcon from './assets/click-icon.png'
import personSearchIcon from './assets/person-search-icon.png'

import { AISummary } from '@momentum/routes/campaign/e-commerce/feedback/feedbackContext'
import { getProductFeedbackSummary } from '@momentum/routes/campaign/e-commerce/feedback/queries'
import { useMemo, useState } from 'react'
import { errorToast } from '@momentum/utils/toastUtils'

import amazonLogo from '/images/logos/amazon-logo.png'
import walmartLogo from '/images/logos/walmart-logo.png'
import bestBuyLogo from '/images/logos/best-buy-logo.png'
import shopifyLogo from '/images/logos/shopify-logo.png'
import krogerLogo from '/images/logos/kroger-logo.png'
import targetLogo from '/images/logos/target-logo.jpg'
import costcoLogo from '/images/logos/costco-logo.png'

const CampaignSummaryPDF = () => {
  const { brand, company } = useBrandContext()
  const campaignContext = useCampaignContext()
  const seoContext = useSeoContext()
  const timelineSteps = useCampaignTimelineSteps()

  const activeStatus = campaignContext.activeStatus

  const [isLoading, setIsLoading] = useState(false)
  const downloadCampaignSummaryFlag = useFlag('DownloadCampaignSummary')

  if (!downloadCampaignSummaryFlag) return null

  const generatePdf = async () => {
    try {
      setIsLoading(true)
      const feedbackSummary = await getProductFeedbackSummary(campaignContext.campaignDetails.id)
      const aiSummaryResp = feedbackSummary?.jsonDownloadUrl && (await fetch(feedbackSummary.jsonDownloadUrl))
      const aiSummary = aiSummaryResp ? ((await aiSummaryResp.json()) as AISummary) : undefined

      const pdfDocument = (
        <CampaignSummaryPDFBody
          company={company}
          brand={brand}
          campaignContext={campaignContext}
          timelineSteps={timelineSteps}
          seoContext={seoContext}
          aiSummary={aiSummary}
        />
      )

      await pdf(pdfDocument)
        .toBlob()
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `Campaign-Summary-${campaignContext.campaignDetails.title}.pdf`.replaceAll(' ', '-')
          a.click()
        })
    } catch (err) {
      console.log('err', err)
      errorToast('Something went wrong, please try again or contact your client success manager.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      disabled={isLoading || activeStatus !== CampaignStatus.FINISHED}
      variant={'text'}
      startIcon={isLoading ? <CircularProgress size={12} color="inherit" /> : <SaveAltOutlined />}
      onClick={generatePdf}
    >
      Download campaign summary
    </Button>
  )
}

export default CampaignSummaryPDF

const CampaignSummaryPDFBody = ({
  company,
  brand,
  campaignContext,
  timelineSteps,
  seoContext,
  aiSummary
}: {
  company: Company
  brand: Brand
  campaignContext: CampaignContextType
  timelineSteps: TimelineStep[]
  seoContext: SeoContextType
  aiSummary?: AISummary
}) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: 'fonts/Avenir/AvenirRegular.ttf',
        fontWeight: 400
      },
      {
        src: 'fonts/Avenir/AvenirHeavy.ttf',
        fontWeight: 800
      }
    ]
  })

  const { campaignDetails, reviewMetrics = [] } = campaignContext
  const { productSeoSummary, productKeywordSummaries } = seoContext

  const {
    expectedSocialPostCount,
    expectedReviewsCount,
    expectedPremiumUgcPostCount,
    expectedUgcPostCount,
    expectedPlacementWins
  } = campaignDetails

  const {
    brandAdvocatesJoined,
    brandAdvocatesTotal,
    numSocialCreators,
    socialJoined,
    numUgcCreators,
    ugcJoined,
    numPremiumUgcCreators,
    premiumUgcJoined
  } = getCampaignCreatorCounts(campaignDetails)

  const { organicPageOneWinsSum, searchVolumeSum } = getCampaignSeoMetrics(campaignDetails.startDate, productSeoSummary)

  const { momentumReviews, weightedAverage } = getCampaignReviewMetrics(reviewMetrics)
  const { socialContentCount, premiumUgcContentCount, ugcContentCount } = getCampaignContentCounts(campaignDetails)

  const pageOneWins = sum((productSeoSummary?.productSeoSummaryRecords || []).map(p => p?.organicPageOneWins ?? 0))

  const improvedSlotsSum =
    productKeywordSummaries
      ?.map(k =>
        k.organicHighestRank && k.organicStartRank && k.organicHighestRank <= k.organicStartRank
          ? Math.abs(k.organicHighestRank - k.organicStartRank)
          : 0
      )
      .reduce((acc, curr) => acc + curr, 0) ?? 0

  const sponsoredPageOneWinsSum = sumBy(productSeoSummary?.productSeoSummaryRecords, r => r.sponsoredPageOneWins ?? 0)
  const sponsoredImprovedSlotsSum =
    productKeywordSummaries
      ?.map(k =>
        k.sponsoredHighestRank && k.sponsoredStartRank && k.sponsoredHighestRank <= k.sponsoredStartRank
          ? Math.abs(k.sponsoredHighestRank - k.sponsoredStartRank)
          : 0
      )
      .reduce((acc, curr) => acc + curr, 0) ?? 0

  const sumMomentumRatings = sumBy(reviewMetrics, r => r.momentumReviewCount)

  const now = DateTime.now().toISODate()
  const startDateDate = DateTime.fromISO(campaignDetails.startDate).toISODate()!
  const endDateDate = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate).toISODate()! : now
  const startOfCampaignData = reviewMetrics.filter(d => d.fromDate >= startDateDate)

  const firstRecord = first(startOfCampaignData)
  const lastRecord = last(startOfCampaignData.filter(d => d.toDate <= endDateDate))

  const ratingDifference = lastRecord && firstRecord && lastRecord.rating - firstRecord.rating

  const feedbackEntries = aiSummary?.feedback.filter(f => f.sentiment !== 'neutral').length || 0

  const summaryText = useMemo(() => {
    switch (campaignDetails.goal) {
      case ProposalGoal.PRODUCT_LAUNCH:
        return `${campaignDetails.numCreatorsJoined} creators drove increased traffic and conversions, boosting visibility and improving overall relevancy of the product on ${STORE_DETAILS[campaignDetails.product.store].storeLink}, setting it up for long-term success.`
      case ProposalGoal.BOOST_RATING:
        return `Your product’s star rating improved from ${firstRecord?.rating} to ${lastRecord?.rating} stars, with total reviews increasing from ${firstRecord?.numReviews} to ${lastRecord?.numReviews}. Powered by ${campaignDetails.numCreatorsJoined} creators, the campaign drove increased traffic and conversions, enhancing the product's overall relevancy on ${STORE_DETAILS[campaignDetails.product.store].storeLink}.`
      default:
        return `${campaignDetails.numCreatorsJoined} creators drove increased traffic and conversions, boosting visibility and improving overall relevancy of the product on ${STORE_DETAILS[campaignDetails.product.store].storeLink}.`
    }
  }, [campaignDetails, firstRecord, lastRecord])

  return (
    <Document
      pageLayout="singlePage"
      pageMode="useNone"
      title={`${campaignDetails.title} Campaign Summary`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <Page
        wrap={false}
        style={{
          fontFamily: 'Avenir'
        }}
      >
        <View style={[styles.row, styles.header, styles.spaceBetween]}>
          <Text style={styles.label1}>Campaign Summary</Text>
          <Image
            src={LogoWithText}
            style={{
              width: 130
            }}
          />
        </View>
        <View style={[styles.columns, styles.body]}>
          <Image
            source={campaignSummaryBackdrop}
            style={{
              position: 'absolute',
              top: -40,
              left: 0,
              width: '100%'
            }}
          />
          <View style={[styles.row, styles.spaceBetween, { alignItems: 'center' }]}>
            <View
              style={{
                width: '280px'
              }}
            >
              <Text
                style={[
                  styles.h4,
                  {
                    fontSize: '28px',
                    lineHeight: '1.4'
                  }
                ]}
              >
                ProductWind x <Br />
                {company.name}
              </Text>
            </View>
            <View
              style={[
                styles.roundBorder,
                styles.row,
                {
                  padding: '8px',
                  backgroundColor: 'white',
                  color: '#767676',
                  alignItems: 'center'
                }
              ]}
            >
              <View style={[styles.roundBorder, { padding: '6px', width: '80px', height: '80px' }]}>
                <Image
                  source={{
                    uri: campaignDetails.product.image!,
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                    body: ''
                  }}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </View>
              <View
                style={{
                  borderLeft: '1px solid #EBEDF6',
                  paddingLeft: '8px',
                  marginLeft: '8px'
                }}
              >
                <View
                  style={[
                    styles.roundBorder,
                    { padding: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }
                  ]}
                >
                  <Image
                    source={{
                      uri: getCdnImageUrl(brand.logo)!,
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                      body: ''
                    }}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </View>
                <View style={[styles.row, { alignItems: 'center', marginTop: '12px' }]}>
                  <Image
                    source={StoreToIcon[campaignDetails.product.store]}
                    style={{
                      height: '20px',
                      margin: '0 4px'
                    }}
                  />
                  <Text style={styles.caption2}>{campaignDetails.product.skuId}</Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.spacing,
              {
                width: '280px'
              }
            ]}
          >
            <Text
              style={[
                styles.label2,
                {
                  borderTop: '1px solid #324AA8',
                  borderBottom: '1px solid #324AA8',
                  padding: '8px 0',
                  margin: '16px 0 8px'
                }
              ]}
            >
              {brand.name} | {campaignDetails.title}
            </Text>
            <Text
              style={[
                styles.row,
                styles.caption2,
                {
                  alignItems: 'center'
                }
              ]}
            >
              <Image
                source={rocketIcon}
                style={{
                  width: '10px',
                  height: '10px',
                  transform: 'translateY(4px)'
                }}
              />{' '}
              {DateTime.fromISO(campaignDetails.startDate || campaignDetails.anticipatedStartDate).toLocaleString(
                DateTime.DATE_SHORT
              )}{' '}
              - {DateTime.fromISO(campaignDetails.endDate!).toLocaleString(DateTime.DATE_SHORT)}
            </Text>
          </View>

          <View
            style={[
              styles.row,
              styles.roundBorder,
              styles.spacing,
              {
                padding: '12px 24px',
                alignItems: 'center'
              }
            ]}
          >
            <Image
              source={clickIcon}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '16px'
              }}
            />
            <Text
              style={[
                styles.body1,
                {
                  lineHeight: '1.2',
                  flex: 1
                }
              ]}
            >
              {summaryText}
            </Text>
          </View>
          <Text style={[styles.label2, styles.spacing]}>Campaign timeline</Text>
          <View
            style={[
              styles.row,
              styles.spacing,
              {
                justifyContent: 'space-between',
                padding: '0 12px'
              }
            ]}
          >
            {timelineSteps.map((step, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    flexDirection: index % 2 === 0 ? 'column-reverse' : 'column'
                  }}
                >
                  <View
                    style={{
                      height: timelineSteps.length >= 7 ? '60px' : '45px'
                    }}
                  />
                  <View
                    style={{
                      position: 'relative'
                    }}
                  >
                    {index !== 0 && (
                      <View
                        style={[
                          styles.timelineLine,
                          {
                            left: '-20px'
                          }
                        ]}
                      />
                    )}
                    <Text style={styles.timelineStepCircle}>{index + 1}</Text>
                    {index !== timelineSteps.length - 1 && (
                      <View
                        style={[
                          styles.timelineLine,
                          {
                            right: '-20px'
                          }
                        ]}
                      />
                    )}
                  </View>
                  <View
                    style={{
                      height: timelineSteps.length >= 7 ? '60px' : '45px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: '150%',
                      transform: 'translateX(-15%)'
                    }}
                  >
                    <Text
                      style={[
                        styles.caption1,
                        {
                          fontSize: '9px'
                        }
                      ]}
                    >
                      {getStringForStep(step)}
                    </Text>
                    <Text
                      style={[
                        styles.caption2,
                        {
                          fontSize: '9px'
                        }
                      ]}
                    >
                      {step?.date?.toLocaleString(DateTime.DATE_MED)}
                    </Text>
                  </View>
                </View>
              )
            })}
          </View>
          <View
            style={[
              styles.row,
              styles.spacing,
              {
                padding: '0 20px',
                marginTop: '16px'
              }
            ]}
          >
            <View
              style={{
                flex: 1
              }}
            >
              <Text
                style={[
                  styles.label2,
                  {
                    textAlign: 'center',
                    marginBottom: '16px'
                  }
                ]}
              >
                Participating creators
              </Text>
              <View style={{ borderRight: '1px solid #EBEDF6' }}>
                {!!brandAdvocatesTotal && (
                  <Deliverable
                    title={getMetricOutOfString(brandAdvocatesJoined, brandAdvocatesTotal)}
                    subtitle="Brand advocates"
                    iconPath={circleCheckIcon}
                  />
                )}
                {!!numUgcCreators && (
                  <Deliverable
                    title={getMetricOutOfString(ugcJoined, numUgcCreators)}
                    subtitle="UGC creators"
                    iconPath={circleCheckIcon}
                  />
                )}
                {!!numPremiumUgcCreators && (
                  <Deliverable
                    title={getMetricOutOfString(premiumUgcJoined, numPremiumUgcCreators)}
                    subtitle="Premium UGC creators"
                    iconPath={circleCheckIcon}
                  />
                )}
                {!!numSocialCreators && (
                  <Deliverable
                    title={getMetricOutOfString(socialJoined, numSocialCreators)}
                    subtitle="Social creators"
                    iconPath={circleCheckIcon}
                  />
                )}
              </View>
            </View>
            <View style={{ flex: 1, borderLeft: '1px solid #EBEDF6', marginLeft: '-1px' }}>
              <Text
                style={[
                  styles.label2,
                  {
                    textAlign: 'center',
                    marginBottom: '16px'
                  }
                ]}
              >
                Campaign deliverables
              </Text>
              <View
                style={{
                  marginLeft: '16px'
                }}
              >
                {!!expectedReviewsCount && (
                  <Deliverable
                    title={getMetricOutOfString(momentumReviews, expectedReviewsCount)}
                    subtitle="Reviews & ratings"
                    iconPath={starIcon}
                  />
                )}

                {!!expectedPlacementWins && (
                  <Deliverable
                    title={getMetricOutOfString(pageOneWins, expectedPlacementWins)}
                    subtitle="Organic placement wins"
                    iconPath={personSearchIcon}
                  />
                )}

                {!!expectedSocialPostCount && (
                  <Deliverable
                    title={getMetricOutOfString(socialContentCount, expectedSocialPostCount)}
                    subtitle="Social posts & stories"
                    iconPath={socialIcon}
                  />
                )}

                {!!expectedPremiumUgcPostCount && (
                  <Deliverable
                    title={getMetricOutOfString(premiumUgcContentCount, expectedPremiumUgcPostCount)}
                    subtitle="Premium UGC"
                    iconPath={circleCheckIcon}
                  />
                )}
                {!!expectedUgcPostCount && (
                  <Deliverable
                    title={getMetricOutOfString(ugcContentCount, expectedUgcPostCount)}
                    subtitle="Authentic UGC"
                    iconPath={playIcon}
                  />
                )}
              </View>
            </View>
          </View>
          <View style={[styles.row]}>
            {!!searchVolumeSum && (
              <View
                style={[
                  styles.roundBorder,
                  {
                    padding: '20px 35px 12px',
                    flex: 1
                  }
                ]}
              >
                <Text
                  style={[
                    styles.label2,
                    {
                      textAlign: 'center',
                      marginBottom: '8px'
                    }
                  ]}
                >
                  SEO
                </Text>
                {campaignDetails.product.store !== Store.walmart && (
                  <Metric title="organic search impressions" value={searchVolumeSum} />
                )}
                <Metric title="organic Page 1 wins" value={organicPageOneWinsSum} />
                <Metric title="organic slots jumped" value={improvedSlotsSum} />
                <Metric title="sponsored page 1 wins" value={sponsoredPageOneWinsSum} />
                <Metric title="sponsored slots jumped" value={sponsoredImprovedSlotsSum} />
              </View>
            )}
            {!!(numUgcCreators || numPremiumUgcCreators) && (
              <View
                style={[
                  styles.roundBorder,
                  {
                    padding: '20px 35px 12px',
                    flex: 1,
                    marginLeft: '16px'
                  }
                ]}
              >
                <Text
                  style={[
                    styles.label2,
                    {
                      textAlign: 'center',
                      marginBottom: '8px'
                    }
                  ]}
                >
                  Conversions
                </Text>
                <Metric title="total reviews/ratings" value={lastRecord?.numRatings || 0} />
                <Metric title="total reviews/ratings from creators" value={sumMomentumRatings} />
                <Metric title="avg. star rating from creators" value={weightedAverage && +weightedAverage.toFixed(1)} />
                <Metric
                  title="boost in star rating"
                  value={
                    !ratingDifference
                      ? 0
                      : ratingDifference >= 0
                        ? `+${ratingDifference.toFixed(1)}`
                        : `-${ratingDifference.toFixed(1)}`
                  }
                />
                {!!feedbackEntries && <Metric title="total product feedback from creators" value={feedbackEntries} />}
              </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  )
}

const Deliverable = ({ title, subtitle, iconPath }: { title: string; subtitle: string; iconPath: string }) => {
  return (
    <View
      style={[
        styles.row,
        {
          marginBottom: '8px',
          alignItems: 'center'
        }
      ]}
    >
      <Image
        source={iconPath}
        style={{
          width: 24,
          height: 24,
          marginRight: '24px'
        }}
      />
      <View style={styles.columns}>
        <Text style={styles.caption1}>{title}</Text>
        <Text style={styles.caption2}>{subtitle}</Text>
      </View>
    </View>
  )
}

const Br = () => '\n'

const Metric = ({ value, title }: { value: number | string; title: string }) => {
  if (!value) return null

  return (
    <Text
      style={[
        styles.caption2,
        {
          marginBottom: '8px'
        }
      ]}
    >
      <Text style={styles.caption1}>{isNumber(value) ? value.toLocaleString() : value} </Text>
      {title}
    </Text>
  )
}

const getMetricOutOfString = (joined: number = 0, total?: number) => {
  if (!total) {
    return '--'
  }

  return `${joined.toLocaleString()} of ${total.toLocaleString()}`
}

export const StoreToIcon: Record<Store, string> = {
  [Store.amazon]: amazonLogo,
  [Store.amazon_ca]: amazonLogo,
  [Store.amazon_uk]: amazonLogo,
  [Store.amazon_de]: amazonLogo,
  [Store.bestBuy]: bestBuyLogo,
  [Store.walmart]: walmartLogo,
  [Store.target]: targetLogo,
  [Store.costco]: costcoLogo,
  [Store.shopify]: shopifyLogo,
  [Store.dtc]: shopifyLogo,
  [Store.kroger]: krogerLogo,
  [Store.other]: shopifyLogo
}
