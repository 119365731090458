import { DataGrid } from '@momentum/components/table'
import { Stack, Typography } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid-pro'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormType } from '../..'
import { useMilestones, useProductColors } from '../../hooks'
import { CampaignPerformanceProduct, PerformanceRecord } from '../../queries'
import { getPerformanceColumnDefinitions, RowData } from './columDefinitions'

export const PerformanceTable = () => {
  const { watch } = useFormContext<FormType>()
  const productColors = useProductColors()
  const products = watch('products') as CampaignPerformanceProduct[]
  const promotedProductId = watch('promotedProductId')
  const comparisonMetric = watch('comparisonMetric') as PerformanceComparisonMetric
  const comparisonMetricValue = watch('comparisonMetricValue')

  const promotedProduct = products.find((product: CampaignPerformanceProduct) => product.id === promotedProductId)

  if (comparisonMetric !== PerformanceComparisonMetric.REVIEWS || !promotedProduct) {
    return null
  }

  const milestones = useMilestones()

  const productsWithMilestones: RowData[] = useMemo(() => {
    const productsForMetric = products.filter(
      p => p.id === promotedProductId || !p.comparisonMetric || p.comparisonMetric === comparisonMetric
    )

    return productsForMetric.map((p, idx) => {
      const startingIndex = p.productPerformanceRecords.findIndex(r => r.reviewCount != null)
      const startingDate = DateTime.fromISO(
        startingIndex >= 0
          ? p.productPerformanceRecords.slice(startingIndex)[0].date
          : p.productPerformanceRecords[0].date
      )

      return {
        ...p,
        isPromoted: p.id === promotedProductId,
        htmlColor: productColors[p.id],
        daysToMilestone: milestones.map(m =>
          calculateDaysToReviewMilestone(p.productPerformanceRecords, m, startingDate)
        )
      }
    })
  }, [products, promotedProductId, comparisonMetric, comparisonMetricValue])

  return (
    <Stack spacing={3}>
      <Typography variant={'h4'}>Review goal wins</Typography>

      <DataGrid
        rowHeight={56}
        rows={productsWithMilestones}
        getRowId={row => row.id}
        columns={getPerformanceColumnDefinitions(milestones)}
        disableColumnResize={false}
        disableColumnSorting={true}
        disableColumnMenu={true}
        disableColumnReorder={true}
        hideFooter={true}
        pinnedColumns={{
          left: ['productName']
        }}
        pagination={false}
        sx={{
          [`.${gridClasses.cell}`]: {
            paddingY: '6px!important',
            paddingX: '8px!important'
          },
          [`& .${gridClasses['cell--pinnedLeft']}, & .${gridClasses['columnHeader--pinnedLeft']}`]: {
            borderRight: 0
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none'
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none'
          }
        }}
      />
    </Stack>
  )
}

const calculateDaysToReviewMilestone = (records: PerformanceRecord[], milestone: number, startingDate: DateTime) => {
  const record = records.find(r => r.reviewCount && r.reviewCount >= milestone)
  if (record) {
    return DateTime.fromISO(record.date).diff(startingDate, 'days').days
  }
}
