export const typography = {
  fontFamily: ['"Avenir"'].join(','),
  allVariants: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    letterSpacing: '0.02em'
  },
  h1: {
    fontSize: '48px',
    fontWeight: 800,
    letterSpacing: '2px'
  },
  h2: {
    fontSize: '36px',
    fontWeight: 800,
    letterSpacing: '2px'
  },
  h3: {
    fontSize: '24px',
    fontWeight: 800,
    letterSpacing: '0.48px'
  },
  h4: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '0.4px'
  },
  h5: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '0.4px'
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px',
    lineHeight: 'normal'
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.28px'
  },
  formPrimaryText: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.28px'
  },
  formSubText: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 300,
    letterSpacing: '0.28px'
  },
  body1: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.24px',
    lineHeight: 'normal'
  },
  body2: {
    fontSize: '12px',
    fontWeight: 300,
    letterSpacing: '0.24px',
    lineHeight: 'normal'
  },
  caption1: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    fontWeight: 800,
    letterSpacing: '0.25px'
  },
  caption2: {
    fontFamily: 'Avenir',
    fontSize: '10px',
    fontWeight: 400,
    letterSpacing: '0.25px'
  },
  caption3: {
    fontFamily: 'Avenir',
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.25px'
  },
  overline: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '1px'
  },
  label1: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  label2: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  label3: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.28px'
  },
  label4: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.28px'
  },
  link1: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  link2: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 800,
    letterSpacing: '0.24px'
  },
  button1: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '0.28px'
  },
  button2: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 800,
    letterSpacing: '0.24px',
    lineHeight: '24px'
  }
}
